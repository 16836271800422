import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { map } from 'rxjs/operators';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InvoiceTemplateBatchService } from 'src/app/services/invoice-template-batch.service';
import { InvoiceTemplateBatch } from 'src/app/model/invoice-template-batch';
import { InvoiceTemplateService } from 'src/app/services/invoice-template.service';

@Component({
  selector: 'app-invoice-run-recurrence',
  templateUrl: './invoice-run-recurrence.component.html',
  styleUrls: ['./invoice-run-recurrence.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceRunRecurrenceComponent extends KendoGridAbstractClass implements OnInit {
  private runInvoiceStep = 1;
  public communities = [];
  public vendors = [];
  public multiSelectCommunities = [];
  public multiSelectVendors = [];
  public selectedCommunities = [];
  public selectedVendors = [];
  public createdInvoiceTemplates = '';
  public createdInvoiceTemplateBatches = [];


  public glDateControl: FormControl = new FormControl();
  @ViewChild(GridComponent) public grid: GridComponent;

  constructor(private invoiceService: InvoiceService, 
    public invoiceTemplateService: InvoiceTemplateService,
    public invoiceTemplateBatchService: InvoiceTemplateBatchService,
    public fb: FormBuilder,
    public toastrService: ToastrService,
    public router: Router, public route: ActivatedRoute) {
    super();
    this.loading = true;
    this.glDateControl.setValue(new Date());
    this.route.queryParams.subscribe(params => {
      if (params['runInvoiceStep'] !== undefined) {
        // this section will be filed when its coming from 'run recurring invoice' on the invoice template list row item
        if (params['companyVendorId'] !== undefined) {
          this.selectedVendors.push(params['companyVendorId']);
        }
        if (params['companyId'] !== undefined) {
          this.selectedCommunities.push(params['companyId']);
        }
        if (params['invoiceDate'] !== undefined) {
          this.glDateControl.setValue(new Date(params['invoiceDate']));
        }
        this.next();
      }
    });
    this.exportFileName = 'recurring-invoice';
  }

  ngOnInit() {
    this.getInvoiceCommunity();
    this.getVendors();
  }

  public onCommunityMultiSelectControlChange(isSelected, selectedItems) {
    if (isSelected == true) {
      this.selectedCommunities.push(selectedItems.id);
    }else {
      this.selectedCommunities.splice(selectedItems.id);
    }
  }

  public onVendorMultiSelectControlChange(isSelected, selectedItems) {
    if (isSelected == true) {
      this.selectedVendors.push(selectedItems.id);
    }else {
      this.selectedVendors.splice(selectedItems.id);
    }
  }

  public cancel() {
    this.router.navigate(['/app/ledger/run-recurring-invoice']);
  }

  public back() {
    this.runInvoiceStep = 1;
    this.createdInvoiceTemplateBatches.forEach(id => {
      setTimeout(() => {
        this.invoiceTemplateBatchService.delete(id).subscribe(result => {
        }, error => {
          this.toastrService.error('There is an error while going back to run recurring invoice page');
        })
      }, 300);
    })
  }

  public next() {
    this.loading = true;
    //query data from invoicetemplates table using the parameters
    
    this.pageIndex = 1;
    this.state = {
      skip: 1, // current page number
      take: 10, // items per page
    };
    this.pageSizes = [10, 25, 50];
    this.data = {
      data: [],
      total: 0
    };
    this.filter = {
      companyId: this.selectedCommunities[0],
      companyVendorId: this.selectedVendors[0],
    }

    this.invoiceTemplateService.getAllInvoiceTemplatesWithFilters(this.filter).subscribe(templates => {
      // when the data is returned loop thru the data and then insert it on the InvoiceTemplateBatch
      let processedInvoiceDate =  `${new Date(this.glDateControl.value).getMonth() + 1}-${new Date(this.glDateControl.value).getDate()}-${new Date(this.glDateControl.value).getFullYear()}`;
      let invoiceTemplatesToCreate = [];
      let model: InvoiceTemplateBatch;
      
      templates.forEach(it => {
        if (this.createdInvoiceTemplates == '') {
          this.createdInvoiceTemplates = it.invoiceTemplateId;
        }else {
          this.createdInvoiceTemplates = this.createdInvoiceTemplates + ',' + it.invoiceTemplateId;
        }
        model  = new InvoiceTemplateBatch(this.fb);
        model.invoiceTemplateBatchId = 0;
        model.invoiceTemplateId = it.invoiceTemplateId;
        model.invoiceDate = processedInvoiceDate;
        model.runDate = new Date();
        invoiceTemplatesToCreate.push(model.toDto());
      });
      //// INSERT TO INVOICE TEMPLATE BATCH
      this.invoiceTemplateBatchService.addBulk(invoiceTemplatesToCreate).subscribe(result => {
        this.runInvoiceStep = 2;
        this.createdInvoiceTemplateBatches = result.idList;
        this.loading = false;
        // after inserting query from the InvoiceTemplateBatch to get the list
        this.getList();
      }, error => {
        this.toastrService.error('There is an error while processing Invoice Templates');
        console.log(error);
        this.loading = false;
      });
    })
  }

  public runInvoices() {
    this.loading = true;
    // create invoices and AP.Invoice distribution based on invoicetemplateids
    let newDate = `${new Date(this.glDateControl.value).getMonth() + 1}-${new Date(this.glDateControl.value).getDate()}-${new Date(this.glDateControl.value).getFullYear()}`
     this.invoiceService.createInvoiceFromTemplate(this.createdInvoiceTemplates, newDate ).subscribe(result => {
      this.toastrService.success('Invoices created');
      // update AP.InvoiceTemplateBatch invoiceId based on the InvoiceTemplateId from the inserted invoices
      // this.createdInvoiceTemplateBatches.forEach(item => {
      //   this.invoiceTemplateBatch.update(item).subscribe(results => {
      //   },error => {
      //     this.loading = false;
      //     this.toastrService.error('There is an error when running invoices');  
      //     console.log(error);
      //   });
      // });  
      this.router.navigate(['/app/ledger/transactions']);
     }, error => {
      this.loading = false;
      this.toastrService.error('There is an error when running invoices');  
      console.log(error);
     });

    // after the insert on the AP.Invoice, it will return the inserted invoiceId and then update the InvoiceTemplateBatch using the invoiceId
  }

  public dataStateChange(state: any): void {
    this.state = state;
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageIndex = (event.skip / event.take) + 1;
    this.state.skip = event.skip;
    this.state.take = event.take
    this.getList();
  }

  public exportCVS() {
    this.grid.saveAsExcel();
  }

  public exportToPDF() {
    this.grid.saveAsPDF();
  }

  public getInvoiceCommunity() {
    this.invoiceService.getEntity('community')
    .pipe(map(d => {
      this.communities = d;
      return d.map(element => {
        return {
          id: element.companyId,
          name: element.name
        }
      })
    })
    ).subscribe(communities => {
      this.multiSelectCommunities = communities;
      this.loading = false;
    });
  }

  public getVendors() {
    this.invoiceService.getEntity('companyVendor')
      .pipe(map(d => {
        this.vendors = d;
        return d.map(element => {
          return {
            id: element.vendorId,
            name: element.legalName
          }
        })
      })
      ).subscribe(vendors => {
        this.multiSelectVendors = vendors;
        this.loading = false;
      });
  }

  public getInvoiceCommunityByCompanyId(companyId: number) {
    let community = this.communities.filter(d => d.companyId == companyId);
    if (community.length > 0) {
      return community[0].name;
    }
    return '';
  }

  public getVendorByVendorId(vendorId: number) {
    let vendor = this.vendors.filter(d => d.vendorId == vendorId);
    if (vendor.length > 0) {
      return vendor[0].legalName;
    }
    return '';
  }

  protected getList() {
    this.loading = true;
    this.invoiceTemplateBatchService.getAllPaginatedWithFilters(this.pageIndex, this.state.take, this.filter, this.sort).subscribe(results => {
      this.data = {
        data: results[0],
        total: results[1].count
      };
      this.loading = false;
    })
  }
}
