import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { FileInfo } from '@progress/kendo-angular-upload';
import { forkJoin, Observable } from 'rxjs';
import * as AppConstants from '../app-constants';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { LevelFilter } from '../model/breadcrumb';
import { InvoiceDetail } from '../model/invoice-detail';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends KendoGridBaseService<InvoiceDetail> {
  constructor(public http: HttpClient,  @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'invoice', LevelFilter.Community, appConfig);
  }

  private invoiceUploadData = null;
  private files: FileInfo[] = [];

  getAllInvoiceFiltered(filter?: any, sortData?: string) {
    let queryString = '?';
    if (filter.batchId !== undefined && filter.batchId > 0 ) {
      queryString = `${queryString}batchId=${filter.batchId}`;
    }

    return this.http.get(`${this.endpoint}${this.entity}${queryString}`, { headers: this.buildGetHeaders() });
  }

  getAllWithFilters(pageIndex: number, pageSize: number, filter?: any, sortData?: string): Observable<any> {
    let queryString = `${pageIndex}/${pageSize}`;
    let dateFrom = '';
    let dateTo = '';
    if (filter !== undefined) {
      if (filter.headerSearch !== undefined && filter.headerSearch !== '') {
        queryString = `${queryString}/${filter.headerSearch}?`;
      } else {
        queryString = `${queryString}/?`;
      }

      if (filter.invoiceDate !== '' && filter.invoiceDate !== null && filter.invoiceDate !== undefined) {
        let newDate = new Date(filter.invoiceDate);
        dateFrom = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getUTCFullYear()}`;
        dateTo = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getUTCFullYear()}`;
        queryString = `${queryString}&invoiceDateFrom=${dateFrom}&invoiceDateTo=${dateTo}`;
      }

      if (filter.lockedByUserId !== null && filter.lockedByUserId !== '' && filter.lockedByUserId !== undefined) {
        queryString = `${queryString}&lockedByUserId=${filter.lockedByUserId}`
      }

      if (filter.bankId !== null && filter.bankId !== '' && filter.bankId !== undefined) {
        queryString = `${queryString}&bankId=${filter.bankId}`;
      }

      if (filter.batchId !== null && filter.batchId !== ''  && filter.batchId !== undefined) {
        queryString = `${queryString}&batchId=${filter.batchId}`;
      }

      if (filter.communityId !== null && filter.communityId !== '' && filter.communityId !== undefined) {
        queryString = `${queryString}&companyId=${filter.communityId}`;
      }

      if (filter.vendorId !== null && filter.vendorId !== '' && filter.vendorId !== undefined) {
        queryString = `${queryString}&companyVendorId=${filter.vendorId}`;
      }

      if (filter.invoiceStatusId !== undefined && filter.invoiceStatusId > 0) {
        queryString = `${queryString}&invoiceStatusId=${filter.invoiceStatusId}`;
      }

      if (filter.minAmount !== undefined && filter.minAmount > 0 && filter.maxAmount !== undefined && filter.maxAmount > 0) {
        queryString = `${queryString}&amountFrom=${filter.minAmount}&amountTo=${filter.maxAmount}`;
      }

      if (filter.invoiceNum !== undefined && filter.invoiceNum !== '' && filter.invoiceNum !== undefined) {
        queryString = `${queryString}&invoiceNum=${filter.invoiceNum}`;
      }

      if (filter.isDeleted == undefined) {
        queryString = `${queryString}&isDeleted=0`;
      } else {
        queryString = `${queryString}&isDeleted=${filter.isDeleted}`;
      }

    }

    if (sortData) {
      const sortCol = sortData.split('|');
      if (sortCol[0] !== undefined) {
        if (filter == null || filter == undefined) {
          queryString = `${queryString}?sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
        } else {
          queryString = `${queryString}&sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
        }
      }
    }
    return forkJoin(
      this.http.get(`${this.endpoint}${this.entity}/allpagewithfilters/${queryString}`, { headers: this.buildGetHeaders() }),
      this.getAllCountWithFilters(filter)
    );
  }

  getAllCountWithFilters(filter: any): Observable<any> {
    let queryString = '';
    let dateFrom = '';
    let dateTo = '';
   if (filter !== undefined) {
      if (filter.headerSearch !== undefined && filter.headerSearch !== '') {
        queryString = `${queryString}/${filter.headerSearch}?`;
      } else {
        queryString = `${queryString}/?`;
      }

      if (filter.invoiceDate !== '' && filter.invoiceDate !== null && filter.invoiceDate !== undefined) {
        let newDate = new Date(filter.invoiceDate);
        dateFrom = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getUTCFullYear()}`;
        dateTo = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getUTCFullYear()}`;
        queryString = `${queryString}&invoiceDateFrom=${dateFrom}&invoiceDateTo=${dateTo}`;
      }

      if (filter.lockedByUserId !== null && filter.lockedByUserId !== '' && filter.lockedByUserId !== undefined) {
        queryString = `${queryString}&lockedByUserId=${filter.lockedByUserId}`
      }

      if (filter.bankId !== null && filter.bankId !== '' && filter.bankId !== undefined) {
        queryString = `${queryString}&bankId=${filter.bankId}`;
      }

      if (filter.batchId !== null && filter.batchId !== ''  && filter.batchId !== undefined) {
        queryString = `${queryString}&batchId=${filter.batchId}`;
      }

      if (filter.communityId !== null && filter.communityId !== '' && filter.communityId !== undefined) {
        queryString = `${queryString}&companyId=${filter.communityId}`;
      }

      if (filter.vendorId !== null && filter.vendorId !== '' && filter.vendorId !== undefined) {
        queryString = `${queryString}&companyVendorId=${filter.vendorId}`;
      }

      if (filter.invoiceStatusId !== undefined && filter.invoiceStatusId > 0) {
        queryString = `${queryString}&invoiceStatusId=${filter.invoiceStatusId}`;
      }

      if (filter.minAmount !== undefined && filter.minAmount > 0 && filter.maxAmount !== undefined && filter.maxAmount > 0) {
        queryString = `${queryString}&amountFrom=${filter.minAmount}&amountTo=${filter.maxAmount}`;
      }

      if (filter.invoiceNum !== undefined && filter.invoiceNum !== '' && filter.invoiceNum !== undefined) {
        queryString = `${queryString}&invoiceNum=${filter.invoiceNum}`;
      }

      if (filter.isDeleted == undefined) {
        queryString = `${queryString}&isDeleted=0`;
      } else {
        queryString = `${queryString}&isDeleted=${filter.isDeleted}`;
      }

    }
    return this.http.get(`${this.endpoint}${this.entity}/allCountWithFilters${queryString}`, { headers: this.buildGetHeaders() })
  } 

  createInvoiceFromTemplate(invoiceTemplateIds: string, runDate: string): Observable<any> {
    const queryString = `${invoiceTemplateIds}/${runDate}`;
    return this.http.post(`${this.endpoint}${this.entity}/generateInvoiceByTemplate/${queryString}`, null, { headers: this.buildGetHeaders() });
  }


  deleteInvoice(id: number): Observable<any> {
    return this.softDelete(id);
  }

  setDataFromInvoiceUpload(data: any, filesToUpload: FileInfo[]) {
    this.invoiceUploadData = data;
    this.files = filesToUpload;
  }

  getDataFromInvoiceUpload(): any {
    return {
      invoiceUploadData: this.invoiceUploadData,
      filesToUpload: this.files
    }
  }

  uploadAttachmentFile(invoiceNum: string, invoiceId: string, attachmentTypeId: string, filesToUpload: File) {
    let formData: FormData = new FormData();
    formData.append('invoiceNum', invoiceNum);
    formData.append('invoiceId', invoiceId);
    formData.append('attachmentTypeId', attachmentTypeId);
    formData.append('files', filesToUpload, filesToUpload.name);
    return this.http.post(`${this.endpoint}${this.entity}/linkFile/`, formData, { headers: this.buildGetHeaders() }); 
  }

  getInvoiceById(id: number): Observable<any> {
    return this.http.get(`${this.endpoint}InvoiceAttachment/byFieldId/InvoiceId/${id}`, { headers: this.buildGetHeaders() });
  }

  getInvoiceByBatchIdInvoiceId(batchId: number, invoiceId: number) {
    return this.http.get(`${this.endpoint}${this.entity}/getInvoiceByBatchIdInvoiceId/${batchId}/${invoiceId}`, { headers: this.buildGetHeaders() })

  }

  getRevoFile(ids: string): Observable<any> {
    return this.http.post(`${this.endpoint}${this.entity}/generateReportToRevo/csv/${ids}`,{ headers: this.buildGetHeaders() })
  }

  getRevoObject(ids: string): Observable<any> {
      return this.http.get(`${this.endpoint}${this.entity}/generateReportToRevo/${ids}`, { headers: this.buildGetHeaders() })
  }

  deleteAttachment(invoiceAttachmentId: number) {
    return this.http.delete(`${this.endpoint}InvoiceAttachment/${invoiceAttachmentId}`, { headers: this.buildGetHeaders() });
  }
}

export interface InvoiceFileUpload {
  files: FileInfo[];
  uploadTypeId: number
}
