import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { LevelFilter } from '../model/breadcrumb';
import { InvoiceAudit } from '../model/invoice-audit';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class InvoiceAuditService extends KendoGridBaseService<InvoiceAudit> {

  constructor(public http: HttpClient,  @Inject(APP_CONFIG) appConfig: AppConfig) { 
    super(http, AppConstants.EndpointNames.Financials, 'invoiceAudit', LevelFilter.Community, appConfig);
  }

  getByInvoiceIdYear(invoiceId: number, invoiceAuditYear: number): Observable<InvoiceAudit[]> {
    let queryString = '/getByInvoice/';
    if (invoiceId !== undefined && invoiceId > 0) {
      queryString = queryString + invoiceId;
    }
    if (invoiceAuditYear !== undefined && invoiceAuditYear > 0) {
      queryString = queryString + '/' + invoiceAuditYear;
    } else {
      queryString = queryString + '/0';
    }
    return this.http.get<InvoiceAudit[]>(`${this.endpoint}${this.entity}${queryString}`, { headers: this.buildGetHeaders() });
  }
}
