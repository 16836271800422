import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KendoModule } from '../../kendo-module';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { AccountPayableBatchListComponent } from './account-payable-batch-list/account-payable-batch-list.component';
import { AccountPayableListComponent } from './account-payable-list/account-payable-list.component';
import { PayablesRoutingModule } from './payable-routing.module';
import { BankAccountEditComponent } from './settings/bank-account-edit/bank-account-edit.component';
import { PayablesSettingsComponent } from './settings/payables-settings/payables-settings.component';
import { PaymentProcessorEditComponent } from './settings/payment-processor-edit/payment-processor-edit.component';
import { VendorDetailComponent } from './vendor-detail/vendor-detail.component';
import { VendorInvoiceDetailComponent } from './vendor-invoice-detail/vendor-invoice-detail.component';
import { CustomDialogComponent } from 'src/app/app-dialogs/custom-dialog/custom-dialog/custom-dialog.component';
import { LedgerModule } from '../ledger.module';
import { PaymentBatchEditComponent } from './payment-batch-edit/payment-batch-edit.component';
import { BankAccountListComponent } from './settings/bank-account-list/bank-account-list.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    KendoModule,
    MaterialModule,
    PayablesRoutingModule,
    LedgerModule
  ],
  declarations: [
    AccountPayableListComponent,
    AccountPayableBatchListComponent,
    VendorInvoiceDetailComponent,
    VendorDetailComponent, 
    BankAccountEditComponent, 
    PaymentProcessorEditComponent, 
    PayablesSettingsComponent, 
    PaymentBatchEditComponent, 
    BankAccountListComponent
  ], 
  entryComponents: [
    BankAccountEditComponent
  ]
})
export class PayablesModule { }
