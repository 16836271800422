import { Component, OnInit, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CompanyService } from '../services/company.service';
import { LocationBarNode, LocationBarFlatNode, TypeLevel, TypeNode } from '../model/location-bar-node';
import { Level } from '../model/breadcrumb';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { Router } from '@angular/router';

const breadcrumbData = {
  1: new Level(),
  2: new Level(),
  3: new Level(),
  4: new Level(),
};

@Component({
  selector: 'app-location-bar',
  templateUrl: './location-bar.component.html',
  styleUrls: ['./location-bar.component.scss']
})
/*end treeview example data*/
export class LocationBarComponent implements OnInit {
  @ViewChild('tree') tree;
  allNodes: LocationBarNode[] = [];
  public locationLabel: string;

  public level1: Level = new Level();
  public level2: Level = new Level();
  public level3: Level = new Level();
  public level4: Level = new Level();
  public isResident = false;
  public searchLabelString = 'Search Company or Community';
  
  public backToLabel: string = '';
  public backToLabelLink: string = '';
  public selectedTabQueryParam: string = '';

  ngOnInit(): void {
    this.populateTree();
    LocalStorageHelper.watchLoggetUserInfo().subscribe(_ => this.populateTree());
    LocalStorageHelper.getBackToButtonOnLocationBar().subscribe(data => {
      this.backToLabel = data.label ?  '< ' + data.label : data.label;
      this.backToLabelLink = data.link;
      this.selectedTabQueryParam = data.selectedTabQueryParam;
    });
  }
  populateTree() {
    this.companyService.GetbyUserForLocationBar().subscribe(tree => {
      this.allNodes = tree;
      this.dataSource.data = tree;
      let idS = 0;
      let account = LocalStorageHelper.getAccountIdFromBreadcrumb();
      let community = LocalStorageHelper.getCommunitiesFromBreadcrumb();

      
      
      if(!(account && account !== 0)) {
        if(!(community && community !== 0)) {
          if(this.allNodes && this.allNodes.length>0 ) {
            var i;
            for(i = 0; i < this.allNodes.length; i++) {
              let lastE = this.getLastChildElement(this.allNodes[i]);
              if(lastE) {
                this.select(lastE);
                i = this.allNodes.length;
              }
            }
          }
          } else {
            idS = community;
          }
      } else {
        idS = account;
      }
      if(idS > 0)
      {
        let nodeC =  this.findElementOnlyById(idS);
        if(nodeC) {
          var element = this.isResident ? this.getLastChildElement(nodeC) : this.getFirstChildElement(nodeC);
          if(element) {
            this.select(element);
          }
        }
      } else {
        this.selectAccount();
      }
    });
  }
  selectAccount() {
    const user = LocalStorageHelper.getLoggedUserInfo(false);
    if (user.isResident && this.allNodes.length > 0 &&  user.residents.length > 0) {
      const account = user.residents.filter(u => u.userId = user.userId)[0].accountId;
      if (account) {
        const node = this.findElementOnlyById(account);
        if (node) {
          this.select(node);
        }
      }
    }
  }
  
  findElementOnlyById(id: number ) {
  if(this.allNodes && this.allNodes.length>0 ){
    var j;
    for(j = 0; j < this.allNodes.length; j++) {
      return this.getElementById(this.allNodes[j], id);
    }
  }
}

getElementById(element: any, id: number) {
    if(element.id === id){
      return element;
    }else if (element.children != null && element.children.length > 0){
        var i;
        var result = null;
        for(i=0; result == null && i < element.children.length; i++){
              result = this.getElementById(element.children[i], id);
        }
        return result;
    }
    
    return null;
  }

  getLastChildElement(element) {

    if (element.children != null && element.children.length > 0){
         var i;
         var result = null;
         for(i=0; result == null && i < element.children.length; i++){
              result = this.getLastChildElement(element.children[i]);
         }
         return result;
    } else if(element.type === 2 || element.type === 3 || element.type === 5){
      return element;
    }
    return null;
  }

  /* start treeview example */
  private transformer = (node: LocationBarNode, level: number) => {
    return {
      expandable: (level === 0 || (!!node.children && node.children.length > 0)),
      text: node.text,
      level: level,
      type: node.type,
      id: node.id,
      hasChild: (!!node.children && node.children.length > 0)
    };
  }

  treeControl = new FlatTreeControl<LocationBarFlatNode>(node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener<LocationBarNode, LocationBarFlatNode>(this.transformer,
    node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  /* end treeview example */

  public showBreadcrumb = true;

  constructor(private companyService: CompanyService, private router: Router) {
    var lui = LocalStorageHelper.getLoggedUserInfo(false);
    if(lui) {
      this.isResident = lui.isResident;
      if(this.isResident) {
        this.searchLabelString = 'Search Unit or Account Number';
      }
    }
  }

  getFirstChildElement(element) {
    if(element.type === 2 || element.type === 3 || element.type === 5){
      return element;
    } else if (element.children != null && element.children.length > 0){
         var i;
         var result = null;
         for(i=0; result == null && i < element.children.length; i++){
              result = this.getFirstChildElement(element.children[i]);
         }
         return result;
    }
    return null;
  }

  select(node: any) {
    if (node && node.text == null || node.text == '') {
      LocalStorageHelper.setSelectedLocationBarNode('');
    }
    
    let isCommunity = node.type === TypeNode.Community || node.type === TypeNode.SubCommunity;
    let isAccount = node.type === TypeNode.Account;
    if (isCommunity || isAccount) {
      var text = node.text;
      var MC: LocationBarNode;
      var Community: LocationBarNode;
      var Unit: LocationBarNode
      var Account: LocationBarNode
      //find parents
      for (let i = node.level; i >= 0; i--) {
        var nodo = this.getNodeById(node.id, i, this.allNodes);
        var parent = this.getNodeById(nodo.parentId, i - 1, this.allNodes);
        switch (nodo.type) {
          case TypeNode.ManagementCompany: { MC = nodo; break; }
          case TypeNode.SubCommunity: { Community = nodo; break };
          case TypeNode.Community: {
            if (!Community) {
             Community = nodo; break 
            }
            };
          case TypeNode.Unit: { Unit = nodo; break };
          case TypeNode.Account: { Account = nodo; break };
        }
        node = parent;
        if (parent) {
          text = parent.text + ' > ' + text;
          LocalStorageHelper.setSelectedLocationBarNode(text);
        }
      }

      this.locationLabel = text;
      if (isCommunity) {
        this.setBreadcrumbFromStaff(MC, Community);
        this.closeSearch();
      }
      else {
        this.setBreadcrumbFromResident(Community, Unit, Account);
      }
    }
    
  }


  setBreadcrumbFromStaff(MC: LocationBarNode, community: LocationBarNode) {
    this.level1.name = MC.text;
    this.level1.id = MC.id;
    this.level1.type = TypeLevel.ManagementCompany;
    this.level1.fatherId = 0;

    this.level2.fatherId = MC.id;
    this.level2.id = community.id;
    this.level2.name = community.text;
    this.level2.type = TypeLevel.Community;
    this.setLocalstorage();
  }

  setBreadcrumbFromResident(community: LocationBarNode, unit: LocationBarNode, account: LocationBarNode) {
    this.level1.fatherId = community.parentId;
    this.level1.id = community.id;
    this.level1.name = community.text;
    this.level1.type = TypeLevel.Community;

    this.level2.fatherId = unit.parentId;
    this.level2.id = unit.id;
    this.level2.name = unit.text;
    this.level2.type = TypeLevel.Unit;

    this.level3.fatherId = account.parentId;
    this.level3.id = account.id;
    this.level3.name = account.text;
    this.level3.type = TypeLevel.Account;
    this.setLocalstorage();
  }


  setLocalstorage() {
    breadcrumbData[1] = this.level1;
    breadcrumbData[2] = this.level2;
    breadcrumbData[3] = this.level3;
    breadcrumbData[4] = this.level4;
    LocalStorageHelper.setBreadcrumbInfo(breadcrumbData);
  }

  hasChild = (_: number, node: LocationBarFlatNode) => node.expandable;

  openSearch() {
    document.getElementById('myOverlay').style.display = 'block';
  }

  closeSearch() {
    document.getElementById('myOverlay').style.display = 'none';
  }


  public filterChanged(value: string): void {
    this.dataSource.data = this.search(this.allNodes, value);
    this.tree.treeControl.expandAll();
  }

  public search(items: any[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.text, term)) {
        acc.push(item);
      } else if (item.children && item.children.length > 0) {
        const newItems = this.search(item.children, term);

        if (newItems.length > 0) {
          acc.push({ text: item.text, children: newItems });
        }
      }

      return acc;
    }, []);
  }

  getNodeById(id, level, node) {
    if (level < 0) {
      return null;
    }
    var reduce = [].reduce;
    function runner(result, node) {
      if (result || !node) return result;
      return (node.id === id && node.level == level) && node || //is this the proper node?
        runner(null, node.children) || //process this nodes children
        reduce.call(Object(node), runner, result);  //maybe this is some ArrayLike Structure
    }
    return runner(null, node);
  }

  public contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }

  public navigateTo() {
    if (this.selectedTabQueryParam !== null) {
      LocalStorageHelper.setBackToButtonOnLocationBar({
        label: this.backToLabel,
        link: this.backToLabelLink,
        selectedTabQueryParam: this.selectedTabQueryParam
      })
      this.router.navigate([this.backToLabelLink, {pageFrom: this.selectedTabQueryParam}]);
    } else {
      this.router.navigate([this.backToLabelLink]);
    }
  }
}
