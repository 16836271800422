import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class CompanyBankAccount {
    companyBankAccountId=0;
    bankId = 0;
    //bankAccountName: string;
    //associationId: number;
    acceptACH: boolean;
    bankName: string;
    altBankName: string;
    bankAccountNum: string;
    bankCode: string;
    companyBankId: number;
    companyChartOfAccountsId: number;
    companyId: number;
    creationDate: Date;
    creationUserId: number;
    externalCode: string;
    isActive: true
    modificationDate: Date;
    modificationUserId: number;
}
