import { Injectable, Inject } from "@angular/core";
import { KendoGridBaseService } from "../building-blocks/kendo-grid-base-service";
import { HttpClient } from "@angular/common/http";
import { LevelFilter } from "../model/breadcrumb";
import { of, Observable, forkJoin } from "rxjs";
import { mockBatchApprovalListData, mockPaymentBatchDialogTable } from "../model/mock-data";
import { AccountPayableBatchDetails } from "./account-payable-batch-details";
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
import { InvoicePaymentService } from "./invoice-payment.service";
@Injectable({
    providedIn: 'root'
})

export class AccountPayableService extends KendoGridBaseService<AccountPayableBatchDetails> {
    public payableBatchToBeEdited: any = null;
    public payableBatchList: any[] = [];

    constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig, private invoicePaymentService: InvoicePaymentService) {
        super(http, AppConstants.EndpointNames.Financials, 'batches', LevelFilter.Community, appConfig);
    }
    // TODO: Reymond this is not the correct API
    getPayableAccount(pageSize: number, pageIndex: number, filter?: any, sortData?: any): Observable<any> {
        let queryString = `${pageIndex}/${pageSize}`;
        if (filter !== undefined) {
            if (filter.headerSearch !== undefined && filter.headerSearch !== '') {
                queryString = `${queryString}/${filter.headerSearch}&bankId=${filter.bankId}&invoiceDate=${filter.invoiceDate}&glDate=${filter.glDate}&communityName=${filter.communityName}&vendorName=${filter.vendorName}&minAmount=${filter.minAmount}&maxAmount=${filter.maxAmount}`;
            } else {
                queryString = queryString + `?bankId=${filter.bankId}&invoiceDate=${filter.invoiceDate}&glDate=${filter.glDate}&communityName=${filter.communityName}&vendorName=${filter.vendorName}&minAmount=${filter.minAmount}&maxAmount=${filter.maxAmount}`;
            }
        }
        if (sortData) {
            const sortCol = sortData.split('|');
            if (sortCol[0] !== undefined) {
                if (filter == null || filter == undefined) {
                    queryString = `${queryString}?sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
                } else {
                    queryString = `${queryString}&sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
                }
            }
        }
        return forkJoin(this.http.get(`${this.endpoint}${this.entity}/allpagewithfilters/${queryString}`, { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`, { headers: this.buildGetHeaders() }));
    }

    // TODO: Reymond this is not the correct API
    getBatchApproval(pageSize: number, pageIndex: number, filter?: any, sortData?: any): Observable<any> {
        return forkJoin(of(mockBatchApprovalListData()), of( {count: 3 }));
    }

    // TODO: Reymond coordinate for the correct API
    getListByBatchId(batchId: number) {
        return forkJoin(of(mockPaymentBatchDialogTable()), of({count: 3}));
    }

    getVendorsToEdit() {
        return this.invoicePaymentService.loadVendors(this.payableBatchList, this.payableBatchToBeEdited);
    }

    setPayableEdit(tableData: any[], payable: any) {
        this.payableBatchList = tableData;
        this.payableBatchToBeEdited = payable;
    }

    getPayableEdit() {
        return this.payableBatchToBeEdited;
    }
    
    getPayableBatchList() {
        return this.payableBatchList;
    }

    getAllVendorDetailByCommunity(communityId: number, sortData?: any): Observable<any> {
        let queryString = '';
        if (sortData) {
            const sortCol = sortData.split('|');
            if (sortCol[0] !== undefined) {
                queryString = `${queryString}?sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
            }
        }
        return forkJoin(this.http.get(`${this.endpoint}${this.entity}/getByCompanyId/${communityId}${queryString}`, { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`, { headers: this.buildGetHeaders() }));
    }

    getAllInvoiceByVendorId(vendorId: number): Observable<any> {
        return forkJoin(this.http.get(`${this.endpoint}${this.entity}/allPaged/${vendorId}`, { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`, { headers: this.buildGetHeaders() }));
    }

}