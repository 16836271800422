import { Component, Inject, OnInit, ViewChild, Injector, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator, MatSort, MatInput, MatPaginatorIntl } from '@angular/material';
import { People } from '../../model/people';
import { PeopleService } from '../../services/people.service';
import { LevelFilter } from 'src/app/model/breadcrumb';
import { BaseList } from 'src/app/building-blocks/base-list';
import { Observable } from 'rxjs';

const peopleColumns = ['name'];

@Component({
  selector: 'app-search-people-dialog',
  templateUrl: './search-people-dialog.component.html',
  styleUrls: ['./search-people-dialog.component.scss']
})


export class SearchPeopleDialogComponent extends BaseList<People> {
  public peoples: People[];
  public selectPeople: People = null;
  public searchPerformed: boolean = false;
  public autoLoad = true;
  private cols: any[] = [
    {
      columnDef: 'select',
      headerName: '',
      cell: (people) => ''
    },
    {
      columnDef: 'lastName',
      headerName: 'Last Name',
      cell: (people) => people.lastName,
      sortable: true
    },
    {
      columnDef: 'firstName',
      headerName: 'First Name',
      cell: (people) => 'people.firstName',
      sortable: true
    },
    {
      columnDef: 'address',
      headerName: 'Address',
      cell: (people) => this.displayAddress(people) ,
      sortable: true
    },
    {
      columnDef: 'emailAddress',
      headerName: 'Email Address',
      cell: (people) => people.emailAddress,
      sortable: true
    }
  ];

  @ViewChild('firstNameInput', {
    read: MatInput
  }) firstNameInput: MatInput;

  @ViewChild('lastNameInput', {
    read: MatInput
  }) lastNameInput: MatInput;

  @ViewChild('emailInput', {
    read: MatInput
  }) emailInput: MatInput;
  
  /** A reference to the table paginator component. */
  @ViewChild('MatPaginator') protected paginator: MatPaginator;
  result: Observable<number>;
  page = 1;

  constructor(public injector: Injector,
    public dialogRef: MatDialogRef<SearchPeopleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private peopleService: PeopleService,
    private elementRef:ElementRef) {
      super(peopleColumns, '/people', peopleService, 'people', injector);
      this.autoLoad = false;
      this.peopleService.setBreadcrumbParams(); 
  }

  protected getList(pageIndex: number, pageSize: number): Observable<People[]> {
    let fn = this.firstNameInput ? this.firstNameInput.value : null;
    let ln = this.lastNameInput? this.lastNameInput.value : null;
    let em = this.emailInput? this.emailInput.value : null;
    if(this.autoLoad === true && (fn !== null || ln !== null || em !== null) && (fn !== '' || ln !== '' || em !== ''))
    { 
      return this.peopleService.getAllPaginated(pageIndex, pageSize, this.dataSource.filter, this.dataSource.sortData, fn, ln, '', em);
    } else {
      this.loading = false;
      return new Observable<People[]>()
    }
  }

  protected getCount(): Observable<number> {
    this.page = 1;
    let fn = this.firstNameInput ? this.firstNameInput.value : null;
    let ln = this.lastNameInput? this.lastNameInput.value : null;
    let em = this.emailInput? this.emailInput.value : null;
    this.configurePaginator();
    if(this.autoLoad === true &&  (fn !== null || ln !== null || em !== null) && (fn !== '' || ln !== '' || em !== ''))
    {
      return this.peopleService.getCount(this.dataSource.filter, fn, ln, '', em).pipe(r => this.result = r );
    } else {
      this.loading = false;
      return new Observable<number>()
    }
  }
 

  configurePaginator() {
    this.paginator._intl.itemsPerPageLabel = 'Show';
      this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
        return '';
      };
    if (!this.elementRef.nativeElement.querySelector('.currentPage')) {
      const d1 = this.elementRef.nativeElement.querySelector('.mat-paginator-navigation-previous');
      d1.insertAdjacentHTML('afterend', '<span class="currentPage">' + this.page + '</span>');
    } else {
      const d1 = this.elementRef.nativeElement.querySelector('.currentPage');
      d1.innerHTML = this.page;
    }
  }

  applySearch() {
    this.autoLoad=true;
    this.dataSource.reload();
  }

  applySort(e) {
    this.autoLoad=true;
    const sortItem = `${e.active}|${e.direction}`;
    this.dataSource.sortData = sortItem;
    this.dataSource.reload();
  }
  pageEvent(e)  {
    this.page = e.pageIndex;
    const d1 = this.elementRef.nativeElement.querySelector('.currentPage');
    d1.innerHTML = this.page;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectPerson(people: any) {
    this.selectPeople = people;
  }

  ngSelect(): void {
    this.dialogRef.close({
      valid: true,
      data: {
        people: this.selectPeople
      }
    });
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: import("@angular/core").EventEmitter<import("../../model/crud-response-model").CrudResponseModel>) {
    throw new Error("Method not implemented.");
  }

  displayAddress(people: People) {
    let completeAddress : string = '';
    if (people.line1) {
      completeAddress = `${people.line1} ${people.name} , ${people.zipCode}`;
    }
    return completeAddress;
    
  }

}
