import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { AccountPayableListComponent } from "./account-payable-list/account-payable-list.component";
import { AuthGuardService } from "../../services/auth-guard.service";
import { AccountPayableBatchListComponent } from "./account-payable-batch-list/account-payable-batch-list.component";
import { PayablesSettingsComponent } from "./settings/payables-settings/payables-settings.component";
import { PaymentBatchEditComponent } from "./payment-batch-edit/payment-batch-edit.component";
import { InvoiceListComponent } from "../invoice/invoice-list/invoice-list/invoice-list.component";
import { InvoiceDetailComponent } from "../invoice/invoice-details/invoice-details.component";
import { InvoiceUploadComponent } from "../invoice/invoice-upload/invoice-upload.component";
import { InvoiceRecurrenceListComponent } from "../invoice/invoice-recurrence-list/invoice-recurrence-list/invoice-recurrence-list.component";
import { InvoiceRunRecurrenceComponent } from "../invoice/invoice-run-recurrence/invoice-run-recurrence/invoice-run-recurrence.component";
import { InvoiceRecurrenceComponent } from "../invoice/invoice-recurrence/invoice-recurrence/invoice-recurrence.component";
// TODO: Reymond uncomment this when menu item on database is updated
const payable_routes: Routes = [{
    path: '',
    children: [
        {
            path: 'transactions',
            component: InvoiceListComponent,
            data: { title: 'Transactions' },
            canActivate: [AuthGuardService]
        },
        {
            path: 'invoice-detail',
            component: InvoiceDetailComponent,
            data: { title: 'Invoices' },
            canActivate: [AuthGuardService]
        },
        {
            path: 'invoice-add',
            component: InvoiceUploadComponent,
            data: { title: 'Add Invoice' },
            canActivate: [AuthGuardService]
        },
        {
            path: 'run-recurring-invoice',
            component: InvoiceRecurrenceListComponent,
            data: { title: 'Add Invoice' },
            canActivate: [AuthGuardService]
        },
        {
            path: 'recurring-invoice',
            component: InvoiceRunRecurrenceComponent,
            data: { title: 'Create Recurring Invoice' },
            canActivate: [AuthGuardService]
        },
        {
            path: 'create-reccuring-invoice',
            component: InvoiceRecurrenceComponent,
            data: { title: 'Create Recurring Invoice' },
        },
        {
            path: 'account-payable-list',
            component: AccountPayableListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'batch-approval-transactions',
            component: AccountPayableBatchListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'transactions',
            component: AccountPayableBatchListComponent,
            canActivate: [AuthGuardService]
        },
        {
            path: 'settings',
            component: PayablesSettingsComponent,
            data: { title: 'Payables Settings' },
            canActivate: [AuthGuardService]
        },
        {
            path: 'payable-edit',
            component: PaymentBatchEditComponent,
            canActivate: [AuthGuardService]
        }
        
    ]

}];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(payable_routes)
    ],
    exports: [RouterModule]
})
export class PayablesRoutingModule { }