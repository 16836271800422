import { Component, ViewChild, ElementRef, Directive, Input } from '@angular/core';
import { LocationOffice, OfficeInformation } from 'src/app/model/locationOffice';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { LocationService } from 'src/app/services/location.service';
import { PhoneNumberService } from 'src/app/services/phone-number.service';
import { EmailService } from 'src/app/services/email.service';
import { AddressService } from 'src/app/services/address.service';
import { AddressTableEntry } from 'src/app/model/address-table-entry';
import { ZipCodeService } from 'src/app/services/zip-code.service';
import { State } from 'src/app/model/state';
import { City } from 'src/app/model/city';
import { CityService } from 'src/app/services/city.service';
import { StateService } from 'src/app/services/state.service';
import { forkJoin } from 'rxjs';
import { PhoneTypeService } from 'src/app/services/phone-type.service';
import { PhoneNumber } from 'src/app/model/phone-number';
import { Email } from 'src/app/model/email';
import { ValidatorHelper } from 'src/app/helpers/validator-helper';
import { TimeShift } from 'src/app/model/time-shifts';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { CommunityService } from 'src/app/services/community.service';
import { MatSelect, MatDialog } from '@angular/material';
import { AlertDialogComponent } from 'src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { stringHelper } from '../../helpers/stringHelper';

const LENGTH_ZIPCODE = 5;

@Component({
    selector: 'app-community-office-information-add-edit',
    templateUrl: './community-office-information-add-edit.component.html',
    styleUrls: ['./community-office-information-add-edit.component.scss']
})

export class CommunityOfficeInformationAddEditComponent {
    //component variables
    locationId: number = 0;
    pageMethod: string = '';
    hasOperationHours: boolean = false;
    urlImage = 'assets/images/office-information/';
    icons: any[] = [
        {
            name: 'office',
            isSelected: false,
            locationType: '',
            label: 'office',
        },
        {
            name: 'guardhouse',
            isSelected: false,
            locationType: ''
            , label: 'Guard House',
        },
        {
            name: 'emergency',
            isSelected: false,
            locationType: ''
            , label: 'After Hours',
        },
        {
            name: 'security',
            isSelected: false,
            locationType: ''
            , label: 'Security',
        },
        {
            name: 'concierge',
            isSelected: false,
            locationType: ''
            , label: 'Front Desk',
        },
        {
            name: 'mail',
            isSelected: false,
            locationType: ''
            , label: 'Mail',
        },
        {
            name: 'gym',
            isSelected: false,
            locationType: ''
            , label: 'Fitness',
        },
        {
            name: 'pool',
            isSelected: false,
            locationType: ''
            , label: 'Pool',
        },
        {
            name: 'beach',
            isSelected: false,
            locationType: ''
            , label: 'Beach',
        },
        {
            name: 'golf',
            isSelected: false,
            locationType: ''
            , label: 'Golf',
        },
        {
            name: 'golf-2',
            isSelected: false,
            locationType: ''
            , label: 'Golf',
        },
        {
            name: 'info',
            isSelected: false,
            locationType: ''
            , label: 'Information',
        }
    ];
    hoursOperation: TimeShift[] = [
        {
            timeShiftDetailId: 0,
            dayOfTheWeekId: 7,
            locationStatusId: 1,
            starts: '--',
            ends: '--',
            dayOfTheWeek: 'Sunday',
            timeShiftId: 0,
        },
        {
            timeShiftDetailId: 0,
            dayOfTheWeekId: 1,
            locationStatusId: 1,
            starts: '--',
            ends: '--',
            dayOfTheWeek: 'Monday',
            timeShiftId: 0,
        },
        {
            timeShiftDetailId: 0,
            dayOfTheWeekId: 2,
            locationStatusId: 1,
            starts: '--',
            ends: '--',
            dayOfTheWeek: 'Tuesday',
            timeShiftId: 0,
        },
        {
            timeShiftDetailId: 0,
            dayOfTheWeekId: 3,
            locationStatusId: 1,
            starts: '--',
            ends: '--',
            dayOfTheWeek: 'Wednesday',
            timeShiftId: 0,
        },
        {
            timeShiftDetailId: 0,
            dayOfTheWeekId: 4,
            locationStatusId: 1,
            starts: '--',
            ends: '--',
            dayOfTheWeek: 'Thursday',
            timeShiftId: 0,
        },
        {
            timeShiftDetailId: 0,
            dayOfTheWeekId: 5,
            locationStatusId: 1,
            starts: '--',
            ends: '--',
            dayOfTheWeek: 'Friday',
            timeShiftId: 0,
        },
        {
            timeShiftDetailId: 0,
            dayOfTheWeekId: 6,
            locationStatusId: 1,
            starts: '--',
            ends: '--',
            dayOfTheWeek: 'Saturday',
            timeShiftId: 0,
        }
    ];
    hourTime: any[] = [
        {
            label: '--',
            value: '--'
        },
        {
            label: '6:00 AM',
            value: '6:00'
        },
        {
            label: '6:30 AM',
            value: '6:30'
        },
        {
            label: '7:00 AM',
            value: '7:00'
        },
        {
            label: '7:30 AM',
            value: '7:30'
        },
        {
            label: '8:00 AM',
            value: '8:00'
        },
        {
            label: '8:30 AM',
            value: '8:30'
        },
        {
            label: '9:00 AM',
            value: '9:00'
        },
        {
            label: '9:30 AM',
            value: '9:30'
        },
        {
            label: '10:00 AM',
            value: '10:00'
        },
        {
            label: '10:30 AM',
            value: '10:30'
        },
        {
            label: '11:00 AM',
            value: '11:00'
        },
        {
            label: '11:30 AM',
            value: '11:30'
        },
        {
            label: '12:00 PM',
            value: '12:00'
        },
        {
            label: '12:30 PM',
            value: '12:30'
        },
        {
            label: '1:00 PM',
            value: '13:00'
        },
        {
            label: '1:30 PM',
            value: '13:30'
        },
        {
            label: '2:00 PM',
            value: '14:00'
        },
        {
            label: '2:30 PM',
            value: '14:30'
        },
        {
            label: '3:00 PM',
            value: '15:00'
        },
        {
            label: '3:30 PM',
            value: '15:30'
        },
        {
            label: '4:00 PM',
            value: '16:00'
        },
        {
            label: '4:30 PM',
            value: '16:30'
        },
        {
            label: '5:00 PM',
            value: '17:00'
        },
        {
            label: '5:30 PM',
            value: '17:30'
        },
        {
            label: '6:00 PM',
            value: '18:00'
        },
        {
            label: '6:30 PM',
            value: '18:30'
        },
        {
            label: '7:00 PM',
            value: '19:00'
        },
        {
            label: '7:30 PM',
            value: '19:30'
        },
        {
            label: '8:00 PM',
            value: '20:00'
        },
        {
            label: '8:30 PM',
            value: '20:30'
        },
        {
            label: '9:00 PM',
            value: '21:00'
        },
        {
            label: '9:30 PM',
            value: '21:30'
        },
        {
            label: '10:00 PM',
            value: '22:00'
        },
        {
            label: '10:30 PM',
            value: '22:30'
        },
        {
            label: '11:00 PM',
            value: '23:00'
        },
        {
            label: '11:30 PM',
            value: '23:30'
        },
        {
            label: '12:00 AM',
            value: '24:00'
        }
    ];
    companyId: number = 0;
    communityId: number = 0;

    //form variables
    loading = false;
    model: OfficeInformation;
    officeInformationFormGroup: FormGroup;
    addressItems: FormArray;
    emailsItems: FormArray;
    phonesItems: FormArray;
    addressModel: AddressTableEntry;
    phoneModel: PhoneNumber;
    emailModel: Email;
    timeShiftModel: TimeShift[] = [];

    // address variables
    addresses: AddressTableEntry[] = [];
    states: State[] = [];
    cities: City[][] = [];
    @ViewChild('stateSelect') stateSelect: MatSelect;
    @ViewChild('citySelect') citySelect: MatSelect;

    //phone variables
    phonemask: (string | RegExp)[] = [];
    phones: PhoneNumber[] = [];

    //email variables
    emails: Email[] = [];
    arrayBuffer: any;

    get addressFormGroup() {
        return this.officeInformationFormGroup.get('address') as FormArray;
    }

    get emailsFormGroup() {
        return this.officeInformationFormGroup.get('emails') as FormArray;
    }

    get phonesFormGroup() {
        return this.officeInformationFormGroup.get('phones') as FormArray;
    }

    constructor(private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private locationService: LocationService,
        private zipCodeService: ZipCodeService,
        private cityService: CityService,
        private stateService: StateService,
        private communityService: CommunityService,
        private dialog: MatDialog) {


        this.model = new OfficeInformation(this.fb);
        this.officeInformationFormGroup = this.model.buildFormGroup();
        this.addressItems = this.officeInformationFormGroup.get('address') as FormArray;
        this.emailsItems = this.officeInformationFormGroup.get('emails') as FormArray;
        this.phonesItems = this.officeInformationFormGroup.get('phones') as FormArray;
        this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
        this.loading = true;
        route.params.subscribe(param => {
            this.phonemask = ValidatorHelper.phonemask();
            if (param && param['id']) {
                this.locationId = param['id'];
            }
            const locationObservable = this.locationService.getLocationByLocationId(this.locationId);
            const stateObservable = this.stateService.getAll();
            this.communityService.getById(this.communityId).subscribe(company => {
                this.model.companyId = company.companyId;
                this.model.companies.push(company.companyId);
                if (this.locationId > 0) {
                    this.pageMethod = 'Edit Location';
                    forkJoin(locationObservable, stateObservable).subscribe(results => {
                        let result: any = results[0];
                        this.loading = false;
                        this.model.fromObject(result);
                        this.model.companyId = result.companies[0];
                        this.states = results[1];
                        this.setAddressModel(result.addresses[0]);
                        this.setPhoneModel(result.phoneNumbers[0]);
                        this.setEmailModel(result.emails[0]);
                        this.hasOperationHours = result.timeShifts.length > 0;
                        result.timeShifts.forEach(item => {
                            this.mapTimeShifts(item);
                        });
                    }, error => {
                        console.log(error.errorMessage);
                        this.loading = false;
                    })
                } else {
                    this.pageMethod = 'Add Location';
                    stateObservable.subscribe(result => {
                        this.states = result;
                        this.setAddressModel();
                        this.setPhoneModel();
                        this.setEmailModel();
                        this.loading = false;
                    }, error => {
                        this.loading = false;
                    });
                }
            })
        });
    }

    ngOnInit() {
        this.addressItems.removeAt(0);
        this.phonesItems.removeAt(0);
        this.emailsItems.removeAt(0);
    }

    ngAfterViewInit() {
        this.officeInformationFormGroup.get('address').setValidators([]);
        this.officeInformationFormGroup.get('emails').setValidators([]);
        this.officeInformationFormGroup.get('phones').setValidators([]);
    }

    setAddressModel(addressTableEntry: any = null) {
        this.addressItems.push(this.createAddressFormGroup(addressTableEntry));
        if (addressTableEntry) {
            this.bindAddressValue(addressTableEntry.zipCode, this.addressItems.length - 1);
        }
    }

    createAddressFormGroup(addressTableEntry: any): FormGroup {
        this.addressModel = new AddressTableEntry(this.fb);
        const formGroup = this.addressModel.buildFormGroup();
        if (addressTableEntry) {
            this.addressModel.fromObject(addressTableEntry);
        }
        this.addresses.push(this.addressModel);
        return formGroup;
    }

    bindAddressValue(zipCodeItem: string, i: number) {
        if (zipCodeItem === undefined || zipCodeItem.length !== LENGTH_ZIPCODE) {
            return;
        }
        this.zipCodeService.getByZipCode(zipCodeItem).subscribe(zipCode => {
            const state = this.states.find(x => x.stateCode === zipCode[0].stateCode);
            this.cityService.getByStateId(state.stateId).subscribe(x => {
                this.cities[i] = x;
                const city = x.find(c => c.name === zipCode[0].city);
                this.officeInformationFormGroup.get('address').get(i.toString()).get('stateId').setValue(state.stateId);
                this.officeInformationFormGroup.get('address').get(i.toString()).get('cityId').setValue(city.cityId);
                this.officeInformationFormGroup.get('address').get(i.toString()).get('zipCode').setValue(zipCodeItem);
                this.officeInformationFormGroup.get('address').get(i.toString()).get('cityId').enable();
                this.officeInformationFormGroup.get('address').get(i.toString()).get('stateId').enable();
                this.addresses[i].zipCodeId = zipCode[0].zipCodeId;
            });
        });
    }

    setPhoneModel(phoneEntity: any = null) {
        this.phonesItems.push(this.createPhoneAddressFormGroup(phoneEntity));
    }

    createPhoneAddressFormGroup(phoneEntity: any): FormGroup {
        this.phoneModel = new PhoneNumber(this.fb);
        const formGroup = this.phoneModel.buildFormGroup();
        if (phoneEntity) {
            this.phoneModel.fromObject(phoneEntity);
        }
        this.phones.push(this.phoneModel);
        return formGroup;
    }

    setEmailModel(emailEntity: any = null) {
        this.emailsItems.push(this.createEmailAddressFormGroup(emailEntity));
    }

    createEmailAddressFormGroup(emailEntity: any): FormGroup {
        this.emailModel = new Email(this.fb);
        const formGroup = this.emailModel.buildFormGroup();
        if (emailEntity) {
            this.emailModel.fromObject(emailEntity);
        }
        this.emails.push(this.emailModel);
        return formGroup;
    }

    updateAddress(value: string, i: number, isForm = false): void {
        if (value === undefined || value.length !== LENGTH_ZIPCODE) {
            return;
        }

        this.zipCodeService.getByZipCode(value).subscribe(zipCode => {
            const state = this.states.find(x => x.stateCode === zipCode[0].stateCode);
            this.cityService.getByStateId(state.stateId).subscribe(x => {
                this.cities[i] = x;
                const city = x.find(c => c.name === zipCode[0].city);
                this.officeInformationFormGroup.get('address').get(i.toString()).get('stateId').setValue(state.stateId);
                this.officeInformationFormGroup.get('address').get(i.toString()).get('cityId').setValue(city.cityId);
                this.officeInformationFormGroup.get('address').get(i.toString()).get('zipCode').setValue(value);
                this.officeInformationFormGroup.get('address').get(i.toString()).get('cityId').enable();
                this.officeInformationFormGroup.get('address').get(i.toString()).get('stateId').enable();
                this.addresses[i].zipCodeId = zipCode[0].zipCodeId;
            }, error => {
                console.log(error.errorMessage);
            });
        }, error => {
            console.log(error.errorMessage);
        });
    }

    onStateSelectionChange(e, i: number): void {
        this.addressModel.stateId = e.value;
        this.stateSelect.value = e.value;
        this.addressModel.stateName = this.states.find(x => x.stateId === e.value).name;
        this.cityService.getByStateId(e.value).subscribe(x => {
            this.cities[i] = x;
            this.addressModel.cityId = null;
        });
    }


    onChange($event) {
        this.hasOperationHours = $event.checked;
    }

    onIconSelection(icon: any) {
        this.icons.forEach(icon => {
            icon.isSelected = false;
        });

        icon.isSelected = true;
    }

    mapTimeShifts(timeShiftEntities: TimeShift) {
        this.hoursOperation.forEach(item => {
            if (item.dayOfTheWeek === timeShiftEntities.dayOfTheWeek) {
                item.dayOfTheWeek = timeShiftEntities.dayOfTheWeek;
                item.dayOfTheWeekId = timeShiftEntities.dayOfTheWeekId;
                item.ends = timeShiftEntities.ends;
                item.starts = timeShiftEntities.starts;
                item.locationStatusId = timeShiftEntities.locationStatusId;
                item.timeShiftDetailId = timeShiftEntities.timeShiftDetailId;
                item.timeShiftId = timeShiftEntities.timeShiftId;
                return;
            }
        });
    }

    save() {
        LocalStorageHelper.setBackToButtonOnLocationBar(null);

        //TODO!!! ask on how this property should have a value
        this.model.locationStatusId = 1
        this.model.locationType = 'Testing Type';
        this.model.locationTypeId = 1;
        this.model.showInOfficeInformation = true;
        //TODO!!! ask on how this property should have a value

        this.model.timeShifts = this.hoursOperation.filter(item => item.starts !== '--');
        let entityAddress = this.addresses[0].toDto();
        let entityPhone = this.phones[0].toDto();
        let entityEmail = this.emails[0].toDto();

        if (entityAddress.line1 == '' && entityPhone.number == '' && entityEmail.address == '') {
            this.openAlertDialog({Title: 'Error on saving', Message: 'There should be at least one contact information for this location'});
            return;
        }

        if (entityAddress.line1 !== '') {
            this.model.addresses.push(entityAddress);
        }

        if (entityPhone !== '') {
            this.model.phoneNumbers.push(entityPhone);
        }

        if (entityEmail !== '') {
            this.model.emails.push(entityEmail);
        }
        let location = this.model.toDto();
        if (location.locationId > 0) {
            this.locationService.update(location).subscribe(result => {
                const icon = this.icons.find(i => i.isSelected);
                if (icon) {
                    this.saveIcon(location.locationId, location.name, icon);
                }
                this.router.navigate(['app/community/settings', { pageFrom: 'officeInformation' }]);
            }, error => {
                this.loading = false;
                console.log(error.errorMessage);
            })
        } else {
            if (this.model.phoneNumbers.length == 0 && this.model.emails.length == 0) {
                alert('Email is required');
                return;
            } else if (this.model.emails.length == 0 && this.model.phoneNumbers.length == 0) {
                alert('Phone Number is required');
                return;
            }
            this.locationService.add(location).subscribe(locationId => {
                const icon = this.icons.find(i => i.isSelected);
                if (icon && locationId) {
                    this.saveIcon(locationId, location.name, icon);
                }
                this.router.navigate(['app/community/settings', { pageFrom: 'officeInformation' }]);
            }, error => {
                console.log(error.errorMessage);
            });
        }
        this.resetModels();
    }

    saveIcon(locationId, locationName, icon: any) {
        const file = stringHelper.createFile(this.urlImage + icon.name + '.svg').then(f => {
            const form_data =  new FormData();
            form_data.append('LocationId', locationId.toString());
            form_data.append('LocationName', locationName);
            form_data.append('icon', f);
            this.locationService.addIcon(form_data).subscribe();
        });
    }
    cancel() {
        this.router.navigate(['app/community/settings', { pageFrom: 'officeInformation' }]);
    }

    resetModels() {
        this.model.addresses = [];
        this.model.phoneNumbers = [];
        this.model.emails = [];
        this.model.specialDates = [];
        this.model.timeShifts = [];
    }

    openAlertDialog(dialogContent = null) {
        const data = {
          Title: 'Error',
          Message: 'Unknown Error'
        };
    
        this.dialog.open(AlertDialogComponent, {
          width: '400px',
          data: dialogContent == null ? data : dialogContent
        });
    
      }

}