import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { FileInfo } from '@progress/kendo-angular-upload';
import { forkJoin, Observable } from 'rxjs';
import * as AppConstants from '../app-constants';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { LevelFilter } from '../model/breadcrumb';
import { APP_CONFIG, AppConfig } from '../app.config';
import { BatchCodeDetail } from '../model/batch-code-detail';
import { BatchCode } from '../model/batch-code';

@Injectable({
  providedIn: 'root'
})
export class BatchCodeService extends KendoGridBaseService<BatchCodeDetail> {
  constructor(public http: HttpClient,  @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'batchcode', LevelFilter.Community, appConfig);
  }

  private invoiceUploadData = null;
  private files: FileInfo[] = [];

  getAllBatchCodesFiltered(filter?: any, sortData?: string) {
    let queryString = '?';
    if (filter.batchId !== undefined && filter.batchId > 0 ) {
      queryString = `${queryString}batchId=${filter.batchId}`;
    }

    return this.http.get(`${this.endpoint}${this.entity}${queryString}`, { headers: this.buildGetHeaders() });
  }

  getAllWithFilters(pageIndex: number, pageSize: number, filter?: any, sortData?: string): Observable<any> {
    let queryString = `${pageIndex}/${pageSize}`;
    
    if (filter !== undefined) {
      if (filter.headerSearch !== undefined && filter.headerSearch !== '') {
        queryString = `${queryString}/${filter.headerSearch}?`;
      } else {
        queryString = `${queryString}/?`;
      }

      if (filter.batchId !== null && filter.batchId !== ''  && filter.batchId !== undefined) {
        queryString = `${queryString}&batchId=${filter.batchId}`;
      }

      if (filter.isDeleted == undefined) {
        queryString = `${queryString}&isDeleted=0`;
      } else {
        queryString = `${queryString}&isDeleted=${filter.isDeleted}`;
      }

    }

    if (sortData) {
      const sortCol = sortData.split('|');
      if (sortCol[0] !== undefined) {
        if (filter == null || filter == undefined) {
          queryString = `${queryString}?sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
        } else {
          queryString = `${queryString}&sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
        }
      }
    }
    return forkJoin(
      this.http.get(`${this.endpoint}${this.entity}/allpagewithfilters/${queryString}`, { headers: this.buildGetHeaders() }),
      this.getAllCountWithFilters(filter)
    );
  }

  getAllCountWithFilters(filter: any): Observable<any> {
    let queryString = '';
    if (filter !== undefined) {
      if (filter.headerSearch !== undefined && filter.headerSearch !== '') {
        queryString = `${queryString}/${filter.headerSearch}?`;
      } else {
        queryString = `${queryString}/?`;
      }

      if (filter.batchId !== null && filter.batchId !== ''  && filter.batchId !== undefined) {
        queryString = `${queryString}&batchId=${filter.batchId}`;
      }

      if (filter.isDeleted == undefined) {
        queryString = `${queryString}&isDeleted=0`;
      } else {
        queryString = `${queryString}&isDeleted=${filter.isDeleted}`;
      }

    }
    return this.http.get(`${this.endpoint}${this.entity}/allCountWithFilters${queryString}`, { headers: this.buildGetHeaders() })
  } 


  deleteBatchCode(id: number): Observable<any> {
    return this.softDelete(id);
  }

  public getBatchTypes(): Observable<any> {
    const typeEntity= 'batchType';
    return this.http.get(`${this.endpoint}${typeEntity}/`, { headers: this.buildGetHeaders() });
}

public add(data: BatchCode): Observable<any> {
  //data['originId'] = 2;
  return this.http.post(`${this.endpoint}${this.entity}/`, data, { headers: this.buildGetHeaders() })
}

public update(entity: BatchCode): Observable<null> {
  //entity['originId'] = 2;
  //entity['modificationOriginId'] = 2;
  //console.log('entity');
  //console.log(entity);
  return this.http.put<null>(`${this.endpoint}${this.entity}/`, entity, { headers: this.buildGetHeaders() });
}
  
}
