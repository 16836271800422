import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as AppConstants from '../app-constants';
import { AppConfig, APP_CONFIG } from '../app.config';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCompanyBankAccountService extends KendoGridBaseService<CompanyBankAccount> {

  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'companyBankAccount', LevelFilter.Community, appConfig);
  }
}


class CompanyBankAccount {
  companyBankId: number;
  companyId: number;
  altBankName: string;
  bankId: number;
  bankCode: string;
  bankAccountNum: number;
  companyChartOfAccountsId: number;
  externalCode: string;
  isActive: boolean;
}