import { Injectable, Inject } from '@angular/core';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { LevelFilter } from '../model/breadcrumb';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { InvoiceTemplateBatch } from '../model/invoice-template-batch';
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
@Injectable({
  providedIn: 'root'
})
export class InvoiceTemplateBatchService extends KendoGridBaseService<InvoiceTemplateBatch> {

  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) { 
    super(http, AppConstants.EndpointNames.Financials, 'invoicetemplatebatch', LevelFilter.Community, appConfig);
  }

  getAllPaginatedWithFilters(pageIndex: number, pageSize: number, filter?: any, sortData?: string): Observable<any> {
    let queryString = `${pageIndex}/${pageSize}`;
    if (sortData) {
        const sortCol = sortData.split('|');
        if (sortCol[0] !== undefined) {
            if (filter == null || filter == undefined) {
              queryString = `${queryString}?sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
            }else {
              queryString = `${queryString}&sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
            }
        }
    }
    return forkJoin(this.http.get(`${this.endpoint}${this.entity}/allpagewithfilters/${queryString}`, { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`, { headers: this.buildGetHeaders() }));
  }


  getAllInvoiceTemplateBatch(filter:any): Observable<any> {
    if (filter !== undefined) {
      return this.getAll();
    }else {
      return this.http.get(`${this.endpoint}${this.entity}/`, { headers: this.buildGetHeaders() });
    }
  }
}
