import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
import { LevelFilter } from '../model/breadcrumb';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class InvoiceCompanyVendorService extends KendoGridBaseService<InvoiceCompanyVendor> {

  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'companyVendor', LevelFilter.Community, appConfig);
  }

}

class InvoiceCompanyVendor {
  companyVendorId: number;
  companyId: number;
  vendorId: number;
  vendorAccountNumber: number;
}