import { Injectable, Inject } from '@angular/core';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { HttpClient } from '@angular/common/http';
import { LevelFilter } from '../model/breadcrumb';
import { CompanyChartAccounts } from '../model/company-chart-accounts';
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CompanyChartAccountsService extends KendoGridBaseService<CompanyChartAccounts> {

  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) { 
    super(http,  AppConstants.EndpointNames.Financials, 'companyChartOfAccounts', LevelFilter.Community, appConfig);
  }
  
  getAllByFilter(companyId: number, filterValue: string): Observable<any> {
    return this.http.get(`${this.endpoint}${this.entity}/allByfilters?companyId=${companyId}&description=${filterValue}`, { headers: this.buildGetHeaders() })
  }

}
