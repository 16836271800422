import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AddressTableEntry } from 'src/app/model/address-table-entry';
import { Bank } from 'src/app/model/bank';
import { City } from 'src/app/model/city';
import { Community } from 'src/app/model/community';
import { CompanyBankAccount } from 'src/app/model/company-bank-account';
import { CrudResponseModel } from 'src/app/model/crud-response-model';
import { State } from 'src/app/model/state';
import { BankService } from 'src/app/services/bank.service';
import { CityService } from 'src/app/services/city.service';
import { CommunityService } from 'src/app/services/community.service';
import { CompanyBankAccountService } from 'src/app/services/company-bank-account.service';
import { BankAccountEditComponent } from '../bank-account-edit/bank-account-edit.component';

@Component({
  selector: 'app-bank-account-list',
  templateUrl: './bank-account-list.component.html',
  styleUrls: ['./bank-account-list.component.css']
})
export class BankAccountListComponent implements OnInit {

  public selectedAccountId: number = 0;
  public bankAccounts: CompanyBankAccount[] = [];
  public banks: Bank[] = [];
  communityId: number;
  companyId: number;
  public loading1: boolean;

  constructor(private dialog: MatDialog, 
    private bankAccountService: CompanyBankAccountService,
    private communityService: CommunityService, 
    private bankService: BankService
    ) { }

  async ngOnInit() {

    /*
    let bank1 = new CompanyBankAccount();
    bank1.bankAccountId=1;
    bank1.bankId=1;
    bank1.bankName="Bank of America";
    bank1.altBankName = "Doral 1";
    bank1.bankAccountNum = "12233444455";
    bank1.acceptACH = true;

    let bank2 = new CompanyBankAccount();
    bank1.bankAccountId=2;
    bank2.bankId=2;
    bank2.bankName="City Bank";
    bank2.altBankName = "Doral 2";
    bank2.bankAccountNum = "12233446666";
    bank2.acceptACH = false;

    this.banks.push(bank1);
    this.banks.push(bank2);
*/
    
    this.loading1 = true;
    await this.bankService.getAll().toPromise().then(p=>{
      this.banks = p;
      
    });
    
    this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if(this.communityId !== 0) {
    this.communityService.getById(this.communityId).subscribe(p=>{
      this.companyId=p.companyId;
      this.bankAccountService.getBankAccountsByCompany(this.companyId).subscribe(s=>{
        this.bankAccounts = s;
        if(this.bankAccounts && this.bankAccounts.length>0) {
          this.bankAccounts.forEach(acc => {
              acc.bankName = this.banks.find(banco => banco.bankId == acc.bankId).bankName;
          });
        }
        this.loading1 = false;
        //console.log('trae esto bankaccounts');
        //console.log(s);
      });
    });
    }
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>) {
    const dialogRef = this.dialog.open(BankAccountEditComponent, {
      width: '900px',
      height: '520px',
      data: {
        isUpdate: isUpdate,
        model: model,
        companyId: this.companyId
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined' && result.valid === true) {
        //this.dataSource.reload();
        //alert('asdad');
        this.loading1 = true;
        this.bankAccounts = [];
        this.bankAccountService.getBankAccountsByCompany(this.companyId).subscribe(s=>{
          this.bankAccounts = s;
          if(this.bankAccounts && this.bankAccounts.length>0) {
            this.bankAccounts.forEach(acc => {
                acc.bankName = this.banks.find(banco => banco.bankId == acc.bankId).bankName;
            });
          }
          this.loading1 = false;
          //console.log('trae esto bankaccounts');
          //console.log(s);
        });
      }
    });
    return dialogRef;
  }
}
