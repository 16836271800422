import { Component, Inject, OnInit } from '@angular/core';
import { BankAccount } from 'src/app/model/bank-account';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { CompanyBankAccount } from 'src/app/model/company-bank-account';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccessType } from 'src/app/model/access-type-ring';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { BatchCodeDetail } from 'src/app/model/batch-code-detail';
import { BatchType } from 'src/app/model/batch-type';
import { BatchCodeService } from 'src/app/services/batchcode.service';
import { BatchCode } from 'src/app/model/batch-code';

@Component({
  selector: 'app-batch-code-detail',
  templateUrl: './batch-code-detail.component.html',
  styleUrls: ['./batch-code-detail.component.css']
})
export class BatchCodeDetailComponent implements OnInit {
 
  public accountId: number;
  public model: BatchCode;
  //public bankAccountForm: FormGroup;
  public batchTypes: BatchType[] = [];
  public batchCode: BatchCode = new BatchCode();
  public batchCodeId: number;
  public loading2: boolean;

  batchCodeForm = new FormGroup({
    batchCode: new FormControl(),
    description: new FormControl(),
    batchTypeId: new FormControl()    
 });
  update: any;

 constructor(private router: Router,
  private route: ActivatedRoute,
  public dialogRef: MatDialogRef<BatchCodeDetailComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialog,
  private fb: FormBuilder,
  private batchCodeService: BatchCodeService) {

  //super();
  
  this.loadSecurityRings();
  this.model = new BatchCodeDetail();
  //this.bankAccountForm = this.model.buildFormGroup();
 }

 protected loadSecurityRings() {
  //this.securityEntity.rings.push({ringId : 577, accessType: AccessType.Add });
  //this.securityEntity.rings.push({ringId : 777, accessType: AccessType.Update });
  //this.entityRings.push(this.securityEntity);
}

  async ngOnInit() {
    this.loading2 = true;
    await this.batchCodeService.getBatchTypes().toPromise().then(p=>{
      this.batchTypes = p;      
    });
    this.loading2 = false;
     this.update = this.data.isUpdate;
      //console.log(this.data.model);
    this.model = this.data.model;
    if(this.model) {
      this.batchCodeForm.controls["batchCode"].setValue(this.model.batchCode);
      this.batchCodeForm.controls["description"].setValue(this.model.description);
      this.batchCodeForm.controls["batchTypeId"].setValue(this.model.batchTypeId);
      this.batchCodeId = this.model.batchCodeId;
    }
   // }
  }

  public onSave() {
    //console.log(this.batchCodeForm);
    if(this.batchCodeForm.valid) {
      if(this.update == false) {
          this.model = new BatchCode();     
          this.model.batchCodeId = this.batchCodeId;
          this.model.creationDate= new Date();
          this.model.modificationDate = new Date();
          this.model.creationUserId = 1;
          this.model.modificationUserId = 1;



          
          this.batchCode.creationDate= new Date();
          this.batchCode.modificationDate = new Date();
          this.batchCode.creationUserId = 1;
          this.batchCode.modificationUserId = 1;
      } else {
        this.batchCode.batchCodeId = this.batchCodeId;
      }
      this.model.batchCode = this.batchCodeForm.controls["batchCode"].value;
      this.model.description = this.batchCodeForm.controls["description"].value;
      this.model.batchTypeId = this.batchCodeForm.controls["batchTypeId"].value;

      //console.log(this.bankAccountForm.controls["bankId"].value);
      //console.log(this.bankAccountForm.controls["bankAccountName"].value);
      //console.log(this.bankAccountForm.controls["merchantId"].value);
      //console.log(this.bankAccountForm.controls["allowDebit"].value);

      this.batchCode.batchCode = this.model.batchCode;
      this.batchCode.description = this.model.description;
      this.batchCode.batchTypeId = this.model.batchTypeId

      this.loading2 = true;
      if(this.update == true) {
        this.batchCodeService.update(this.batchCode).subscribe(p=>{
          this.loading2 = false;
          this.dialogRef.close({ valid: true })
        });
      } else {
        this.batchCodeService.add(this.model).subscribe(q=>{
          this.loading2 = false;
          this.dialogRef.close({ valid: true })
        });
      }


    } else {
      alert('verify please');
    }
  }

  onCancel(): void {
    if (this.batchCodeForm.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else  {
      this.dialogRef.close({ valid: false });
    }
  }

  delete(): void {
    this.loading2 = true;
    this.batchCodeService.delete(this.model.batchCodeId).subscribe(p=>{
      this.loading2 = false;
      this.dialogRef.close({ valid: true })
    });
  }

}