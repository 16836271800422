import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-timeline',
  templateUrl: './file-timeline.component.html',
  styleUrls: ['./file-timeline.component.scss']
})
export class FileTimelineComponent implements OnInit {
  /**
   * This will be coming from the parent component from the database
   */
  @Input() fileItems = [];
  @Input() menuAction = [];
  @Output() actionMenuEvenEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onActionMenuClick(menu, question) {
    this.actionMenuEvenEmitter.emit({menu: menu, question: question});
  }

}
