import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { CompanyBankAccount } from '../model/company-bank-account';
import { AbstractDataProviderService } from './abstract-data-provider.service';

import * as AppConstants from '../app-constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { PictureService } from './picture.service';
import { LevelFilter } from '../model/breadcrumb';
import { CompanyProvider } from '../model/company-provider';

@Injectable({
  providedIn: 'root'
})
export class CompanyProviderService extends AbstractDataProviderService<CompanyProvider> {

  private userInfo = new BehaviorSubject(LocalStorageHelper.getUserDisplayName());
  currentUser = this.userInfo.asObservable();
  
  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig, private pictureService: PictureService) {
    super(AppConstants.EndpointNames.Financials, '/CompanyProvider', http, appConfig, LevelFilter.Unit);
  }

  getCompanyProvidersByCommunity(communityId: number) {
    return this.http.get<CompanyProvider[]>(this.baseUrl + '/ByFieldId/CommunityId/' + communityId, { headers: this.buildGetHeaders() });
  }

}