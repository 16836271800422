import { Injectable, Inject } from '@angular/core';
import { GlAccount } from '../model/invoice-detail';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { HttpClient } from '@angular/common/http';
import { LevelFilter } from '../model/breadcrumb';
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
@Injectable({
  providedIn: 'root'
})
export class InvoiceDistributionService  extends KendoGridBaseService<GlAccount> {

  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'invoiceDistributions', LevelFilter.Community, appConfig);
  }

  public getByFieldId(invoiceId: number) {
    return this.http.get(`${this.endpoint}${this.entity}/byFieldId/InvoiceId/${invoiceId}`, { headers: this.buildGetHeaders() });
  }
}
