import { Component, OnInit } from '@angular/core';
import {MatTab, matTabsAnimations} from '@angular/material';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AddressTableEntry } from 'src/app/model/address-table-entry';
import { City } from 'src/app/model/city';
import { Community } from 'src/app/model/community';
import { PhoneNumber } from 'src/app/model/phone-number';
import { State } from 'src/app/model/state';
import { CityService } from 'src/app/services/city.service';
import { CommunityService } from 'src/app/services/community.service';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-payables-settings',
  templateUrl: './payables-settings.component.html',
  styleUrls: ['./payables-settings.component.css']
})
export class PayablesSettingsComponent implements OnInit {

  public communityId: number;
  public communityName: string;
  public loading: boolean;
  modelCommunity: Community;
  public addressModel: AddressTableEntry;
  cities: City[];
  public states: State[] = [];
  public communityAddress: string;
  public phoneModel: PhoneNumber;
  public contactInfo: string;
  public selectedFile: File;
  public imgSrc: any;
  
  constructor(private communityService: CommunityService,
    private cityService: CityService,
    private companyService: CompanyService) { }

  ngOnInit() {
    
    this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.communityName = LocalStorageHelper.getCommunityNameFromBreadcrumb();
    this.loadCommunityData(this.communityId);
    //
  }

  loadCommunityData(id) {
    this.loading = true;
    this.communityService.getCommunityWithAddressByCommunityId(id).subscribe(c => {
      this.modelCommunity = c;

      //bind the address from the API
      if (c.addresses) {
        //console.log('esto tiene adresses');
        //console.log(c.addresses);

        this.addressModel = c.addresses;
        this.cityService.getByStateId(this.addressModel.stateId).subscribe(res => {
          this.cities = res;
          const city = this.cities.find(c => c.cityId === this.addressModel.cityId).name;
          const state = this.states.find(st => st.stateId === this.addressModel.stateId);
          let stateName = '';
          if (state) {
            stateName = state.name;
          }

          this.communityAddress = this.addressModel.line1 + ', ' + this.addressModel.cityName + ', ' +
            stateName + ' ' + this.addressModel.zipCode;
        }, (e=>{this.loading = false;}));
      }

      // bind the phone from the API
      if (c.phoneNumbers) {
        this.phoneModel = c.phoneNumbers;
        this.modelCommunity.phoneNumber = this.phoneModel.number;
        this.contactInfo = this.phoneModel.number;
      }

      this.communityName = c.name;

      this.getLogo(c.companyId);
      //this.getPictures(c.communityId);
      //this.loading = false;
    }, (e=>{this.loading = false;}));

  }

  getLogo(companyId: number) {
    this.loading = true;
    this.companyService.getCompanyLogo(companyId).subscribe(pic => {
      
      const reader = new FileReader();
      const blob = new Blob([new Uint8Array(pic)], { type: 'image/jpeg' });
      if (blob.size > 0) {
        const file = new File([blob], 'Logo', { type: 'image/jpeg', lastModified: Date.now() });
        this.selectedFile = file;
        reader.onload = (event: any) => {
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      }
      this.loading = false;
    }, (e=>{this.loading = false;}));
  }
}
