import { BaseForm } from "../building-blocks/base-form";
import { FormField } from "../building-blocks/decorators/reactive-form-decorators";
import { Validators, FormArray } from "@angular/forms";


export class GlAccount extends BaseForm {
    invoiceTemplateDistributionId = 0;
    invoiceTemplateId = 0;
    isDeleted = false;
    @FormField('companyChartOfAccountsId', [null, Validators.required])
    companyChartOfAccountsId = null;

   @FormField('amount', [null, Validators.required])
   amount = null;

   @FormField('description', [null, Validators.required])
   description = null;
}

export class InvoiceRecurrence  extends BaseForm {
    termsId = 1;
    orgId = 1;
    templateIntervalId = 1; // TODO: Reymond ask for the field on the mock up
    dueDay = 1; // TODO: Reymond ask for the field on the mock up
    // bankId = 1; // TODO: Reymond ask for the field on the mock up
    uploadTypeId = 1; // TODO: Reymond ask for the field on the mock up
    lastRunDate = new Date(); // TODO: Reymond ask for the field on the mock up
    nextRunDate = new Date(); // TODO: Reymond ask for the field on the mock up
    endDate = new Date(); // TODO: Reymond ask for the field on the mock up

    
    @FormField('invoiceTypeId', [null, Validators.required])
    invoiceTypeId = null;

    @FormField('templateName', [null, Validators.required])
    templateName = null;

    @FormField('amount', [null, Validators.required])
    amount = null;

    @FormField('companyVendorId', [null, Validators.required])
    companyVendorId = null;

    @FormField('companyId', [null, Validators.required])
    companyId = null;
    

    // @FormField('vendoAccountNumber', [null, Validators.required])
    // vendoAccountNumber: any;

    // @FormField('communityId', [null, Validators.required])
    // communityId: number;

    @FormField('paymentTypeId', [Validators.required])
    paymentTypeId = 0;

    @FormField('bankId', [null, Validators.required])
    bankId: number; 

    @FormField('isSeparateCheck', null)
    isSeparateCheck = false;

    @FormField('isHoldPayment', null)
    isHoldPayment = false;

    @FormField('isNoApproval', null)
    isNoApproval = false;

    @FormField('is1099Payment', null)
    is1099Payment = false;

    @FormField('glAccnts', [], GlAccount,  true)
    glAccnts: FormArray;

    // @FormField('glAccountId', [null, Validators.required])
    // glAccountId: number;

    // @FormField('glDescription', [null, Validators.required])
    // glDescription: Date;

    // @FormField('glAmount', [null, Validators.required])
    // glAmount: any;

    
}