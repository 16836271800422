import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../../services/auth-guard.service';

import { PaymentComponent } from '../payment/payment.component';
import { TransactionsComponent } from '../transactions/transactions.component';
import { InvoiceUploadComponent } from '../invoice/invoice-upload/invoice-upload.component';
import { InvoiceDetailComponent } from '../invoice/invoice-details/invoice-details.component';
import { InvoiceListComponent } from '../invoice/invoice-list/invoice-list/invoice-list.component';
import { InvoiceRecurrenceComponent } from '../invoice/invoice-recurrence/invoice-recurrence/invoice-recurrence.component';
import { InvoiceRunRecurrenceComponent } from '../invoice/invoice-run-recurrence/invoice-run-recurrence/invoice-run-recurrence.component';
import { InvoiceRecurrenceListComponent } from '../invoice/invoice-recurrence-list/invoice-recurrence-list/invoice-recurrence-list.component';
import { BatchCodeListComponent } from '../batch-code/batch-code-list/batch-code-list.component';

const ledgerRoutes: Routes = [{
  path: '',
  children: [
    {
      path: 'payment',
      component: PaymentComponent,
      data: { title: 'Component' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'transactions',
      component: InvoiceListComponent,
      data: { title: 'Transactions' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'invoices',
      component: InvoiceListComponent,
      data: { title: 'Invoices' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'invoice-detail',
      component: InvoiceDetailComponent,
      data: { title: 'Invoices' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'invoice-add',
      component: InvoiceUploadComponent,
      data: { title: 'Add Invoice' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'run-recurring-invoice',
      component: InvoiceRecurrenceListComponent,
      data: { title: 'Add Invoice' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'recurring-invoice',
      component: InvoiceRunRecurrenceComponent,
      data: { title: 'Create Recurring Invoice' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'create-reccuring-invoice',
      component: InvoiceRecurrenceComponent,
      data: { title: 'Create Recurring Invoice' },
    },
    {
      path: 'batchcodes',
      component: BatchCodeListComponent,
      data: { title: 'Batch Codes' },
      canActivate: [AuthGuardService]
    }
  ]
}];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ledgerRoutes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class LedgerRoutingModule { }
