import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import * as AppConstants from '../app-constants';
import { APP_CONFIG, AppConfig } from '../app.config';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCommunityService extends KendoGridBaseService<InvoiceCommunity> {

  constructor(public http: HttpClient,  @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'community', LevelFilter.Community, appConfig);
   }
}


class InvoiceCommunity {
  communityId: number;
  name: string;
  parent: number;
  propertyManagementCompany: number;
  companyId: number;
  isDeleted: boolean;
}
