import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnlyNumberDirective } from '../directives/only-number.directive';
import { UnderConstructionComponent } from '../under-construction/under-construction.component';
import { MaterialModule } from '../material.module';
import { SidebarAccountComponent } from './sidebar-account/sidebar-account.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PeopleEditComponent } from '../people/people-edit/people-edit.component';
import { SearchUnitComponent } from './search-unit/search-unit.component';
import { SearchPeopleComponent } from '../visitors/search-people/search-people.component';
import { SearchVisitorsComponent } from '../visitors/search-visitors/search-visitors.component';
import { ActionsVisitorComponent } from '../visitors/actions-visitor/actions-visitor.component';
import { PhotoCameraComponent } from './photo-camera/photo-camera.component';
import { SearchResidentComponent } from './search-resident/search-resident.component';
import { SearchResidentStaffComponent } from './search-resident-staff/search-resident-staff.component';
import { SearchAddressGoogleComponent } from './search-address-google/search-address-google.component';
import { CrossSearchComponent } from './cross-search/cross-search.component';
import { ContactsWidgetComponent } from './contacts-widget/contacts-widget.component';
import { SearchResidentUnitsComponent } from './search-resident-units/search-resident-units.component';
import { PhotoCameraImageUpload } from './photo-camera-image-upload/photo-camera-image-upload.component';
import { OfficeInformationWidgetComponent } from './office-information-widget/office-information-widget.component';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import { GridTableComponent } from './grid-table/grid-table.component';
import { NotificationPanelComponent } from '../notification-panel/notification-panel.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TextMaskModule } from '../../../node_modules/angular2-text-mask';

import { MatTableExporterModule } from 'mat-table-exporter';
import { BadgeDirectiveComponent } from './badge-directive/badge-directive.component';
import { StylePaginatorDirective } from './grid-table/grid-table-paginator/grid-table-pagination.component';
import { HeaderTabComponent } from './header-tab/header-tab/header-tab.component';
import { MultiSelectComponent } from './multi-select-control/multi-select/multi-select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { KendoModule } from '../kendo-module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    AngularSvgIconModule,
    TextMaskModule,
    MatTableExporterModule,
    NgxMatSelectSearchModule,
    KendoModule
  ],
  declarations: [
    OnlyNumberDirective,
    UnderConstructionComponent,
    SidebarAccountComponent,
    PaymentMethodsComponent,
    PeopleEditComponent,
    SearchUnitComponent,
    CrossSearchComponent,
    ActionsVisitorComponent,
    SearchPeopleComponent,
    PhotoCameraComponent,
    SearchVisitorsComponent,
    SearchResidentComponent,
    SearchResidentStaffComponent,
    SearchAddressGoogleComponent,
    ContactsWidgetComponent,
    SearchResidentUnitsComponent,
    PhotoCameraImageUpload,
    OfficeInformationWidgetComponent,
    CustomSnackbarComponent,
    GridTableComponent,
    NotificationPanelComponent,
    BadgeDirectiveComponent,
    StylePaginatorDirective,
    HeaderTabComponent,
    MultiSelectComponent
  ],
  exports: [
    OnlyNumberDirective,
    UnderConstructionComponent,
    SidebarAccountComponent,
    PaymentMethodsComponent,
    PeopleEditComponent,
    SearchUnitComponent,
    CrossSearchComponent,
    SearchPeopleComponent,
    SearchVisitorsComponent,
    ActionsVisitorComponent,
    PhotoCameraComponent,
    SearchResidentComponent,
    SearchResidentStaffComponent,
    SearchAddressGoogleComponent,
    ContactsWidgetComponent,
    SearchResidentUnitsComponent,
    PhotoCameraImageUpload,
    OfficeInformationWidgetComponent,
    CustomSnackbarComponent,
    GridTableComponent,
    NotificationPanelComponent,
    BadgeDirectiveComponent,
    StylePaginatorDirective,
    HeaderTabComponent,
    MultiSelectComponent
  ],
  entryComponents: [
    PeopleEditComponent,
    CustomSnackbarComponent
    ]
})
export class SharedModule { }
