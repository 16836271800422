import { Component, Inject, OnInit } from '@angular/core';
import { BankAccount } from 'src/app/model/bank-account';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { CompanyBankAccount } from 'src/app/model/company-bank-account';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccessType } from 'src/app/model/access-type-ring';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { Bank } from 'src/app/model/bank';
import { CompanyBankAccountService } from 'src/app/services/company-bank-account.service';
import { TreeChecklistExample } from 'material2/src/material-examples/tree-checklist/tree-checklist-example';
import { BankService } from 'src/app/services/bank.service';

@Component({
  selector: 'app-bank-account-edit',
  templateUrl: './bank-account-edit.component.html',
  styleUrls: ['./bank-account-edit.component.css']
})
export class BankAccountEditComponent  implements OnInit {

  public accountId: number;
  public model: CompanyBankAccount;
  //public bankAccountForm: FormGroup;
  public bankss: Bank[] = [];
  public bankAccount: BankAccount = new BankAccount();
  public assocications: string[] = [];
  public companyId: number;
  public loading2: boolean;

  bankAccountForm = new FormGroup({
    bankAccountName: new FormControl(),
    merchantId: new FormControl(),
    bankId: new FormControl(),
    allowDebit: new FormControl()
    
 });
  update: any;

 constructor(private router: Router,
  private route: ActivatedRoute,
  public dialogRef: MatDialogRef<BankAccountEditComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialog,
  private fb: FormBuilder,
  private companyAccountService: CompanyBankAccountService,
  private bankService: BankService) {

  //super();
  
  this.loadSecurityRings();
  this.model = new CompanyBankAccount();
  //this.bankAccountForm = this.model.buildFormGroup();
 }

 protected loadSecurityRings() {
  //this.securityEntity.rings.push({ringId : 577, accessType: AccessType.Add });
  //this.securityEntity.rings.push({ringId : 777, accessType: AccessType.Update });
  //this.entityRings.push(this.securityEntity);
}

  async ngOnInit() {
    this.loading2 = true;
    await this.bankService.getAll().toPromise().then(p=>{
      this.bankss = p;      
    });
    this.loading2 = false;
     this.update = this.data.isUpdate;
      console.log(this.data.model);
    this.model = this.data.model;
    this.companyId = this.data.companyId;
    if(this.model) {
      this.bankAccountForm.controls["bankId"].setValue(this.model.bankId);
      this.bankAccountForm.controls["bankAccountName"].setValue(this.model.altBankName);
      this.bankAccountForm.controls["merchantId"].setValue(this.model.bankAccountNum);
      this.bankAccountForm.controls["allowDebit"].setValue(this.model.acceptACH);
      this.accountId = this.model.bankId;
    }
   // }
  }

  public onSave() {
    console.log(this.bankAccountForm);
    if(this.bankAccountForm.valid) {
      if(this.update == false) {
          this.model = new CompanyBankAccount();     
          this.model.companyId = this.companyId;
          this.model.creationDate= new Date();
          this.model.modificationDate = new Date();
          this.model.creationUserId = 1;
          this.model.modificationUserId = 1;
          this.model.isActive = true;
          this.model.bankCode = "4444444";
          this.model.companyChartOfAccountsId = 3;

      }
      this.model.bankId = this.bankAccountForm.controls["bankId"].value;
      this.model.altBankName = this.bankAccountForm.controls["bankAccountName"].value;
      this.model.bankAccountNum = this.bankAccountForm.controls["merchantId"].value;

      //console.log(this.bankAccountForm.controls["bankId"].value);
      //console.log(this.bankAccountForm.controls["bankAccountName"].value);
      //console.log(this.bankAccountForm.controls["merchantId"].value);
      //console.log(this.bankAccountForm.controls["allowDebit"].value);
      this.loading2 = true;
      if(this.update == true) {
        this.companyAccountService.update(this.model).subscribe(p=>{
          this.loading2 = false;
          this.dialogRef.close({ valid: true })
        });
      } else {
        this.companyAccountService.add(this.model).subscribe(q=>{
          this.loading2 = false;
          this.dialogRef.close({ valid: true })
        });
      }


    } else {
      alert('verify please');
    }
  }

  onCancel(): void {
    if (this.bankAccountForm.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else  {
      this.dialogRef.close({ valid: false });
    }
  }

  delete(): void {
    this.loading2 = true;
    this.companyAccountService.delete(this.model.companyBankAccountId).subscribe(p=>{
      this.loading2 = false;
      this.dialogRef.close({ valid: true })
    });
  }

}
