import { Injectable, Inject } from '@angular/core';
import { InvoiceTemplateDistribution } from '../model/invoice-template-distribution';
import { HttpClient } from '@angular/common/http';
import { LevelFilter } from '../model/breadcrumb';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
@Injectable({
  providedIn: 'root'
})
export class InvoiceTemplateDistributionService extends KendoGridBaseService<InvoiceTemplateDistribution> {

  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'invoiceTemplateDistributions', LevelFilter.Community, appConfig);
  }

  public getByFieldId(invoiceTemplateId: number) {
    return this.http.get(`${this.endpoint}${this.entity}/byFieldId/InvoiceTemplateId/${invoiceTemplateId}`, { headers: this.buildGetHeaders() });
  }
}
