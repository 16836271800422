import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { toODataString } from "@progress/kendo-data-query";
import { BehaviorSubject, Observable, forkJoin } from "rxjs";
import { map, tap } from "rxjs/operators";
import { LocalStorageHelper } from "../helpers/local-storage-helper";
import { LevelFilter } from "../model/breadcrumb";
import { SearchParamsBreadcrumb } from "../model/search-params-breadcrumb";
import { AppConfig } from "../app-config";
import { Inject } from "@angular/core";
import { APP_CONFIG } from "../app.config";

export abstract class KendoGridBaseService<T> extends BehaviorSubject<GridDataResult> {
    public loading: boolean;
    public searchParamsBreadcrumb = new SearchParamsBreadcrumb();
    public endpoint = '';

    /**
     * 
     * @param http http client
     * @param endpoint api endpoint coming from environment.ts
     * @param entity entity that will be combined to the endpoint for the query
     * @param filterLevel filter level based on the breadcrumbs
     */
    constructor(public http: HttpClient, protected endpointName: string, protected entity: string, 
                protected filterLevel: LevelFilter,
                @Inject(APP_CONFIG) protected appConfig?: AppConfig) 
    {
        super(null);
        if (appConfig !== null) {
            if (endpointName in this.appConfig.endpoints) {
                this.endpoint = this.appConfig.endpoints[endpointName];
            }
            this.setBreadcrumbParams();
        }
    }

    /**
     * Method to query the data from database
     * @param state is a parameter that is use to filter the data using the parameter
     */
    public query(state): Observable<any> {
        return this.fetch(this.entity, state);
    }

    /**
     * Method to query data using API and parsing parameter using kendo string parser
     * @param state parameter to filter the data using toODataString parser by Kendo framework
     */
    public queryGridDataResult(state: any): Observable<any> {
        return this.fetchGridDataResult(this.entity, state);
    }

    protected fetchGridDataResult(entity: string, state: any): Observable<GridDataResult> {
        const queryStr = `${toODataString(state)}&$count=true`;
        this.loading = true;

        return this.http
            .get(`${this.endpoint}${entity}?${queryStr}`)
            .pipe(
                map(response => (<GridDataResult>{
                    data: response['value'],
                    total: parseInt(response['@odata.count'], 10)
                })),
                tap(() => this.loading = false)

            );
    }

    /**
     * 
     * @param entity defined on the constructor of the class
     * @param queryParams query params that will be used to query the data from the database
     */
    protected fetch(entity: string, queryParams: string): Observable<any> {
        this.loading = true;
        return this.http
            .get(`${this.endpoint}${entity}/${queryParams}`)
            .pipe(
                map(response => ({
                    data: response['value'],
                    total: parseInt(response['count'], 10)
                })),
                tap(() => this.loading = false)
            );
    }

    /**
     * Method that will get the list of data from the database using breadcrumbs
     * @param pageIndex determine page[ of the table
     * @param pageSize  number of data the table will be displayed
     * @param filter parameter that will filter the data
     * @param sortData sort parameter to order the data
     */
    protected getList(pageIndex: number, pageSize: number, filter?: string, sortData?: string): Observable<any> {
        this.loading = true;
        let queryString = `${pageIndex}/${pageSize}/${filter == null ? '' : '/' + filter}`;
        return forkJoin(this.http.get(`${this.endpoint}${this.entity}/${queryString}`, { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`));
    }

    public getAll(): Observable<any> {
        return forkJoin(this.http.get(`${this.endpoint}${this.entity}/`, { headers: this.buildGetHeaders() }));
    }


    public getById(fieldName: string, id: number): Observable<any> {
        let queryString = `${fieldName}/${id}`;
        return this.http.get(`${this.endpoint}${this.entity}/byFieldId/${queryString}`, { headers: this.buildGetHeaders() });
    }

    protected getAllPaged(pageIndex: number, pageSize: number, filter?: string, sortData?: string): Observable<any> {
        let queryString = `${pageIndex}/${pageSize}/${filter == null ? '' : '/' + filter}`;
        return forkJoin(this.http.get(`${this.endpoint}${this.entity}/${queryString}`, { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`, { headers: this.buildGetHeaders() }));
    }

    protected getAllPagedWithFilters(pageIndex: number, pageSize: number, filter?: string, sortData?: string): Observable<any> {
        let queryString = `${pageIndex}/${pageSize}`;
        queryString = this.parseParameters(queryString, filter, sortData);
        return forkJoin(this.http.get(`${this.endpoint}${this.entity}/allpagewithfilters/${queryString}`, { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`, { headers: this.buildGetHeaders() }));
    }

    protected getByCompanyId(companyId: number, sortData?: string): Observable<any> {
        let sort = '';
        if (sortData) {
            const sortCol = sortData.split('|');
            if (sortCol[1] !== '') {
                sort = (sort + '?sortDir=') + (sortCol[1] !== '' ? sortCol[1] : 'asc');
                sort = '&sortCol=' + sortCol[0];
            }
        }
        return forkJoin(this.http.get(`${this.endpoint}${this.entity}/${companyId}`, { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`, { headers: this.buildGetHeaders() }));
    }

    public delete(id: number): Observable<any> {
        return this.http.delete(`${this.endpoint}${this.entity}/${id}`, { headers: this.buildGetHeaders() });
    }

    public softDelete(id: number): Observable<any> {
        let body = {
          modificationOriginId: 2
        }
        return this.http.post<any>(`${this.endpoint}${this.entity}/softDelete/` + id, body, { headers: this.buildGetHeaders() });
      }

    public getEntity(entityName: string): Observable<any> {
        return this.http.get(`${this.endpoint}${entityName}`, { headers: this.buildGetHeaders() })
    }

    public add(data: T): Observable<any> {
        data['originId'] = 2;
        return this.http.post(`${this.endpoint}${this.entity}/`, data, { headers: this.buildGetHeaders() })
    }

    public addBulk(entities: T[]): Observable<any> {
        entities.map(x => x['originId'] = 2);
        return this.http.post<any>(`${this.endpoint}${this.entity}/bulk`, entities, { headers: this.buildGetHeaders() });
    }

    public update(entity: T): Observable<null> {
        entity['originId'] = 2;
        entity['modificationOriginId'] = 2;
        return this.http.put<null>(`${this.endpoint}${this.entity}/`, entity, { headers: this.buildGetHeaders() });
    }

    private setBreadcrumbParams(pmcId?: number, communityId?: number, areaId?: number) {
        this.searchParamsBreadcrumb.propertyManagementCompanyId = pmcId ? pmcId : LocalStorageHelper.getManagementCompanyFromBreadcrumb();
        this.searchParamsBreadcrumb.communityId = communityId ? communityId : LocalStorageHelper.getCommunitiesFromBreadcrumb();
        this.searchParamsBreadcrumb.areaId = areaId ? areaId : LocalStorageHelper.getBuildingFromBreadcrumb();
        this.searchParamsBreadcrumb.unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
        this.searchParamsBreadcrumb.AccountId = LocalStorageHelper.getAccountIdFromBreadcrumb();
    }

    protected buildGetHeaders(): HttpHeaders {
        const token = LocalStorageHelper.getAuthToken();
        let getHeaders = new HttpHeaders();
        getHeaders = getHeaders.set('Authorization', 'bearer ' + token);

        const impersonatedFlag = LocalStorageHelper.getImpersonatedFlag();
        const loggedUserInfo = LocalStorageHelper.getLoggedUserInfo(impersonatedFlag);
        if (loggedUserInfo) {
            getHeaders = getHeaders.set('UserId', String(loggedUserInfo.userId));
        }

        return getHeaders;
    }

    public groupBy(objectArray, property) {
        return objectArray.reduce((acc, obj) => {
           const key = obj[property];
           if (!acc[key]) {
              acc[key] = [];
           }
           // Add object to list for given key's value
           acc[key].push(obj);
           return acc;
        }, {});
     }

    private parseParameters(queryStr: string, filter: string, sortData: string): string {
        queryStr = `${queryStr}${filter == null || filter == '' ? '' : '/' + filter}`;
        if (sortData) {
            const sortCol = sortData.split('|');
            if (sortCol[0] !== undefined) {
                if (filter == null || filter == '') {
                    queryStr = `${queryStr}?sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
                } else {
                    queryStr = `${queryStr}&sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
                }
            }
        }
        return queryStr;
    }


}