import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css']
})
export class CustomDialogComponent implements OnInit {
  @Input() title = '';
  @Input() minWidh = 250;
  @Input() width = 450;
  @Input() dialogContentTemplate: TemplateRef<any>;
  @Input() dialogButtonTemplate: TemplateRef<any>;
  @Input() isOpened = false;
  @Output() dialogStateChange = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  public onClose() {
    this.dialogStateChange.emit(false);

  }

}
