import { Injectable, Inject } from '@angular/core';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { PaymentInvoice } from '../model/payment-invoice';
import { HttpClient } from '@angular/common/http';
import { LevelFilter } from '../model/breadcrumb';
import * as AppConstants from '../app-constants';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends KendoGridBaseService<PaymentInvoice> {

  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) { 
    super(http, AppConstants.EndpointNames.Financials, 'payment', LevelFilter.Community, appConfig);
  }
}
