import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { LedgerRoutingModule } from './ledger-routing/ledger-routing.module';

import { PaymentComponent } from './payment/payment.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { Transactions2Component } from './transactions2/transactions2.component';
import { MatSelectModule, MatPaginatorModule, MatSortModule, MatTableModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../shared/shared.module';
import { InvoiceUploadComponent } from './invoice/invoice-upload/invoice-upload.component';
import { InvoiceDetailComponent } from './invoice/invoice-details/invoice-details.component';
import { AngularSvgIconModule  } from 'angular-svg-icon';
import { KendoModule } from '../kendo-module';
import { InvoiceListComponent } from './invoice/invoice-list/invoice-list/invoice-list.component';
import { CustomDialogComponent } from '../app-dialogs/custom-dialog/custom-dialog/custom-dialog.component';
import { FileTimelineComponent } from '../shared/file-timeline/file-timeline/file-timeline.component';
import { InvoiceRecurrenceComponent } from './invoice/invoice-recurrence/invoice-recurrence/invoice-recurrence.component';
import { InvoiceRunRecurrenceComponent } from './invoice/invoice-run-recurrence/invoice-run-recurrence/invoice-run-recurrence.component';
import { InvoiceRecurrenceListComponent } from './invoice/invoice-recurrence-list/invoice-recurrence-list/invoice-recurrence-list.component';
import { PayablesModule } from './payables/payables.module';
import { BatchCodeDetailComponent } from './batch-code/batch-code-detail/batch-code-detail.component';
import { BatchCodeListComponent } from './batch-code/batch-code-list/batch-code-list.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LedgerRoutingModule,
    MaterialModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    AngularSvgIconModule,
    KendoModule
  ],
  exports: [
    LedgerRoutingModule,
    TransactionsComponent,
    Transactions2Component,
    InvoiceUploadComponent,
    InvoiceDetailComponent,
    InvoiceListComponent,
    CustomDialogComponent
  ],
  declarations: [
    PaymentComponent,
    TransactionsComponent,
    Transactions2Component,
    InvoiceUploadComponent,
    InvoiceDetailComponent,
    InvoiceListComponent,
    InvoiceListComponent,
    CustomDialogComponent,
    FileTimelineComponent,
    InvoiceRecurrenceComponent,
    InvoiceRunRecurrenceComponent,
    InvoiceRecurrenceListComponent,
    BatchCodeDetailComponent,
    BatchCodeListComponent
  ],
  entryComponents: [
    BatchCodeDetailComponent
  ]
})
export class LedgerModule { }
