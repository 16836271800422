import { Injectable, Inject } from '@angular/core';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { InvoicePayment, IVendorDetails, IInvoiceDetail } from '../model/invoice-payment';
import { HttpClient } from '@angular/common/http';
import { LevelFilter } from '../model/breadcrumb';
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
import { forkJoin, Observable } from 'rxjs';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class InvoicePaymentService extends KendoGridBaseService<InvoicePayment> {
  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'invoicePayment', LevelFilter.Community, appConfig);
  }

  getAllWithFilters(pageIndex: number, pageSize: number, filter?: any, sortData?: string): Observable<any> {
    let queryString = `${pageIndex}/${pageSize}`;
    if (filter !== undefined) {
      if (filter.invoiceStatusId !== '' && filter.invoiceStatusId > 0) {
        queryString = `${queryString}?invoiceStatusId=${filter.invoiceStatusId}`
      }
    }
    return forkJoin(
      this.http.get(`${this.endpoint}${this.entity}/allpagewithfilters/${queryString}`, { headers: this.buildGetHeaders() }),
      this.getAllCountWithFilters(filter)
    );
  }

  getAllCountWithFilters(filter?: any): Observable<any> {
    let queryString = '';
    if (filter !== undefined) {
      if (filter.invoiceStatusId !== '' && filter.invoiceStatusId > 0) {
        queryString = `${queryString}?invoiceStatusId=${filter.invoiceStatusId}`
      }
    }
    return this.http.get(`${this.endpoint}${this.entity}/allCountWithFilters${queryString}`, { headers: this.buildGetHeaders() })
  }

  public loadVendors(tableData, community): any[] {
    let vendorTableData = [];
    tableData.forEach(data => {
      if (data.invoicePaymentId == community.invoicePaymentId) {
        let vendorDetail: IVendorDetails = {
          invoicePaymentId: data.invoicePaymentId,
          vendorId: data.vendorId,
          vendorName: data.vendorName,
          paymentDate: data.paymentDate,
          paymentStatus: data.paymentStatus,
          totalAmount: 0,
          invoices: []
        }
        vendorDetail.invoices = this.loadInvoices(tableData, vendorDetail);
        vendorDetail.invoices.forEach(invoice => {
          vendorDetail.totalAmount = vendorDetail.totalAmount + invoice.amount;
        });
        vendorTableData.push(vendorDetail);
      }
    })
    return vendorTableData;
  }

  private loadInvoices(tableData, vendor): any[] {
    let invoiceTableData = [];
    tableData.forEach(data => {
      if (data.invoicePaymentId == vendor.invoicePaymentId) {
        let invDetail: IInvoiceDetail = {
          invoicePaymentId: parseInt(data.invoicePaymentId),
          batchId: parseInt(data.batchId),
          invoiceId: data.invoiceId,
          invoiceNum: data.invoiceNum,
          amount: data.amount,
          isDeleted: false
        }
        invoiceTableData.push(invDetail);
      }
    })
    return invoiceTableData;
  }

  getUsersByCompany(companyId: number, userId?: number, roleId?: number): Observable<any> {
    let queryString = '/getByCompanyIdUserIdRoleId/' + companyId;
    const userEntity: string = 'user';
   if (userId && userId != undefined && userId > 0) {
     queryString = queryString + '?userId=' + userId;
   }

   if (roleId && roleId != undefined && roleId > 0) {
     if(userId && userId != undefined && userId > 0) {
       queryString = queryString + '&roleId=' + roleId;
     } else {
       queryString = queryString + '?roleId=' + roleId;
     }
     
   }
   //return this.http.get<User[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
   return this.http.get<any>(`${this.endpoint}${userEntity}${queryString}`, { headers: this.buildGetHeaders() })
 }
}


