import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AccountPayableService } from 'src/app/services/account-payables.service';
import { State } from '@progress/kendo-data-query';
import { PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { FormGroup, FormControl } from '@angular/forms';
import { InvoiceService } from 'src/app/services/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { AccountPayableBatchDetails } from 'src/app/services/account-payable-batch-details';
import { InvoicePaymentService } from 'src/app/services/invoice-payment.service';
import { InvoicePayment } from 'src/app/model/invoice-payment';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentInvoice } from 'src/app/model/payment-invoice';

@Component({
  selector: 'app-account-payable-list',
  templateUrl: './account-payable-list.component.html',
  styleUrls: ['./account-payable-list.component.scss'],
  animations: [
    trigger('searchOpenClose', [
      state('open', style({
        'width': '245px',
        'border': '1px solid lightgray',
        'padding-left': '5px'
      })),
      state('closed', style({
        'width': '0px',
        'border': 'none'
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ])
    ]),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class AccountPayableListComponent extends KendoGridAbstractClass {
  public searchString = '';
  public selectableSettings = {};
  public userRole = 1;
  public showAdvanceFilter = false;
  public showSearch = true;
  public selectedTableRowCount = [];
  public selectionItems: any = {
    selectedRows: [],
    deselectedRows: []
  };

  public totalSelectedAmount = 0;
  public payableFilterForm: FormGroup;
  public isBatchAdded: number = 0;
  @ViewChild(GridComponent) public grid: GridComponent;

  constructor(private accountPayableService: AccountPayableService,
    private invoiceService: InvoiceService,
    private invoicePaymentService: InvoicePaymentService,
    private paymentService: PaymentService,
    private toastrService: ToastrService) {
    super();
    this.exportFileName = 'payment-list';
    this.showAdvanceFilter = false;
    this.pageIndex = 1;
    this.state = {
      skip: 1, // current page number
      take: 10, // items per page
    };
    this.pageSizes = [10, 25, 50];
    this.data = {
      data: [],
      total: 0
    };
    
    this.payableFilterForm = new FormGroup(
      {
        bankId: new FormControl(),
        invoiceDate: new FormControl(),
        glDate: new FormControl(),
        communityName: new FormControl(),
        vendorName: new FormControl(),
        minAmount: new FormControl(),
        maxAmount: new FormControl()
      }
    )
    this.setSelectableSettings();
    this.getList();
  }


  public dataStateChange(state: any): void {
    this.state = state;
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageIndex = (event.skip / event.take) + 1;
    this.state.skip = event.skip;
    this.state.take = event.take;
    this.getList();
  }

  public applyFilter() {
    let minAmount = this.payableFilterForm.controls['minAmount'].value;
    let maxAmount = this.payableFilterForm.controls['maxAmount'].value;
    if (minAmount > maxAmount) {
      this.toastrService.error('Min Amount cannot be greater that Max Amount', 'Filter Error', {
        closeButton: true,
        timeOut: 5000,
        extendedTimeOut: 3000
      });
      return;
    }
    this.filter = {
      headerSearch: this.searchString,
      bankId: this.payableFilterForm.controls['bankId'].value,
      invoiceDate: this.payableFilterForm.controls['invoiceDate'].value,
      glDate: this.payableFilterForm.controls['glDate'].value,
      communityName: this.payableFilterForm.controls['communityName'].value,
      vendorName: this.payableFilterForm.controls['vendorName'].value,
      minAmount: minAmount,
      maxAmount: maxAmount
    };
    this.getList();
  }

  public resetFilter() {
    this.payableFilterForm.controls['bankId'].setValue('');
    this.payableFilterForm.controls['invoiceDate'].setValue('');
    this.payableFilterForm.controls['glDate'].setValue('');
    this.payableFilterForm.controls['communityName'].setValue('');
    this.payableFilterForm.controls['vendorName'].setValue('');
    this.payableFilterForm.controls['minAmount'].setValue('');
    this.payableFilterForm.controls['maxAmount'].setValue('');
    this.filter = undefined
    this.getList();
  }

  public setSelectableSettings(): void {
    switch (this.userRole) {
      case 1: {
        this.selectableSettings = {
          checkboxOnly: true
        };
        break;
      }
      case 2: {
        this.selectableSettings = null;
        break;
      }
      case 3: {
        this.selectableSettings = null;
        break;
      }
    }
  }

  private onFilterClick() {
    this.showAdvanceFilter = !this.showAdvanceFilter;
  }

  private searchItem() {
    this.showSearch = !this.showSearch;
  }

  private performSearch() {
    console.log('implement search')
  }

  public onSelectionChange(e) {
    e.selectedRows.forEach(item => {
      this.totalSelectedAmount = this.totalSelectedAmount + item.dataItem.amount;
      this.selectionItems.selectedRows.push(item.dataItem);
    });

    e.deselectedRows.forEach(item => {
      this.totalSelectedAmount = this.totalSelectedAmount - item.dataItem.amount;
      this.selectionItems.deselectedRows.push(item.dataItem);
    });

  }

  public approve() {
    // insert invoice on AP.Batch table
    // insert record on AP.payment for each selected invoice
    // insert invoice on AP.InvoicePayment with the batchId for each selected invoice
    // create service for InvoicePayment to handle the add
    // create model for InvoicePayment
    // if deselected, then remove on AP.InvoicePayment and AP.batch??
    if (this.isBatchAdded == 0) {
      // add data on AP.Batch
      let batchDetail: AccountPayableBatchDetails[] = this.accountPayableService.groupBy(this.selectionItems.selectedRows, 'companyId');
      Object.keys(batchDetail).forEach(item => {
        let batchDetailToAdd: AccountPayableBatchDetails = {
          batchId: 0,
          batchTypeId: 1,
          batchCode: 'PMT',
          batchName: 'PMT',
          companyId: parseInt(item),
          description: 'PMT',
        };

        // add a batch using the object key
        this.accountPayableService.add(batchDetailToAdd).subscribe(result => {
          this.isBatchAdded == result.id;
          const invoicesToAdd: any[] = batchDetail[item];

          invoicesToAdd.forEach(invoice => {
            //add payment using selected invoice
            let paymentInvoice: PaymentInvoice = {
              paymentId: 0,
              description: '',
              paymentTypeId: invoice.paymentTypeId == null ? 1 : invoice.paymentTypeId,
              companyId: invoice.companyId,
              isDeleted: 0
            };
            this.paymentService.add(paymentInvoice).subscribe(paymentResult => {
              // add item on AP.InvoicePayment table using the invoiceId and batchId
              // paymentId is not defined
              let invoicePaymentToAdd: InvoicePayment = {
                invoicePaymentId: 0,
                invoiceId: invoice.invoiceId,
                paymentId: paymentResult.id,
                batchId: result.id,
                amount: invoice.amount,
                isApproved: false
              }


              this.invoicePaymentService.add(invoicePaymentToAdd).subscribe(result => {
              }, error => {
                console.log(error);
              });

            });
          });

        });
      });
    }
    this.selectedTableRowCount = [];
    this.getList();
  }

  public exportCVS() {
    this.grid.saveAsExcel();
  }

  public exportToPDF() {
    this.grid.saveAsPDF();
  }

  protected getList() {
    this.loading = true;
    if (this.filter == undefined) {
      this.filter = {
        invoiceStatusId: 3
      }
    }
    this.invoiceService.getAllWithFilters(this.pageIndex, this.state.take, this.filter, this.sort).subscribe(results => {
      this.loading = false;
      this.data = {
        data: results[0],
        total: results[1][0].recordCount
      };
    }, error => {
      this.loading = false;
      console.log(error);
    })
  }


}
