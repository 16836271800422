import { BaseForm } from "../building-blocks/base-form";
import { FormField } from "../building-blocks/decorators/reactive-form-decorators";
import { Validators, FormArray } from "@angular/forms";

export class GlAccount extends BaseForm {
    invoiceDistributionId = 0;
    invoiceId = 0;
    isDeleted = false;
    @FormField('companyChartOfAccountsId', [null, Validators.required])
    companyChartOfAccountsId = 3;

    @FormField('glDate', [null])
    glDate = new Date();

    @FormField('amount', [null, Validators.required])
    amount = null;

    @FormField('YTDBudget', [null])
    YTDBudget = null;

    @FormField('annualBudget', [null])
    annualBudget = null

    @FormField('glDescription', [null])
    glDescription = null;
}

export class InvoiceDetail extends BaseForm {
    invoiceId = 0;
    unpaidBalance = 0;
    termsId = 1;
    orgId = 1;
    isLocked = false;
    lockedByUserId = null;
    lockedDate = null;
    batchId = 4 // TODO: this is temporary
    invoiceStatusId = 1; // TODO: this is temporary
    vendorPaymentPrepaid;
    
    @FormField('companyId', [null, Validators.required])
    companyId = null;
    
    @FormField('companyVendorId', [null, Validators.required])
    companyVendorId = null;

    @FormField('invoiceTypeId', [null, Validators.required])
    invoiceTypeId = null;

    @FormField('invoiceDate', [new Date(), Validators.required])
    invoiceDate = null

    @FormField('invoiceNum', [null, Validators.required])
    invoiceNum = null;

    @FormField('amount', [0, Validators.required])
    amount = 0;

    // @FormField('isSeparateCheck', null)
    // isSeparateCheck = false;

    // @FormField('isHoldPayment', null)
    // isHoldPayment = false;

    @FormField('isNoApproval', null)
    isNoApproval = false;

    @FormField('is1099Payment', null)
    is1099Payment = false;

    @FormField('glAccnts', [], GlAccount, true)
    glAccnts: FormArray;

    @FormField('invoiceDesc', [null, Validators.required])
    invoiceDesc = null;

    // @FormField('vendorPaymentPrepaid', null)
    // vendorPaymentPrepaid = null;

    // @FormField('vendorPaymentRecurring', null)
    // vendorPaymentRecurring = null;

    // @FormField('vendorPaymentAllocated', null)
    // vendorPaymentAllocated = null;

    // @FormField('vendorPaymentRush', null)
    // vendorPaymentRush = null;

    // @FormField('separateCheck', null)
    // separateCheck = null;

    // @FormField('holdPayment', null)
    // holdPayment = null;

    // @FormField('noApproval', null)
    // noApproval = null;

    // @FormField('otherPayment', null)
    // otherPayment = null;


    // @FormField('vendorAccountNumber', [null, Validators.required])
    // vendorAccountNumber = null;

    @FormField('bankId', [null, Validators.required])
    bankId = null;

    // @FormField('paymentTypeId', [null, Validators.required])
    // paymentTypeId = null;



}
