import { Component, Input, Output, EventEmitter } from '@angular/core';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { Observable, of } from 'rxjs';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-vendor-invoice-detail',
  templateUrl: './vendor-invoice-detail.component.html',
  styleUrls: ['./vendor-invoice-detail.component.scss'],
})
export class VendorInvoiceDetailComponent extends KendoGridAbstractClass {
  @Input() invoices: any;
  @Input() invoicePayment: any;
  @Input() isSelectable: boolean = false;
  public view = new Observable<any>();
  public mySelection = [];

  @Output() onInvoiceSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  public selectableSettings = {
    checkboxOnly: true
  };
  constructor(private invoiceService: InvoiceService) {
    super();
  }

  ngOnInit() {
    this.selectableSettings.checkboxOnly = this.isSelectable;
    this.getList();
  }

 
  public onInvoiceDetailSelectionChange(dataItem) {
    dataItem.selectedRows.forEach(item => {
      this.invoices = this.invoices.map(data => {
        if (data.invoiceId == item.dataItem.invoiceId) {
          data.isDeleted = true;
          this.mySelection.push(item.dataItem.invoiceId);
        }
        return data;
      });
    })

    dataItem.deselectedRows.forEach(item => {
      this.invoices = this.invoices.map(data => {
        if (data.invoiceId == item.dataItem.invoiceId) {
          data.isDeleted = false;
          this.mySelection = this.mySelection.filter(mySelectionItem => mySelectionItem !== data.invoiceId );
        }
        return data;
      });
    })
    
    this.view = of(this.invoices);
    this.onInvoiceSelectionChange.emit(dataItem);
  }

  protected getList() {
    // this.view = of(this.invoices);
     this.view = this.invoiceService.getInvoiceByBatchIdInvoiceId(this.invoicePayment.batchId, this.invoicePayment.invoiceId);
  }
}
