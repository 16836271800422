import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../app.config';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { LevelFilter } from '../model/breadcrumb';
import { InvoiceNotes } from '../model/invoice-notes';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { noteItem, noteItemYear } from '../model/note-item';

@Injectable({
  providedIn: 'root'
})
export class InvoiceNotesService extends KendoGridBaseService<InvoiceNotes> {

  constructor(public http: HttpClient,  @Inject(APP_CONFIG) appConfig: AppConfig) { 
    super(http, AppConstants.EndpointNames.Financials, 'invoiceNotes', LevelFilter.Community, appConfig);
  }

  getByInvoiceIdAndYear(invoiceId: number, year: number): Observable<InvoiceNotes[]> {
    let queryString = '/getByInvoice/';
    if (invoiceId !== undefined && invoiceId > 0) {
      queryString = queryString + invoiceId;
    }
    return this.http.get<InvoiceNotes[]>(`${this.endpoint}${this.entity}${queryString}`, { headers: this.buildGetHeaders() });
  }

  getQuestionsById(invoiceId: number): noteItemYear[] {
    let result: noteItemYear[] = [];
    this.http.get<any[]>(`${this.endpoint}${this.entity}/allRecursivepagedwithfilters/1/10?InvoiceId=${invoiceId}&NoteTypeId=2&SortCol=InvoiceId&SortDir=2`, { headers: this.buildGetHeaders() }).toPromise().then(p=>{
      //this.questionsByYear = p;
      if(p && p.length>0) {
        
        let years: number[] = [];
        p.forEach(ele => {
          let date: Date = new Date(ele.creationDate);

          let notey = new noteItemYear();
          notey.year = date.getFullYear();
          notey.subitems = [];

          if(years.includes(date.getFullYear()) === false) {
            years.push(date.getFullYear());
            result.push(notey);
          }
        });
        result.forEach(noteYear => {
          
          p.forEach(element => {
            let date: Date = new Date(element.creationDate);

            if(element.noteTypeId === 2 && date.getFullYear() === noteYear.year) {
              //question 
              let q1 = new noteItem();
              q1.label=element.label;
              q1.icon="question";
              q1.author=element.firstName + ' ' + element.lastName;
              q1.uploadDateTime= date.toLocaleString('en-GB');//'MM/DD/YYYY - XX:XXPM';
              q1.subitems = [];
              q1.noteId = element.invoiceNoteId;
          
              p.forEach(answer => {
                if(answer.noteTypeId===3 && answer.noteParentId === element.invoiceNoteId) {
                  let datea: Date = new Date(answer.creationDate);
                  let a1 = new noteItem();
                  a1.label= answer.label;
                  a1.icon= "answer";
                  a1.author= answer.firstName + ' ' + answer.lastName;;
                  a1.uploadDateTime= datea.toLocaleString('en-GB');//'MM/DD/YYYY - XX:XXPM';
                  a1.noteId = answer.invoiceNoteId;
                  q1.subitems.push(a1);
                }
              });
              noteYear.subitems.push(q1);
            }
  
          });


        });
        
      }
      return result;

    }, error => {
        console.log(error);
        this.loading = false;
        return result;
      }
        
        );
        return result;
  }
  
}
