// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import * as AppConstants from '../app/app-constants';

export const environment = {
  production: false,
  defaultDomain: 'gridenterprisedev.com',
  endpoints: {
    'C3PO': 'https://c3po.gridenterprisedev.com/api',
    'gridVendor': 'https://gringotts.gridenterprisedev.com/api',
    'menuService': 'https://menu.gridenterprisedev.com/api',
    'sortingHat': 'https://sortinghat.gridenterprisedev.com/api',
    'security': 'https://sec.gridenterprisedev.com/api',
    'tardis': 'https://tardis.gridenterprisedev.com/api',
    'notification': 'https://notifications.gridenterprisedev.com/api/notifications',
    'smaug': 'https://smaug.gridenterprisedev.com/api',
    'search': 'https://searchservice.gridenterprisedev.com/api',
    'newsfeed': 'https://newsfeed.gridenterprisedev.com/api',
    'pensieve': 'https://pensieve.gridenterprisedev.com/api',
    'thor': 'https://thor.gridenterprisedev.com/api',
    //'financials': 'http://localhost:7033/api/'
    'financials': 'https://financials.gridenterprisedev.com/api/'
  },
  apiKeys: {
    Smaug : 'F7F85f9ACPlIhPjJ2+fXAao9EbzGMqsqjIJVXrJfSq4=',
    C3PO : 'ADmEpdg0zEeJ7VjeWSgDOp63qxxYo6l3LYltDRYCss0',
    Gingotts : 'ADmEpdg0zEeJ7VjeWSgDOp63qxxYo6l3LYltDRYCss0',
    Pensieve : 'gFs03YTa+jVOi+2MGpmiZBGAwinXFi4ZuwFmu9cQLU0='
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCDobx-9X-i0nsYIfn5nywf2Qb7gJf-dQI',
    authDomain: 'gridenterprisedev.firebaseapp.com',
    databaseURL: 'https://gridenterprisedev.firebaseio.com',
    projectId: 'gridenterprisedev',
    storageBucket: 'gridenterprisedev.appspot.com',
    messagingSenderId: '1090432943916'
  },
  apiKeyGoogleMaps: 'AIzaSyA3Jo3ZkCJUbyBdlnXmk9ZJVb9eYlHtGvc',
  messagingPublicKey: 'BNDtphpMjTA4kE3KVTq_Bpqxrw4WSuUmgVDX1koIOIPC5aJ7oXRYhTmxK1Srjkw6OiaJj_A4adzvv1Ek1Ky9Jd8',
  snsApplicationName: 'GridEnterpriseDev',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
