import { NgModule } from '@angular/core';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export'
import '@progress/kendo-ui';

@NgModule({
    imports: [
        GridModule,
        LayoutModule,
        ButtonsModule,
        UploadModule,
        DialogModule,
        ExcelModule,
        PDFModule,
        ExcelExportModule
    ],
    exports: [
        GridModule,
        LayoutModule,
        ButtonsModule,
        UploadModule,
        DialogModule,
        ExcelModule,
        PDFModule,
        ExcelExportModule
    ]
})

export class KendoModule {

}