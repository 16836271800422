import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-header-tab',
  templateUrl: './header-tab.component.html',
  styleUrls: ['./header-tab.component.scss']
})
export class HeaderTabComponent implements OnInit {
  @Input() tabItems = [];
  @Output() tabChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("tabSelection") matTabSelect: MatSelect;
  @Input() selectedTabId = 1;
  selectedTabName: string;

  constructor() { }

  ngOnInit() {
  }

  changeTab(tabId: number, tabEntity: any) {
    if (tabEntity.isActive) {
      this.selectedTabId = tabId;
      this.selectedTabName = tabEntity.name;
      this.tabChange.emit({'tabId': this.selectedTabId});
    }
  }

  triggerMatSelectionChange() {
    this.matTabSelect.open();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //this.populateTabs();
  }
}
