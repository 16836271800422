import { BaseForm } from "../building-blocks/base-form";
import { FormField } from "../building-blocks/decorators/reactive-form-decorators";
import { Validators, FormArray } from "@angular/forms";

export class BatchCodeDetail {
    batchCodeId = 0;
    batchCode = null;

    description = null;

    batchTypeId = null;

    batchType = null;
  creationDate: Date = null;
  modificationDate: Date = null;
  creationUserId: number;
  modificationUserId: number;
    
}
