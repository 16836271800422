export class BankAccount {
    bankAccountId: number;
    bankAccountTypeId: number;
    bankAccountTypeName: string;
    bankId: number;
    bankName: string;
    lastDigits: number;
    name: string;
    paypointId: number;
    token: number;
    userDataId: number;
}
