export class noteItemYear {
    year: number;
    subitems: any;
}

export class noteItem {
    label: string;
    icon: string;
    author: string;
    uploadDateTime: string;
    status: string;    
    subitems: any;
    noteId: number;
}