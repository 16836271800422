import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as AppConstants from '../app-constants';
import { AppConfig, APP_CONFIG } from '../app.config';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class InvoiceVendorService extends KendoGridBaseService<InvoiceVendor> {
  constructor(public http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(http, AppConstants.EndpointNames.Financials, 'vendor', LevelFilter.Community, appConfig);
  }

  getByBatchIdInvoiceId(batchId: number, invoiceId: number): Observable<any> {
    return this.http.get(`${this.endpoint}${this.entity}/getByBatchIdInvoiceId/${batchId}/${invoiceId}`, { headers: this.buildGetHeaders() })
  }

  getAllByFilter(pmcid: number, filterValue: string): Observable<any> {
    return this.http.get(`${this.endpoint}${this.entity}/getByPropertyManagementCompanyIdLegalName/${pmcid}/${filterValue}`, { headers: this.buildGetHeaders() })
  }
}


export class InvoiceVendor {
  vendorId: number;
  nickName: string;
}