import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { KendoGridBaseService } from '../building-blocks/kendo-grid-base-service';
import { InvoiceRecurrence } from '../model/invoice-recurrence';
import { LevelFilter } from '../model/breadcrumb';
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
@Injectable({
  providedIn: 'root'
})
export class InvoiceTemplateService  extends KendoGridBaseService<InvoiceRecurrence> {

  constructor(public http: HttpClient,  @Inject(APP_CONFIG) appConfig: AppConfig) { 
    super(http, AppConstants.EndpointNames.Financials, 'invoicetemplates', LevelFilter.Community, appConfig);
  }

  getAllPaginatedWithFilters(pageIndex: number, pageSize: number, filter?: any, sortData?: string): Observable<any> {
    let queryString = `${pageIndex}/${pageSize}`;
    let dateFrom = '';
    let dateTo = '';
    if (filter !== undefined) {
      if (filter.headerSearch !== undefined && filter.headerSearch !== '') {
        queryString = `${queryString}/${filter.headerSearch}?`;
      } else {
        queryString = `${queryString}/?`;
      }

      if (filter.invoiceDate !== undefined && filter.invoiceDate !== null) {
        let newDate = new Date(filter.invoiceDate);
        dateFrom = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getUTCFullYear()}`;
        dateTo = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getUTCFullYear()}`;
        queryString = `${queryString}&invoiceDateFrom=${dateFrom}&invoiceDateTo=${dateTo}`;
      }

      if (filter.lockedByUserId !== '' && filter.lockedByUserId !== undefined) {
        queryString = `${queryString}&lockedByUserId=${filter.lockedByUserId}`
      }

      if (filter.batchId !== '' && filter.batchId !== undefined) {
        queryString = `${queryString}&batchId=${filter.batchId}`;
      }

      if (filter.communityId !== '' && filter.communityId !== undefined) {
        queryString = `${queryString}&companyId=${filter.communityId}`;
      }

      if (filter.vendorId !== '' && filter.vendorId !== undefined) {
        queryString = `${queryString}&companyVendorId=${filter.vendorId}`;
      }

      if (filter.invoiceStatusId > 0) {
        queryString = `${queryString}&invoiceStatusId=${filter.invoiceStatusId}`;
      }

      if (filter.amountFrom > 0 && filter.amountTo > 0) {
        queryString = `${queryString}&amountFrom=${filter.amountFrom}&amountTo${filter.amountTo}`;
      }
      

    }
    
    if (sortData) {
        const sortCol = sortData.split('|');
        if (sortCol[0] !== undefined) {
            if (filter == null || filter == undefined) {
              queryString = `${queryString}?sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
            }else {
              queryString = `${queryString}&sortDir=${sortCol[0]}&sortCol=${sortCol[1]}`;
            }
        }
    }
    return forkJoin(
      this.http.get(`${this.endpoint}${this.entity}/allpagewithfilters/${queryString}`, { headers: this.buildGetHeaders() }),
      this.getAllCountWithFilters(filter)
    );
  }

  public getAllCountWithFilters(filter: any): Observable<any> {
    let queryString = '';
    let dateFrom = '';
    let dateTo = '';
    if (filter !== undefined) {
      if (filter.headerSearch !== undefined && filter.headerSearch !== '') {
        queryString = `${queryString}/${filter.headerSearch}?`;
      } else {
        queryString = `${queryString}/?`;
      }

      if (filter.invoiceDate !== undefined && filter.invoiceDate !== null) {
        let newDate = new Date(filter.invoiceDate);
        dateFrom = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getUTCFullYear()}`;
        dateTo = `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getUTCFullYear()}`;
        queryString = `${queryString}&invoiceDateFrom=${dateFrom}&invoiceDateTo=${dateTo}`;
      }

      if (filter.lockedByUserId !== '' && filter.lockedByUserId !== undefined) {
        queryString = `${queryString}&lockedByUserId=${filter.lockedByUserId}`
      }

      if (filter.batchId !== '' && filter.batchId !== undefined) {
        queryString = `${queryString}&batchId=${filter.batchId}`;
      }

      if (filter.communityId !== '' && filter.communityId !== undefined) {
        queryString = `${queryString}&companyId=${filter.communityId}`;
      }

      if (filter.vendorId !== '' && filter.vendorId !== undefined) {
        queryString = `${queryString}&companyVendorId=${filter.vendorId}`;
      }

      if (filter.invoiceStatusId > 0) {
        queryString = `${queryString}&invoiceStatusId=${filter.invoiceStatusId}`;
      }

      if (filter.amountFrom > 0 && filter.amountTo > 0) {
        queryString = `${queryString}&amountFrom=${filter.amountFrom}&amountTo${filter.amountTo}`;
      }
    }

    return this.http.get(`${this.endpoint}${this.entity}/allCountWithFilters`, { headers: this.buildGetHeaders() });
  }

  public updateInvoiceTemplateBatchByInvoiceTemplateId(invoiceTemplateBatch: any) {
    return this.http.put<null>(`${this.endpoint}${this.entity}/`, invoiceTemplateBatch, { headers: this.buildGetHeaders() });

  }

  getAllInvoiceTemplatesWithFilters(filters): Observable<any> {
    let queryString = '?';
    if (filters.companyId !== undefined && filters.companyId !== '' ) {
      queryString = queryString + '&companyId=' + filters.companyId;
    }

    if (filters.companyVendorId !== undefined && filters.companyVendorId !== '') {
      queryString = queryString + '&companyVendorId=' + filters.companyVendorId;
    }

    if (filters.invoiceTemplateId !== undefined && filters.invoiceTemplateId !== '')  {
      queryString = queryString + `&invoiceTemplateId=${filters.invoiceTemplateid}`;
    }

    return this.http.get(`${this.endpoint}${this.entity}${queryString}`, { headers: this.buildGetHeaders() });
  }

  getAllWithFilters(pageIndex: number, pageSize: number, filter?: any, sortData?: any): Observable<any> {
    let queryString = `${pageIndex}/${pageSize}`;
    let sortOrder = 0;

    if (sortData) {
      const sortCol = sortData.split('|');
      if (sortCol[0] !== undefined) {
        if (sortCol[0] == 'asc') {
          sortOrder = 1;
        } else {
          sortOrder = 2;
        }
        if (filter == null || filter == undefined) {
          queryString = `${queryString}?sortDir=${sortOrder}&sortCol=${sortCol[1]}`;
        } else {
          queryString = `${queryString}&sortDir=${sortOrder}&sortCol=${sortCol[1]}`;
        }
      }
    }
    return forkJoin(this.http.get(`${this.endpoint}${this.entity}/allpagewithfilters/${queryString}`,
      { headers: this.buildGetHeaders() }), this.http.get(`${this.endpoint}${this.entity}/count`, { headers: this.buildGetHeaders() }));
  }

  getInvoiceTemplateByInvoiceTemplateId(invoiceTemplateId: number) {
    return this.http.get(`${this.endpoint}${this.entity}/allByFilters?invoiceTemplateId=${invoiceTemplateId}`, { headers: this.buildGetHeaders() });
  }


}
