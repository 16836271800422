import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentChecked, AfterViewInit, Component, ElementRef, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatDialog, MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { ToasterMessages } from 'src/app/model/toastrMessages';
import { Vendor } from 'src/app/model/vendor';
import { CommunityService } from 'src/app/services/community.service';
import { InvoiceCommunityService } from 'src/app/services/invoice-community.service';
import { InvoiceCompanyVendorService } from 'src/app/services/invoice-company-vendor.service';
import { InvoicePaymentService } from 'src/app/services/invoice-payment.service';
import { InvoiceVendorService } from 'src/app/services/invoice-vendor.service';
import { BatchCodeService } from 'src/app/services/batchcode.service';
import { State } from '@progress/kendo-data-query';
import { BatchCodeDetailComponent } from '../batch-code-detail/batch-code-detail.component';
import { CrudResponseModel } from 'src/app/model/crud-response-model';

@Component({
  selector: 'app-batch-code-list',
  templateUrl: './batch-code-list.component.html',
  styleUrls: ['./batch-code-list.component.css'],
  animations: [
    trigger('searchOpenClose', [
      state('open', style({
        'width': '245px',
        'border': '1px solid lightgray',
        'padding-left': '5px'
      })),
      state('closed', style({
        'width': '0px',
        'border': 'none'
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ])
    ]),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class BatchCodeListComponent extends KendoGridAbstractClass {
  public batchCodes = [];
  public pageTitle = 'Batch Codes';
  public searchString = '';
  public showActionButton = false;
  public showLockIcon = false
  public hoveredIndex = 0;
  public userRole = 2; // TODO: this should come from data source
  public selectableSettings = {};
  public selectedStatus = 0;
  public isDeletedStatus = 0;
  public invoiceStatuses = [];
  public invoiceCommunities = [];
  private isLoading = false;
  public selectedVendor = new Subject<Vendor>();
  public vendorSearch: Vendor[] = [];
  public searchComplete = false;
  public eventReady = false;
  public isManager = false;

  public state: State = {
    skip: 0,
    take: 5,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild(GridComponent) public grid: GridComponent;
  public filterFormGroup: FormGroup;
  constructor(private batchCodeService: BatchCodeService,
    public communityService: CommunityService,
    public router: Router,
    private toastrService: ToastrService,
    private dialog: MatDialog) {
    super();
    // code that will clear the back to value in location bar
    LocalStorageHelper.setBackToButtonOnLocationBar('null');
    this.exportFileName = 'payment-list';
    this.showAdvanceFilter = false;
    this.pageIndex = 1;
    this.state = {
      skip: 1, // current page number
      take: 10, // items per page
    };
    this.pageSizes = [10, 25, 50];
    this.data = {
      data: [],
      total: 0
    };
    
    this.getList();
  }

  ngOnInit() {
    //this.getInvoiceRelatedEntity();
    this.isManager = LocalStorageHelper.isManager();
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageIndex = (event.skip / event.take) + 1;
    this.state.skip = event.skip;
    this.state.take = event.take
    this.getList();
  }

  public onSortChange(e) {
    this.sort = `${e[0].dir}|${e[0].field}`;
    this.getList();
  }

  public setSelectableSettings(): void {
    switch (this.userRole) {
      case 1: {
        this.selectableSettings = {
          checkboxOnly: true
        };
        break;
      }
      case 2: {
        this.selectableSettings = null;
        break;
      }
      case 3: {
        this.selectableSettings = null;
        break;
      }
    }
  }


  private performSearch() {
    console.log('implement search')
  }

  public actionClicked() {
    console.log('action button is clicked');
  }

  public onStatusSelectionChange(selectedStatus) {
    this.selectedStatus = selectedStatus.value;
    this.filter = {
      invoiceStatusId: this.selectedStatus
    }
    this.getList();
  }

  private activateHover() {
    setTimeout(() => {
      this.hoverFeature();
    });
  }

  private onLockClick(row) {
    console.log('implement lock click', row);
  }

  public exportCSV() {
    this.grid.saveAsExcel();
  }

  public exportToPDF() {
    this.grid.saveAsPDF();
  }

  public editItem(dataItem) {
   console.log(dataItem);
    /*
    this.invoiceService.update(dataItem).subscribe(data => {
      this.toastrService.success(ToasterMessages.SUCCESSMSG.toString());
      this.getList();
    }, error => {
      this.toastrService.error(ToasterMessages.ERRMSG.toString())
    });
    */
    this.menuTrigger.closeMenu();
  }

  public runRecurringInvoices() {
    this.router.navigate(['app/ledger/run-recurring-invoice/']);
    this.menuTrigger.closeMenu();
  }
/*
  public checkout(dataItem) {
    const impersonatedFlag = LocalStorageHelper.getImpersonatedFlag();
    const loggedUser = LocalStorageHelper.getLoggedUserInfo(impersonatedFlag);
    dataItem.lockedByUserId = loggedUser.userId;
    dataItem.lockedDate = new Date();
    this.invoiceService.update(dataItem).subscribe(data => {
      this.toastrService.success(ToasterMessages.SUCCESSMSG.toString());
      this.getList();
    }, error => {
      this.toastrService.error(ToasterMessages.ERRMSG.toString())
    });
    this.menuTrigger.closeMenu();
  }
*/
  public delete(dataItem) {

    this.batchCodeService.delete(dataItem.batchCodeId).subscribe(result => {
      this.toastrService.success(ToasterMessages.SUCCESSMSG);
      this.getList();
    }, error => {
      this.toastrService.error(ToasterMessages.ERRMSG);
    })
    this.menuTrigger.closeMenu();
  }

  private hoverFeature() {
    const kGridContainer = Array.from(document.querySelectorAll(".k-grid .k-grid-container tr"));
    kGridContainer.forEach(row => {
      const index = row.getAttribute("data-kendo-grid-item-index");
      row.addEventListener("mouseenter", () => {
        this.showActionButton = true;
        this.showLockIcon = true;
        this.hoveredIndex = parseInt(index);
      });
    });
  }
/*
  public cellClickHandler(column, $dataItem) {
    if (column.title !== '' && column.title !== undefined) {
      LocalStorageHelper.setBackToButtonOnLocationBar({
        label: 'Back to Invoices',
        link: '/app/ledger/transactions',
        selectedTabQueryParam: null
      });
      this.router.navigate(['/app/ledger/invoice-detail'], {
        queryParams:
          { invoiceId: $dataItem.invoiceId, isEditable: $dataItem.lockedDate == null ? true : false, currStep: $dataItem.invoiceStatusId, selectedTabId: 1 },
      });
    }
  }
*/
  public getList() {
    this.loading = true;
    this.batchCodeService.getAllWithFilters(this.pageIndex, this.state.take, this.filter, this.sort).subscribe(results => {
      this.data = {
        data: results[0],
        total: results[1][0].recordCount
      };
      this.loading = false;
      this.activateHover();
    }, error => {
      this.loading = false;
    });
  }

  public addItem() {
    this.router.navigate(['app/ledger/invoice-add']);
  }
/*
  public getInvoiceRelatedEntity() {
    forkJoin(
      this.invoiceService.getEntity('invoicestatuscode'),
      this.invoiceService.getEntity('community'),
      this.invoiceService.getEntity('batches')
    ).subscribe(results => {
      this.invoiceStatuses = results[0];
      this.invoiceCommunities = results[1];
      this.batchCodes = results[2];
    })

  }
*/
  public getInvoiceStatusByStatusId(invoiceStatusId: number): string {
    let status = this.invoiceStatuses.filter(d => d.invoiceStatusId == invoiceStatusId);
    if (status.length > 0) {
      return status[0].status;
    }
    return '';
  }

  public communities = [];
  public users = [];
  public vendor: Vendor;
  
  //@ViewChild('vendorMultiSelect') vendorMultiSelect;
  @ViewChild('userMultiSelect') userMultiSelect;
  @ViewChild('communityMultiSelect') communityMultiSelect;
  public onMultiSelectControlChange(isSelected, selectedItems, controlName) {
    if (isSelected == true) {
      this.filterFormGroup.controls[controlName].setValue(selectedItems.id);
    }
  }

  
  displayFn(vendor?: Vendor): string {
    return vendor ?  vendor.legalName : '';
  }


  ngAfterViewInit() {
    this.invoiceStatuses.push('Deleted');
  }

  public dataStateChange(state: any): void {
    this.state = state;
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>) {
    //console.log('esto tiene model');
    //console.log(model);
    const dialogRef = this.dialog.open(BatchCodeDetailComponent, {
      width: '900px',
      height: '520px',
      data: {
        isUpdate: isUpdate,
        model: model
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined' && result.valid === true) {
        //this.loading = true;
        this.getList();
        //alert('asdad');
        //this.loading = true;
        /*
        this.bankAccounts = [];
        this.bankAccountService.getBankAccountsByCompany(this.companyId).subscribe(s=>{
          this.bankAccounts = s;
          if(this.bankAccounts && this.bankAccounts.length>0) {
            this.bankAccounts.forEach(acc => {
                acc.bankName = this.banks.find(banco => banco.bankId == acc.bankId).bankName;
            });
          }
          
          this.loading = false;
          //console.log('trae esto bankaccounts');
          //console.log(s);
        });
        */
      }
    });
    return dialogRef;
  }

  public cellClickHandler() {

  }

}

