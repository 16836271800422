import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { CompanyChartAccounts } from 'src/app/model/company-chart-accounts';
import { InvoiceAudit } from 'src/app/model/invoice-audit';
import { GlAccount, InvoiceDetail } from 'src/app/model/invoice-detail';
import { InvoiceNotes } from 'src/app/model/invoice-notes';
import { generateArrayOfYears, mockAuditTrailByYear, mockNotesByYear, mockQuestionByYear } from 'src/app/model/mock-data';
import { Vendor } from 'src/app/model/vendor';
import { CommunityService } from 'src/app/services/community.service';
import { CompanyChartAccountsService } from 'src/app/services/company-chart-accounts.service';
import { InvoiceAuditService } from 'src/app/services/invoice-audit.service';
import { InvoiceCommunityService } from 'src/app/services/invoice-community.service';
import { InvoiceCompanyBankAccountService } from 'src/app/services/invoice-company-bank-account.service';
import { InvoiceCompanyVendorService } from 'src/app/services/invoice-company-vendor.service';
import { InvoiceDistributionService } from 'src/app/services/invoice-distribution.service';
import { InvoiceNotesService } from 'src/app/services/invoice-notes.service';
import { InvoiceVendorService } from 'src/app/services/invoice-vendor.service';
import { InvoiceService } from 'src/app/services/invoice.service';
declare var $: any;
@Component({
    selector: 'app-invoice-detail',
    templateUrl: './invoice-details.component.html',
    styleUrls: ['./invoice-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InvoiceDetailComponent implements OnInit, OnDestroy, AfterViewInit {
    public invoiceDetails: InvoiceDetail;
    public invoiceTab = [
        {
            tabId: 1,
            value: 'invoiceDetails',
            name: 'Invoice Details',
            isActive: true
        },
        {
            tabId: 2,
            value: 'attachments',
            name: 'Attachments',
            isActive: true
        },
        {
            tabId: 3,
            value: 'auditTrail',
            name: 'Audit Trail',
            isActive: true
        },
        {
            tabId: 4,
            value: 'notes',
            name: 'Notes',
            isActive: true
        },
        {
            tabId: 5,
            value: 'questions',
            name: 'Questions',
            isActive: true
        }
    ];
    public histories = generateArrayOfYears();
    public selectedYear = 0;
    public vendors = [];
    public invoiceTypes = [];
    public communities = [];
    public banks = [];
    public paymentTypes = [];
    public glAccounts: CompanyChartAccounts[] = [];
    public pageMode = 0;
    public dataFromInvoiceUploadPage = null
    public isNotEditable = false;
    public invoiceId: number;
    @ViewChild('pdfViewer', { read: false }) pdfViewer: ElementRef;

    //for auto complete dropdowns
    public searchComplete = false;
    @ViewChild('vendorAutoComplete') vendorAutoComplete: ElementRef;
    @ViewChild('vendorSelect') vendorSelect: MatAutocomplete;
    public vendorSearch: Vendor[] = [];

    public isDisabled = true;
    public stepsToBeValidate = [];
    public invoiceVendorCommunityAccountingForm: FormGroup;
    public selectedTabId: number;
    public model: InvoiceDetail;
    public currStep: number;
    public openInvoiceDialog = false;
    public openDeleteConfirmationDialog = false;
    public openCancelConfirmationDialog = false;
    public showExpandIcon = false;
    public uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
    public uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
    public questionMenuAction = ['Answer']; //, 'Edit', 'Delete'];
    public selectedQuestionToAnswer: any;
    public pdf;
    public loading = false;
    private isLoading = false;
    public fileToBeDeleted: any;
    public hoveredInvoiceAttachmentId = 0;

    // for selected company id used to filter Gl Account and Bank Account Dropdown
    public selectedCompanyId: number;

    // for upload
    public uploadFile: Array<any> = [];
    public fileRestrict: FileRestrictions = {
        allowedExtensions: ['.pdf']
    };

    // for prev and next header button
    public onPrevButtonHovered = false;
    public onNextButtonHovered = false;

    // for vendor and community initial value dropdown
    public communityInitialValue = 0;
    public multiSelectCommunities = [];


    // for GL accounting section creation of multiple control
    public glFormArray: FormArray;
    public glAccountModel: GlAccount;
    public glAccountFormList: GlAccount[] = [];

    // GL account dropdown
    public multiSelectGlAccounts = [];
    public ngxMatSelectSearchControl: FormControl = new FormControl();
    public matSelectControl = new FormControl();
    public filterControlMulti: ReplaySubject<any> = new ReplaySubject();
    public _onDestroy = new Subject<void>();
    public glAccountInitialValue = 0;

    // for adding notes
    public noteLabel = new FormControl();
    public answerLabel = new FormControl();
    public questionLabel = new FormControl();

    // this will indicate if the step should be validated
    shouldValidateStep = (): boolean => {
        return this.stepsToBeValidate.indexOf(this.currStep) >= 0;
    }

    // TODO: Implement logic to check the validity of the current step
    isStepValid = (step: any): boolean => {
        return true;
    }
    steps = [
        { label: 'Upload', isValid: this.isStepValid, validate: this.shouldValidateStep },
        { label: 'Gl Coding', isValid: this.isStepValid, validate: this.shouldValidateStep },
        // { label: 'Quality Control', isValid: this.isStepValid, validate: this.shouldValidateStep },
        // { label: 'Approvals', isValid: this.isStepValid, validate: this.shouldValidateStep },
        { label: 'Payment', isValid: this.isStepValid, validate: this.shouldValidateStep }
    ];

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        public router: Router,
        public invoiceService: InvoiceService,
        public invoiceCommunityService: InvoiceCommunityService,
        public invoiceCompanyVendorService: InvoiceCompanyVendorService,
        public invoiceDistributionService: InvoiceDistributionService,
        public invoiceCompanyBankAccount: InvoiceCompanyBankAccountService,
        public companyChartAccounts: CompanyChartAccountsService,
        public communityService: CommunityService,
        public invoiceNotesService: InvoiceNotesService,
        public invoiceAuditService: InvoiceAuditService,
        private toastrService: ToastrService,
        private invoiceVendorService: InvoiceVendorService) {
        this.loading = true;
        this.model = new InvoiceDetail(fb);
        this.invoiceVendorCommunityAccountingForm = this.model.buildFormGroup();
        this.glFormArray = this.invoiceVendorCommunityAccountingForm.get('glAccnts') as FormArray;
        LocalStorageHelper.setBackToButtonOnLocationBar({
            label: 'Back to Invoices',
            link: '/app/ledger/transactions',
            selectedTabQueryParam: null
        });
        this.route.queryParams.subscribe(param => {
            forkJoin(
                this.invoiceService.getEntity('invoicetype'),
                this.invoiceService.getEntity('paymenttype')
            ).subscribe(results => {
                this.invoiceTypes = results[0];
                this.paymentTypes = results[1];
                this.loading = false;
                if (param.invoiceId !== undefined) {
                    this.invoiceId = param.invoiceId;
                    // edit mode
                    this.invoiceService.getById('InvoiceId', param.invoiceId).subscribe(invoice => {
                        this.invoiceDetails = invoice[0];
                        this.model.fromObject(invoice[0]);
                        this.loadCommunityDropdownDetails(this.model.companyId);
                        this.loadBankDropdrownDetails(this.model.companyId);
                        this.pageMode = 1;
                        this.loading = false;
                        this.loadPdf(this.invoiceDetails.invoiceId);
                        this.loadGLAccountDropdownDetails(this.model.companyId, 0);
                        // get the accounting information
                        this.invoiceDistributionService.getByFieldId(this.model.invoiceId).subscribe(invDistribution => {
                            const items: any = invDistribution;
                            if (items.length > 0) {
                                this.glAccountFormList.splice(0, 1);
                                this.glFormArray.removeAt(0);
                            }
                            items.forEach(element => {
                                element.isDeleted = false;
                                this.addGlAccount(element);
                            });
                        }, error => {
                            this.loading = false;
                        });

                        this.loadQuestions(this.invoiceDetails.invoiceId);

                    });
                } else {
                    // add mode
                    const uploadPageData = this.invoiceService.getDataFromInvoiceUpload();
                    this.dataFromInvoiceUploadPage = uploadPageData.invoiceUploadData;
                    this.communityInitialValue = this.dataFromInvoiceUploadPage.associationId;
                    this.uploadFile = uploadPageData.filesToUpload;
                    this.loadCommunityDropdownDetails(this.dataFromInvoiceUploadPage.associationId);
                    this.loadBankDropdrownDetails(this.dataFromInvoiceUploadPage.associationId);
                    this.invoiceVendorCommunityAccountingForm.controls['companyId'].setValue(this.dataFromInvoiceUploadPage.associationId);
                    this.loadGLAccountDropdownDetails(this.dataFromInvoiceUploadPage.associationId, 0);
                    this.pageMode = 0;
                }

            }, error => {
                this.loading = false;
                this.toastrService.error('There is an error on the page');
            });
            if (param.currStep == undefined) {
                this.currStep = 0;
                this.selectedTabId = 1;
            } else {
                this.currStep = parseInt(param.currStep);
                this.selectedTabId = parseInt(param.selectedTabId);
                if (this.currStep != 1) {
                    this.model.disableControls();
                }
            }

            if (this.currStep == 0) {
                this.stepsToBeValidate.push(0);
            }

            this.isNotEditable = param.isEditable !== undefined && param.isEditable == 'false';
            if (this.isNotEditable) {
                this.model.disableControls();

            }
        });

    }

    ngOnInit() {
        this.addGlAccount();
    }

    ngOnDestroy(): void {
        kendo.destroy(this.pdfViewer.nativeElement);
        LocalStorageHelper.setBackToButtonOnLocationBar('null');
    }

    public loadInvoiceNotes(invoiceId: number) {
        this.loading = true;
        this.invoiceNotesService.getByInvoiceIdAndYear(invoiceId, 0).subscribe(d => {
            this.notesByYear = d;
            this.loading = false;
        }, error => {
            this.loading = false;
            this.toastrService.error(error);
        })
    }

    public loadInvoiceAudit(invoiceId: number, auditYear: number) {
        this.selectedYear = auditYear;
        this.loading = true;
        this.invoiceAuditService.getByInvoiceIdYear(invoiceId, this.selectedYear).subscribe(d => {
            this.auditTrailByYear = d;
            this.loading = false;
        }, error => {
            this.loading = false;
            this.toastrService.error(error);
        })
    }

    public loadPdf(invoiceId: number) {
        this.loading = true;
        this.invoiceService.getInvoiceById(invoiceId).subscribe((fileData: any[]) => {
            this.loading = false;
            fileData.forEach(item => {
                this.uploadFile.push(
                    {
                        invoiceAttachmentId: item.invoiceAttachmentId,
                        name: '',
                        rawFile: {
                            lastModifiedDate: item.creationDate
                        },
                        description: item.description,
                        attachmentTypeId: item.attachmentTypeId
                    }
                );
            })

            if (fileData.length > 0) {
                $("#pdfViewer").kendoPDFViewer({
                    pdfjsProcessing: {
                        file: {
                            data: fileData[0].fileByte
                        }
                    },
                    height: 800,
                    toolbar: {
                        items: [
                            "pager", "spacer", "zoom", "toggleSelection", "spacer", "search", "download", "print"
                        ]
                    },
                    error: function (e) {
                        this.loading = false;
                        e.preventDefault();
                    }
                });
            }

        }, error => this.loading = false);
    }

    // when header tab change the selected tab
    public tabChange(tabId: number) {
        this.selectedTabId = tabId;
        switch (tabId) {
            case 3: {
                this.loadInvoiceAudit(this.invoiceId, this.selectedYear);
            }
            case 4 : {
                this.loadInvoiceNotes(this.invoiceId);
            }
        }
    }

    public currentStepChange() {
        this.stepsToBeValidate.push(this.currStep);
        if (this.currStep !== 1) {
            this.model.disableControls();
            this.enableDisableGlAccountControls(false)
        } else {
            this.enableDisableGlAccountControls(true)
            this.model.enableControls();
        }
    }

    public onActionMenuEvent(item) {
        switch (item.menu.toLocaleLowerCase()) {
            case "answer": {
                this.openAnswerQuestionDialog = true;
                this.selectedQuestionToAnswer = item.question;
                break;
            }
            case "edit": {
                break;
            }
            case "delete": {
                break;
            }
        }
    }

    //#region Attachments
    public currentSelectedFile: any = {};
    public onSelectEvent(item) {
        let fileTypeNotSupported = false;
        item.files.forEach(file => {
            if (file.extension != '.pdf') {
                alert('file type not allowed');
                fileTypeNotSupported = true;
                this.uploadFile = this.uploadFile.filter(item => item.uid !== file.uid);
                return;
            }
        })
        if (!fileTypeNotSupported) {
            this.openInvoiceDialog = true;
            this.currentSelectedFile = item.files;
        }
    }

    public onDialogStateChange(isOpened) {
        this.openInvoiceDialog = isOpened;
    }

    public processFileToUpload(action: string) {
        this.currentSelectedFile.forEach(file => {
            if (action == 'add') {
                file.uploadTypeId = 1;
            } else if (action == 'replace') {
                this.uploadFile.map(function (item: any) {
                    item.uploadTypeId = 2;
                });
                file.uploadTypeId = 1;
            } else if (action == 'supporting') {
                file.uploadTypeId = 2;
            }
            this.uploadFile.push(file)
        });
        this.openInvoiceDialog = false;
    }

    public deleteUploadedFile(fileToBeDeleted) {
        this.fileToBeDeleted = fileToBeDeleted
        this.openDeleteConfirmationDialog = true;
        this.uploadFile = this.uploadFile.filter(item => item.uid !== fileToBeDeleted.uid);
    }

    public expand() {
        throw new Error('Method not implemented.');
    }

    public onDeleteConfirmationDialogStateChange(e) {
        this.openDeleteConfirmationDialog = false;
    }

    public unlinkAttachedFile() {
        if (this.fileToBeDeleted.invoiceAttachmentId !== undefined) {
            this.invoiceService.deleteAttachment(this.fileToBeDeleted.invoiceAttachmentId).subscribe(result => {
                this.openDeleteConfirmationDialog = false;
            });
        } else {
            this.openDeleteConfirmationDialog = false;
        }

    }

    public doNothingOnAttachedFile() {
        this.openDeleteConfirmationDialog = false;
    }


    //# Attachement endregion

    //#region Add Note Dialog Properties
    public openNotesDialog = false;
    public onNoteDialogStateChange(isOpened) {
        this.openNotesDialog = isOpened;
    }

    public onAddNote() {
        this.openNotesDialog = true;
    }

    public saveNote() {
        this.isLoading = true;
        let invoiceModel = this.model.toDto();
        let invoiceNotesToSave: any = {
            invoiceId: invoiceModel.invoiceId,
            label: this.noteLabel.value,
            statusNoteId: 1,
            noteTypeId: 1
        }
        this.invoiceNotesService.add(invoiceNotesToSave).subscribe(d => {
            this.openNotesDialog = false;
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.toastrService.error(error);
        })
    }

    public cancelNote() {
        this.openNotesDialog = false;
    }
    //#endregion

    //#region  Add Question Dialog Properties
    private openQuestionDialog = false;
    public onQuestionDialogStateChange(isOpened) {
        this.openQuestionDialog = isOpened;
    }
    public onAddQuestion() {
        this.openQuestionDialog = true;
    }

    public saveQuestion() {
        this.isLoading = true;
        let invoiceModel = this.model.toDto();
        let invoiceNotesToSave: any = {
            invoiceId: invoiceModel.invoiceId,
            label: this.questionLabel.value,
            statusNoteId: 1,
            noteTypeId: 2
        }
        this.invoiceNotesService.add(invoiceNotesToSave).subscribe(d => {
            this.openQuestionDialog = false;
            this.loadQuestions(this.invoiceDetails.invoiceId);
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.toastrService.error(error);
        })
    }

    public cancelQuestion() {
        this.openQuestionDialog = false;
        console.log('cancel note');
    }
    //#endregion

    //#region Answer Question Dialog Properties
    public openAnswerQuestionDialog = false;
    public onAnswerQuestionDialogStateChange(isOpened) {
        this.openAnswerQuestionDialog = isOpened;
    }

    public saveAnswerQuestion() {
        this.isLoading = true;
        let invoiceModel = this.model.toDto();
        let invoiceNotesToSave: any = {
            invoiceId: invoiceModel.invoiceId,
            label: this.answerLabel.value,
            statusNoteId: 1,
            noteTypeId: 3,
            noteParentId: this.selectedQuestionToAnswer.noteId
        }
        this.invoiceNotesService.add(invoiceNotesToSave).subscribe(d => {
            this.openAnswerQuestionDialog = false;
            this.loadQuestions(this.invoiceDetails.invoiceId);
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.toastrService.error(error);
        })
    }

    public cancelAnswerQuestion() {
        this.openAnswerQuestionDialog = false;
        console.log('cancel note');
    }
    //#endregion

    public onMouseEnter(invoiceAttachmentId) {
        this.showExpandIcon = true;
        this.hoveredInvoiceAttachmentId = invoiceAttachmentId;
    }

    public onMouseLeave() {
        this.showExpandIcon = false;
        this.hoveredInvoiceAttachmentId = 0;
    }

    public cancel() {
        this.openCancelConfirmationDialog = true;
    }

    public onCancelConfirmationDialogStateChange() {
        this.openCancelConfirmationDialog = false;
    }

    public leavePage() {
        this.openCancelConfirmationDialog = false;
        this.router.navigate(['app/ledger/transactions']);
    }

    public stayOnPage() {
        this.openCancelConfirmationDialog = false;
    }


    /**
     * For the notes tab
     */
    public notesByYear: InvoiceNotes[] = [];

    public questionsByYear = mockQuestionByYear();

    public auditTrailByYear: InvoiceAudit[] = [];

    get glAccountFormGroup() {
        return this.invoiceVendorCommunityAccountingForm.get('glAccnts') as FormArray;
    }

    public addGlAccount(entity: GlAccount = null) {
        this.glFormArray.push(this.createGlAccount(entity));
        (<FormArray>this.invoiceVendorCommunityAccountingForm.get('glAccnts')).controls.forEach(function (item: any) {
            item.controls['YTDBudget'].disable();
            item.controls['annualBudget'].disable();
        })

        if (this.isNotEditable || this.currStep !== 1) {
            (<FormArray>this.invoiceVendorCommunityAccountingForm.get('glAccnts')).controls.forEach(ctrl => {
                ctrl.disable();
            });
        }
    }

    public createGlAccount(entity: GlAccount = null): FormGroup {
        this.glAccountModel = new GlAccount(this.fb);
        if (this.isNotEditable) {
            this.glAccountModel.disableControls();
        }
        const formGroup = this.glAccountModel.buildFormGroup();
        if (entity) {
            this.glAccountModel.fromObject(entity);
        }
        this.glAccountInitialValue = this.glAccountModel.companyChartOfAccountsId;
        this.glAccountFormList.push(this.glAccountModel);
        return formGroup;
    }

    public removeGlAccount(index: number): void {
        this.glAccountFormList[index - 1].isDeleted = true;
        this.glFormArray.removeAt(index);
    }

    public enableDisableGlAccountControls(isEnabled) {
        this.glAccountFormList.forEach(item => {
            if (isEnabled == true) {
                item.enableControls()
            } else {
                item.disableControls();
            }
        });
    }

    public onCommunityMultiSelectControlChange(isSelected, selectedItems) {
        this.loading = true;
        if (isSelected == true) {
            this.selectedCompanyId = selectedItems.id;
            this.invoiceVendorCommunityAccountingForm.controls['companyId'].setValue(selectedItems.id);
            this.loadBankDropdrownDetails(selectedItems.id);
        }
    }

    public onGlAccountMultiSelectControlChange(isSelected, selectedItems, formIndex: number) {
        if (isSelected == true) {
            this.glAccountFormList[formIndex - 1].companyChartOfAccountsId = selectedItems.vendorId;
        }
    }

    // for update
    public readyForPayment() {
        this.glFormArray.removeAt(0);
        if (this.invoiceVendorCommunityAccountingForm.valid) {
            let modelToSave = this.model.toDto();
            modelToSave.invoiceStatusId = 3;
            this.invoiceService.update(modelToSave).subscribe(results => {
                this.processGlAccounts(modelToSave.invoiceId);
                this.router.navigate(['/app/ledger/transactions/']);
            }, error => {
                console.log(error);
            })
        } else {
            this.toastrService.error('Please fill the required fields', 'Error');
            this.loading = false;
        }
        
    }

    // for add
    public saveInvoice() {
        this.loading = true;
        let itemModel = this.model.toDto();
        let createdInvoiceId = '';

        // TODO: this should be updated based on the selected user on Invoice Upload Page
        if (this.dataFromInvoiceUploadPage.checkOut == true) {
            const impersonatedFlag = LocalStorageHelper.getImpersonatedFlag();
            const loggedUser = LocalStorageHelper.getLoggedUserInfo(impersonatedFlag);
            itemModel.lockedByUserId = loggedUser.userId;
            itemModel.lockedDate = new Date();
        }

        itemModel.uploadTypeId = this.dataFromInvoiceUploadPage.uploadTypeId;
        itemModel.batchId = this.dataFromInvoiceUploadPage.batchId;
        this.invoiceService.add(itemModel).subscribe(result => {
            this.loading = false;
            this.processGlAccounts(result.id);
            createdInvoiceId = result.id.toString();
        }, () => {
            this.loading = false;
            this.toastrService.error('There is an error when adding data on Invoice');
        })
    }


    public uploadAttachment() {
        this.loading = true;
        this.uploadFile.forEach(item => {
            if (item.invoiceAttachmentId == undefined) {
                this.invoiceService.uploadAttachmentFile(this.invoiceVendorCommunityAccountingForm.controls['invoiceNum'].value, this.model.invoiceId.toString(),
                    item.uploadTypeId.toString(), item.rawFile)
                    .subscribe(result => {
                        this.loading = false;
                    }, () => {
                        this.loading = false;
                        this.toastrService.error('There is an error while uploading the file');
                    });
            }
        })
    }

    private processGlAccounts(invoiceId) {
        let glAccountDto = [];
        if (this.pageMode == 0) {
            this.glAccountFormList.forEach(formData => {
                let formDataDto = formData.toDto();
                formDataDto.companyChartOfAccountsId = formData.companyChartOfAccountsId;
                formDataDto.invoiceId = invoiceId
                glAccountDto.push(formDataDto);
            });
            this.invoiceDistributionService.addBulk(glAccountDto).subscribe(result => {
            }, () => {
                this.loading = false;
                this.toastrService.error('There is an error when adding data on Invoice Distribution');
            });
            this.router.navigate(['/app/ledger/transactions']);
        } else {
            this.glAccountFormList.forEach(formData => {
                let formDataDto = formData.toDto();
                formDataDto.companyChartOfAccountsId = formData.companyChartOfAccountsId;
                formDataDto.invoiceId = invoiceId;
                if (formData.invoiceDistributionId == 0) {
                    if (formDataDto.isDeleted == false) {
                        // add
                        this.invoiceDistributionService.add(formDataDto).subscribe(result => {
                        }, error => {
                            this.toastrService.error('There is an error adding account information');
                        })
                    }
                } else {
                    if (formDataDto.isDeleted == false) {
                        this.invoiceDistributionService.update(formDataDto).subscribe(result => {
                        }, error => {
                            this.toastrService.error('There is an error updating account information');
                        })
                    } else {
                        //delete
                        this.invoiceDistributionService.delete(formDataDto.invoiceDistributionId).subscribe(result => {
                        }, () => {
                            this.toastrService.error('There is an error deleting account information');
                        });
                    }
                }
            });
            this.router.navigate(['/app/ledger/transactions']);
        }

    }

    private formatDate(dateToFormat) {
        let newDate = new Date(dateToFormat);
        return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getUTCFullYear()}`;
    }

    private loadCommunityDropdownDetails(communityInitialValue) {
        this.loading = true;
        this.invoiceCommunityService.getById('PropertyManagementCompanyId', LocalStorageHelper.getManagementCompanyFromBreadcrumb())
            .pipe(map(d => {
                this.communities = d;
                return d.map(element => {
                    return {
                        id: element.companyId,
                        name: element.name
                    }
                })
            })
            ).subscribe(communities => {
                this.multiSelectCommunities = communities;
                this.communityInitialValue = communityInitialValue;
                this.selectedCompanyId = communityInitialValue;
            }, () => this.loading = false);
    }

    private loadBankDropdrownDetails(companyId: number) {
        this.invoiceCompanyBankAccount.getById('CompanyId', companyId).subscribe(result => {
            this.banks = result;
            this.loading = false;
        }, () => this.loading = false)
    }

    private loadGLAccountDropdownDetails(companyId: number, glAccountsInitialValue) {
        this.glAccountInitialValue = glAccountsInitialValue
        this.ngxMatSelectSearchControl.valueChanges.pipe(
            tap(() => this.isLoading = true),
            takeUntil(this._onDestroy),
            debounceTime(200),
            switchMap((e: any) => {
                this.loading = true;
                if (e && e.length > 2) {
                    return this.applyFilter(e || '');
                } else {
                    return new Observable<any[]>();
                }
            }),
            takeUntil(this._onDestroy)
        ).subscribe(item => {
            this.loading = false;
            this.filterControlMulti.next(item.list);
        }, () => this.loading = false)
        this.setInitialValue();
    }

    public onPrevButtonHoverEvent() {
        this.onPrevButtonHovered = true;
    }

    public onPrevButtonHoverLeaveEvent() {
        this.onPrevButtonHovered = false;
    }

    public loadPreviousInvoice() {

    }

    public loadNextInvoice() {

    }

    setInitialValue() {
        if (this.glAccountInitialValue != null) {
            this.filterControlMulti.next(this.multiSelectGlAccounts.slice());
            this.matSelectControl.setValue(this.glAccountInitialValue);
        }
    }

    ngAfterViewInit() {
        this.isLoading = false;
        if (this.vendorAutoComplete) {
            fromEvent(this.vendorAutoComplete.nativeElement, 'keyup')
                .pipe(
                    debounceTime(200),
                    tap(() => this.isLoading = true),
                    distinctUntilChanged(),
                    switchMap((e: any) => {
                        this.searchComplete = false;
                        let val = '';
                        if (e) {
                            val = e.target.value;
                        }
                        if (val && val.length > 2) {
                            return this.applyFilter(val || '');
                        } else {
                            this.isLoading = false;
                            this.vendorSearch = [];
                            return new Observable<Vendor[]>();
                        }
                    })
                ).subscribe(result => {
                    this.vendorSearch = result.list;
                    this.isLoading = false;
                    this.searchComplete = true;
                });
        }
    }

    clearFilter() {
        this.vendorSearch = [];
        this.vendors = [];
    }

    // vendor auto complete dropdown
    applyFilter(filterValue: string): Observable<any> {
        return this.invoiceVendorService.getAllByFilter(LocalStorageHelper.getManagementCompanyFromBreadcrumb(), filterValue);
    }

    displayFn(vendor?: Vendor): string {
        return vendor ? vendor.legalName : '';
    }

    onVendorSelectionChange(vendr: Vendor) {
        this.loading = true;
        this.vendorAutoComplete.nativeElement.value = vendr.legalName;
        this.vendors.push(vendr);
        this.invoiceVendorCommunityAccountingForm.controls['companyVendorId'].setValue(vendr.vendorId);
        this.loading = false;
    }

    public loadQuestions(invoiceId: number) {
        this.loading = true;
        this.questionsByYear = this.invoiceNotesService.getQuestionsById(invoiceId);
        this.loading = false;
    }
}