import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GridComponent, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { AccountPayableService } from 'src/app/services/account-payables.service';
import { forkJoin } from 'rxjs';
import { MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { mockFundStatus } from 'src/app/model/mock-data';
import { InvoicePaymentService } from 'src/app/services/invoice-payment.service';
import { IPaymentDetails, IAdvanceFilter } from 'src/app/model/invoice-payment';
import { CsvDataService } from 'src/app/services/csv-data.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { TardisFileService } from 'src/app/services/pensieve.service';

@Component({
  selector: 'app-account-payable-batch-list',
  templateUrl: './account-payable-batch-list.component.html',
  styleUrls: ['./account-payable-batch-list.component.scss'],
  animations: [
    trigger('searchOpenClose', [
      state('open', style({
        'width': '245px',
        'border': '1px solid lightgray',
        'padding-left': '5px'
      })),
      state('closed', style({
        'width': '0px',
        'border': 'none'
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ])
    ]),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class AccountPayableBatchListComponent extends KendoGridAbstractClass implements OnInit {
  public searchString = '';
  public selectableSettings = {};
  public userRole = 1; // get the user role from different datasource 
  public showSearch = true;
  public showActionButton = false;
  public showLockIcon = false
  public hoveredIndex = 0;
  public payableBatchFilterForm: FormGroup;
  public communities = [];
  public openCancelBatchDialog = false;
  public openApproveBatchDialog = false;
  public openBatchApprovalEdit = false;
  public payableBatchToBeCancel: any = null;
  public payableBatchToApprove: any = null;
  public showHeaderButtons = false;
  public selectedPayables: any = {
    selectedRows: [],
    deselectedRows: []
  };
  public selectedInvoiceBatch = [];
  public exportCSVFileName = '';
  public dummyRevoData: any[] = [];
  // TODO: Reymond change this from mock data to actual data
  public fundTypes = mockFundStatus();


  // for filters
  public multiSelectCommunities = [];
  public selectedAdvanceFilters: IAdvanceFilter[] = [];

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild(GridComponent) public grid: GridComponent;

  constructor(private accountPayableService: AccountPayableService, 
    private invoicePaymentService: InvoicePaymentService, 
    private invoiceService: InvoiceService,
    private pensieveService: TardisFileService,
    private router: Router) {
    super();
    this.exportFileName = 'payment-list';
    this.exportCSVFileName = kendo.toString(new Date, "d") + ' - RevoPayment.csv';
    this.showAdvanceFilter = false;
    this.pageIndex = 1;
    this.state = {
      skip: 1, // current page number
      take: 10, // items per page
    };
    this.pageSizes = [5, 10, 25, 50];
    this.data = {
      data: [],
      total: 0
    };
    this.filter = {};
    this.getList();
    this.payableBatchFilterForm = new FormGroup(
      {
        bankAccount: new FormControl(),
        batchDate: new FormControl(),
        amountStatus: new FormControl(),
      }
    );
  }
  ngOnInit() {
    this.setSelectableSettings();
    this.getDropdownData();
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageIndex = (event.skip / event.take) + 1;
    this.state.skip = event.skip;
    this.state.take = event.take;
    this.getList();
  }

  public onSortChange(e) {
    this.sort = `${e[0].dir}|${e[0].field}`;
    this.getList();
  }

  public applyFilter() {
    this.filter = {
      headerSearch: this.searchString,
      batchDate: this.payableBatchFilterForm.controls['batchDate'].value !== null ? this.payableBatchFilterForm.controls['batchDate'].value : '',
      bankAccount: this.payableBatchFilterForm.controls['bankAccount'].value !== null ? this.payableBatchFilterForm.controls['bankAccount'].value : '',
      amountStatus: this.payableBatchFilterForm.controls['amountStatus'].value !== null ? this.payableBatchFilterForm.controls['amountStatus'].value : '',
    };
    this.getList();
  }

  public resetFilter() {
    this.payableBatchFilterForm.controls['bankAccount'].setValue('');
    this.payableBatchFilterForm.controls['batchDate'].setValue('');
    this.selectedAdvanceFilters = [];
    this.selectedInvoiceBatch
    this.filter = {};
    this.getList();
  }

  public setSelectableSettings(): void {
    switch (this.userRole) {
      case 1: {
        this.selectableSettings = {
          checkboxOnly: true
        };
        break;
      }
      case 2: {
        this.selectableSettings = null;
        break;
      }
      case 3: {
        this.selectableSettings = null;
        break;
      }
    }
  }

  public onSelectionChange(dataItem) {
    dataItem.selectedRows.forEach(item => {
      this.selectedPayables.selectedRows.push(item.dataItem);
    });

    dataItem.deselectedRows.forEach(item => {
      this.selectedPayables.selectedRows = this.selectedPayables.selectedRows.filter(payable => payable.batchId !== item.dataItem.batchId);
      this.selectedPayables.deselectedRows.push(item.dataItem);
    });

    if (this.selectedPayables.selectedRows.length > 0) {
      this.showHeaderButtons = true;
    } else {
      this.showHeaderButtons = false;
    }
  }

  public onDetailExpand(row) {
  }

  private onFilterClick() {
    this.showAdvanceFilter = !this.showAdvanceFilter;
  }

  private searchItem() {
    this.showSearch = !this.showSearch;
  }

  private performSearch() {
    console.log('implement search')
  }

  public exportCVS() {
    this.grid.saveAsExcel();
  }

  public onBatchApprovalEditStateChange(isOpen) {
    this.openBatchApprovalEdit = isOpen;
  }

  // for approval API call
  public singleBatchApproveDialog() {
    this.openApproveBatchDialog = false;
    if (this.payableBatchToApprove == null) {
      this.selectedPayables.selectedRows.forEach(item => {
        item.isApproved = true;
        setTimeout(() => {
          this.invoicePaymentService.update(item).subscribe();
        }, 100);
        this.selectedInvoiceBatch = [];
      })
    } else {
      this.payableBatchToApprove.isApproved = true;
      this.invoicePaymentService.update(this.payableBatchToApprove).subscribe(result => {
        this.payableBatchToApprove = null;
      });
    }
  }

  // for cancel batch API call
  public singleBatchDialogCancel() {
    this.openCancelBatchDialog = false;
    if (this.payableBatchToBeCancel == null) {
      this.selectedPayables.deselectedRows.forEach(item => {
        setTimeout(() => {
          this.invoicePaymentService.delete(item.invoicePaymentId).subscribe(result => { })
        }, 100);
        this.selectedInvoiceBatch = [];
      })
    } else {
      console.log(this.payableBatchToBeCancel);
      this.invoicePaymentService.delete(this.payableBatchToBeCancel.invoicePaymentId).subscribe(result => {
        this.payableBatchToBeCancel = null;
        this.getList();
      });
    }
  }

  // for closing the confirmation dialog 
  public dontApproveBatchDialog(payable) {
    this.openCancelBatchDialog = false;
  }

  // for closing the confirmation dialog 
  public dontCancelBatch() {
    this.openCancelBatchDialog = false;
    this.menuTrigger.closeMenu();
  }

  // open confirmation dialog
  public openBatchApprove(payable) {
    if (payable !== undefined) {
      this.menuTrigger.closeMenu();
      this.payableBatchToApprove = payable;
    }
    this.openApproveBatchDialog = true;
  }

  // open confirmation dialog
  public openBatchCancel(payable) {
    if (payable !== undefined) {
      this.menuTrigger.closeMenu();
      this.payableBatchToBeCancel = payable;
    }
    this.openCancelBatchDialog = true;
  }

  public cancelBulkOperation() {
    // clear the selection
    this.selectedInvoiceBatch = [];
    this.showHeaderButtons = false;
  }

  public bulkCancelApproval() {
    // TODO: Reymond update invoice batchId to null
    console.log('cancel approval using the header button', this.selectedInvoiceBatch);
  }

  public bulkApproval() {
    // TODO: reymond update the invoice set to approved
    console.log('approve using the header button', this.selectedInvoiceBatch);
  }


  public onCancelBatchTemplate() {
    this.openCancelBatchDialog = false;
  }

  public onApproveBatchTemplate() {
    this.openApproveBatchDialog = false;
  }

  public dontApproveBatch() {
    // TODO: Do something to the payableBatchToBeCancel
    // this.payableBatchToBeCancel
    this.openApproveBatchDialog = false;
    this.menuTrigger.closeMenu();
  }

  public onFundSelectionChange(selected) {
    this.payableBatchFilterForm.controls['amountStatus'].setValue(selected.value);
  }

  public onAdvFundSelectionChange(selected) {
    this.selectedAdvanceFilters.push({
      label: 'Batch: ' + selected.source.triggerValue,
      value: selected.value,
      type: 'fund'
    });
  }

  public onAdvFilterBankAccontFilterLeave() {
    // TODO: Reymond Know the real value for this advance filter
    this.selectedAdvanceFilters.push({
      label: 'Bank Account: ' + this.payableBatchFilterForm.controls['bankAccount'].value,
      value: 0,
      type: 'fund'
    });
  }

  public onAdvFilterDateChange($event) {
    this.selectedAdvanceFilters.push({
      label: 'Batch Date: ' + new Date(this.payableBatchFilterForm.controls['batchDate'].value),
      value: this.payableBatchFilterForm.controls['batchDate'].value,
      type: 'batch date'
    });
  }

  public exportToPDF() {
    this.grid.saveAsPDF();
  }

  public getDropdownData() {
    this.loading = true;
    this.accountPayableService.getEntity('community')
      .pipe(map(d => {
        this.communities = d;
        return d.map(element => {
          return {
            id: element.companyId,
            name: element.name
          }
        })
      })
      ).subscribe(communities => {
        this.multiSelectCommunities = communities;
        this.loading = false;
      });
  }

  public onCommunityMultiSelectControlChange(isSelected, selectedItems) {
    if (isSelected == true) {
      // this.selectedCommunities.push(selectedItems.id);
      this.selectedAdvanceFilters.push({
        label: 'Community:' + selectedItems.name,
        value: selectedItems.id,
        type: 'community'
      });
    } else {
      // this.selectedCommunities = this.selectedCommunities.filter(item => item !== selectedItems.id);
      this.selectedAdvanceFilters = this.selectedAdvanceFilters.filter(item => item.value !== selectedItems.id);
    }
  }

  private activateHover() {
    setTimeout(() => {
      this.hoverFeature();
    }, 100);
  }

  private hoverFeature() {
    const kGridContainer = Array.from(document.querySelectorAll(".k-grid .k-grid-container tr"));
    kGridContainer.forEach(row => {
      const index = row.getAttribute("data-kendo-grid-item-index");
      row.addEventListener("mouseenter", () => {
        this.showActionButton = true;
        this.hoveredIndex = parseInt(index);
      });
    });
  }

  public rowCallback(context: RowClassArgs) {
    const isRed = context.dataItem.amount > context.dataItem.balance;
    return {
      red: isRed,
      notRed: !isRed
    }
  }

  public edit(community) {
    this.menuTrigger.closeMenu();
    this.openBatchApprovalEdit = true;
    this.accountPayableService.setPayableEdit(this.data.data, community);
    this.router.navigate(['/app/payables/payable-edit']);
  }

  public processedInvoicePaymentDetails: IPaymentDetails[] = [];
  public getList() {
    this.loading = true;
    this.filter = {
      invoiceStatusId: 3
    }
    this.invoicePaymentService.getAllWithFilters(this.pageIndex, this.state.take, this.filter, this.sort).subscribe(results => {      // nested vendor details
      this.processedInvoicePaymentDetails = [];
      results[0].forEach(item => {
        let invoicePaymentDetails: IPaymentDetails = {
          invoicePaymentId: item.invoicePaymentId,
          invoiceId: item.invoiceId,
          paymentId: item.paymentId,
          batchId: item.batchId,
          isApproved: item.isApproved,
          companyId: item.companyId,
          communityName: item.communityName,
          vendorId: item.vendorId,
          vendorName: item.vendorName,
          invoiceStatusId: item.invoiceStatusId,
          status: item.status,
          bankId: item.bankId,
          bankAccountNum: item.bankAccountNum,
          invoiceNum: item.invoiceNum,
          amount: item.amount,
          batchTotal: item.batchTotal,
          balance:0,
          vendors: []
        };
        invoicePaymentDetails.vendors = this.invoicePaymentService.loadVendors(results[0], item);
        // invoicePaymentDetails.vendors.forEach(vendor => {
        //   invoicePaymentDetails.batchTotal = invoicePaymentDetails.batchTotal + vendor.totalAmount;
        // })
        this.processedInvoicePaymentDetails.push(invoicePaymentDetails);
      })

      this.data = {
        data: this.processedInvoicePaymentDetails,
        total: results[1][0].recordCount
      };
      this.loading = false;
      this.activateHover();
    });
  }

  public exportRevo() {
    //console.log(this.selectedPayables.selectedRows);
    let ids = '';

    //call financials service 
    this.selectedPayables.selectedRows.forEach(element => {
      ids += element.batchId + ",";
    });
    if(ids && ids.length>1) {
      ids = ids.substr(0, ids.length - 1);
    }

    try{
      this.loading=true;
      //console.log(this.dummyRevoData);
      this.invoiceService.getRevoObject(ids).subscribe(d => {
        let revoData = d;
        //console.log(d);
        //debugger;
        CsvDataService.exportToCsv(this.exportCSVFileName, revoData.report);
      });
    }
    catch {      
      this.invoiceService.getRevoFile(ids).subscribe(f => {
        //debugger;
        //console.log(f);
        this.invoiceService.getRevoFile(ids).subscribe(f => {
          //debugger;
          //console.log(f);
          this.pensieveService.getFile(f.fileId).subscribe(p=>{
            //console.log(p);
            const blob = new Blob([new Uint8Array(p)], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
              navigator.msSaveBlob(blob, this.exportCSVFileName);
            } else {
              const link = document.createElement('a');
              if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', this.exportCSVFileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          });
        });
      });
    } 
    finally{
      this.loading=false;
    }
  }
}