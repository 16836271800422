export function mockCommunity() {
    return [
        {
            id: 1,
            name: 'Communtity 1'
        },
        {
            id: 2,
            name: 'Communtity 2'
        },
        {
            id: 3,
            name: 'Communtity 3 '
        }
    ];
}

export function mockInvoiceType() {
   return [
        {
            id: 1,
            name: 'Type 1'
        },
        {
            id: 2,
            name: 'Type 2'
        }
    ];
}

export function mockBankAccounts() {
    return [
        {
            id: 1,
            value: 101010101010101
        },
        {
            id: 2,
            value: 2020202020202020
        }
    ];
}

export function mockPaymentType() {
    return [
        {
            id: 1,
            name: 'Type 1'
        },
        {
            id: 2,
            name: 'Type 1'
        }
    ];
}

export function mockGlAccounts() {
    return [
        {
            id: 3,
            name: 'GL Account 1'
        },
        {
            id: 3,
            name: 'GL Account 1'
        }
    ];
}

export function mockVendors() {
    return [
        {
            id: 1,
            name: 'Vendor 1'
        },
        {
            id: 2,
            name: 'Vendor 2'
        }
    ];
}

export function mockBatchCode() {
    return [
        {
            id: 1,
            name: 'Batch Code 1'
        },
        {
            id: 2,
            name: 'Batch Code 2'
        }
    ];
}

export function mockRecurringInvoices() {
    return [
        {
            invoiceNumber:'321654987',
            invoiceDate: '07/09/2020',
            communityId: '1',
            communityName: 'Community 1',
            vendorId: '1',
            vendorName: 'Vendor 1',
            amount: 789
        },
        {
            invoiceNumber:'789654321',
            invoiceDate: '07/09/2020',
            communityId: '1',
            communityName: 'Community 1',
            vendorId: '1',
            vendorName: 'Vendor 1',
            amount: 824
        },
        {
            invoiceNumber:'774785852',
            invoiceDate: '07/09/2020',
            communityId: '1',
            communityName: 'Community 2',
            vendorId: '1',
            vendorName: 'Vendor 1',
            amount: 824
        },
        {
            invoiceNumber:'778855996',
            invoiceDate: '07/09/2020',
            communityId: '1',
            communityName: 'Community 2',
            vendorId: '1',
            vendorName: 'Vendor 2',
            amount: 824
        }
    ]
}

export function mockBatchApprovalListData() {
    return [
        {
            batchId: 4,
            companyId: 1,
            communityName: 'Rock Creerk',
            bankAccountNum: '90001',
            batchDate: new Date(),
            batchStatusId: 1,
            status: 'Pending Payment',
            amount: 999999,
            balance: 999999
        },
        {
            batchId: 5,
            companyId: 1,
            communityName: 'Greenglades Condo 1',
            bankAccountNum: '90002',
            batchDate: new Date(),
            batchStatusId: 1,
            status: 'Pending Payment',
            amount: 999999,
            balance: 999999
        },
        {
            batchId: 1003,
            companyId: 1,
            communityName: 'Homes at Forest Lake',
            bankAccountNum: '90001',
            batchDate: new Date(),
            batchStatusId: 1,
            status: 'Pending Payment',
            amount: 67000,
            balance: 20000
        }
    ]
}

export function mockPaymentBatchDialogTable() {
    return [
        {
            vendor: 'Advance Mechanical Systems, LLC',
            paymentDate: null,
            paymentStatus: 'Pending Payment',
            amount: 37000
        },
        {
            vendor: 'Lightning of Tomorrow',
            paymentDate: null,
            paymentStatus: 'Pending Payment',
            amount: 30000
        }
    ]
}

export function mockFundStatus() {
    return [
        {
            label:'All Batches',
            value: 0
        },
        {
            label:'Sufficient Funds',
            value: 1
        },
        {
            label: 'Insufficient Funds',
            value: 2 
        }
    ]
}

export function generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = max - 9;
    var years = [];
    for (var i = max; i >= min; i--) {
        years.push(i)
      }
    return years
}


export function mockNotesByYear() {
    return [
        {
            year: 2020,
            subitems: [
                { label: 'label 1', icon: 'notes', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Rejected', subitems: [] }
            ]
        },
        {
            year: 2019,
            subitems: [
                { label: 'label 1', icon: 'notes', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Modified', subitems: [] },
                { label: 'label 2', icon: 'notes', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Modified', subitems: [] },
                { label: 'label 3', icon: 'notes', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Modified', subitems: [] },
                { label: 'label 4', icon: 'notes', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Modified', subitems: [] }
            ]
        }
    ];
}

export function mockQuestionByYear() {
    return  [
        {
            year: 2020,
            subitems: [
                { label: 'label 1', icon: 'question', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Question', subitems: [] }
            ]
        },
        {
            year: 2019,
            subitems: [
                { label: 'label 1', icon: 'question', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Question', subitems: [] },
                { label: 'label 2', icon: 'question', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Question', subitems: [] },
                { label: 'label 3', icon: 'question', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Question', subitems: [] },
                { label: 'label 4', icon: 'question', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Question', subitems: [] }
            ]
        }
    ];
}

export function mockAuditTrailByYear() {
    return [
        {
            year: 2020,
            subitems: [
                { label: 'label 1', icon: 'upload', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Uploaded', subitems: [] }
            ]
        },
        {
            year: 2019,
            subitems: [
                { label: 'label 1', icon: 'upload', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Uploaded', subitems: [] },
                { label: 'label 2', icon: 'upload', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Uploaded', subitems: [] },
                { label: 'label 3', icon: 'upload', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Uploaded', subitems: [] },
                { label: 'label 4', icon: 'upload', author: 'Unknown', uploadDateTime: 'MM/DD/YYYY - XX:XXPM', status: 'Uploaded', subitems: [] }
            ]
        }
    ];
}