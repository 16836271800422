import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { InvoiceUpload } from 'src/app/model/invoice-upload';
import { InvoiceService } from 'src/app/services/invoice.service';
import { UploadComponent, FileInfo, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { InvoiceCommunityService } from 'src/app/services/invoice-community.service';
import { CommunityService } from 'src/app/services/community.service';
import { EditService$1 } from '@progress/kendo-angular-grid';
import { User } from 'src/app/model/user';
import { InvoicePaymentService } from 'src/app/services/invoice-payment.service';

@Component({
    selector: 'app-invoice-upload',
    templateUrl: './invoice-upload.component.html',
    styleUrls: ['./invoice-upload.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InvoiceUploadComponent extends KendoGridAbstractClass implements OnInit {
    model: InvoiceUpload
    invoiceForm: FormGroup;
    public batchCodes = [];
    public checkOut = false;
    public communities: any[] = [];
    public uploadTypes: any[] = [];
    public myFiles: Array<any> = [];
    public users: any;

    uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
    uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
    @ViewChild('upload') upload: UploadComponent;

    constructor(private fb: FormBuilder, public invoiceService: InvoiceService,
        public invoiceCommunityService: InvoiceCommunityService,
        public communityService: CommunityService,
        public toasterService: ToastrService,
        public invoicePaymentService: InvoicePaymentService,
        public router: Router) {
        super();
        this.model = new InvoiceUpload(this.fb);
        this.invoiceForm = this.model.buildFormGroup();

        LocalStorageHelper.setBackToButtonOnLocationBar({
            label: 'Back to Invoices',
            link: '/app/ledger/transactions',
            selectedTabQueryParam: null
        });
    }

    ngOnInit() {
        this.loading = true;
        forkJoin(this.invoiceService.getEntity('batches'),
            this.invoiceService.getEntity('uploadType'),
            this.invoiceCommunityService.getById('PropertyManagementCompanyId', LocalStorageHelper.getManagementCompanyFromBreadcrumb()))
            .subscribe(results => {
                //this.loading = false;
                this.batchCodes = results[0];
                this.uploadTypes = results[1];
                this.communities = results[2];
                this.communityService.getById(LocalStorageHelper.getCommunitiesFromBreadcrumb()).subscribe(result => {
                    this.invoiceForm.controls['companyId'].setValue(result.companyId);
                    this.invoicePaymentService.getUsersByCompany(result.companyId).subscribe(usrs => {
                        this.users = usrs.list;
                        this.loading = false;
                    });
                })
            }, error => {
                this.loading = false;
                this.toasterService.error('There is an error on this page');
                console.log(error);
            });
    }

    ngOnDestroy() {
        LocalStorageHelper.setBackToButtonOnLocationBar('null');
    }

    public onSlideToggleChange() {
        this.checkOut = !this.checkOut;
    }

    selectEventHandler(e: SelectEvent) {
        e.files.forEach((file: any) => {
            file.uploadTypeId = this.invoiceForm.controls['uploadTypeId'].value;
            this.myFiles.push(file)
        });
    }

    removeEventHandler(e: RemoveEvent) {
        this.myFiles = this.myFiles.filter(item => item.uid !== e.files[0].uid);
        console.log(this.myFiles);
    }

    public uploadFile() {
        this.loading = true;
        let modelToDto = this.model.toDto();
        modelToDto.invoiceDate = new Date();
        modelToDto.invoiceStatusId = 1;
        modelToDto.invoiceTypeId = 1;
        if (this.checkOut === true) {
            modelToDto.lockedDate = new Date();
            modelToDto.lockedByUser = this.invoiceForm.controls['lockedByUserId'].value
            modelToDto.isLocked = true;

        }
        this.invoiceService.add(modelToDto).subscribe(result => {
            this.uploadAttachment(null, result.id);
        }, error => this.loading = false)
        this.invoiceService.setDataFromInvoiceUpload(this.model.toDto(), this.myFiles);

    }

    public cancel() {
        this.router.navigate(['app/ledger/transactions']);
    }

    private uploadAttachment(invoiceNum: string, invoiceId: string) {
        if (this.myFiles.length > 0) {
            this.myFiles.forEach(item => {
                if (item.invoiceAttachmentId == undefined) {
                    this.invoiceService.uploadAttachmentFile(invoiceNum, invoiceId,
                        item.uploadTypeId.toString(), item.rawFile)
                        .subscribe(result => {
                            this.router.navigate(['/app/ledger/invoice-detail'], {
                                queryParams:
                                    { invoiceId: invoiceId, isEditable: true, currStep: 1, selectedTabId: 1 },
                            });
                        }, error => {
                            this.toasterService.error('There is an error while uploading the file');
                        });
                }
            })
        } else {
            this.router.navigate(['/app/ledger/invoice-detail'], {
                queryParams:
                    { invoiceId: invoiceId, isEditable: true, currStep: 1, selectedTabId: 1 },
            });
        }
    }

    protected getList() {
        throw new Error("Method not implemented.");
    }
}   