import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AccountPayableService } from 'src/app/services/account-payables.service';
import { InvoicePaymentService } from 'src/app/services/invoice-payment.service';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-payment-batch-edit',
  templateUrl: './payment-batch-edit.component.html',
  styleUrls: ['./payment-batch-edit.component.scss']
})
export class PaymentBatchEditComponent extends KendoGridAbstractClass implements OnInit {
  @Input() public invoicePaymentDetail: any = null;
  public editDialogTitle: string;
  public vendorTableData: any;
  public selectableSettings = {
    checkboxOnly: true
  };
  public selectedInvoices = [];
  public selectedPayables: any = {
    selectedRows: [],
    deselectedRows: []
  };
  public view = new Observable<any>();
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  constructor(private accountPayableService: AccountPayableService,
    private invoicePaymentService: InvoicePaymentService,
    public router: Router) {
    super();
    LocalStorageHelper.setBackToButtonOnLocationBar({
      label: 'Back to Payment Batch Status',
      link: '/app/payables/batch-approval-transactions',
      selectedTabQueryParam: null
    });
  }

  ngOnInit() {
    if (this.invoicePaymentDetail == null) {
      this.invoicePaymentDetail = this.accountPayableService.getPayableEdit();
      if (this.invoicePaymentDetail == null) {
        this.router.navigate(['/app/payables/batch-approval-transactions'])
      }
    }
    this.editDialogTitle = 'Payment Batch ID ' + this.invoicePaymentDetail.batchId;
    this.vendorTableData = this.accountPayableService.getVendorsToEdit();
    this.view = of(this.vendorTableData);

  }

  ngOnDestroy() {
    LocalStorageHelper.setBackToButtonOnLocationBar('null');
  }
  
  public onEditDialogSelectionChange(dataItem) {
    dataItem.selectedRows.forEach(item => {
      this.selectedPayables.selectedRows.push(item.dataItem);
    });

    dataItem.deselectedRows.forEach(item => {
      this.selectedPayables.selectedRows = this.selectedPayables.selectedRows.filter(payable => payable.batchId !== item.dataItem.batchId);
      this.selectedPayables.deselectedRows.push(item.dataItem);
    });
  }

  public onDetailExpand(expandedDataItem) {
    console.log('expanded data item ', expandedDataItem);
  }

  public cancelUpdate() {
    this.router.navigate(['/app/payables/batch-approval-transactions'])
  }

  public updateBatch() {
    this.selectedInvoices.forEach(invoice => {
      this.invoicePaymentService.delete(invoice.dataItem.invoicePaymentId).subscribe(result => {
        this.invoicePaymentDetail.batchTotal = this.invoicePaymentDetail.batchTotal - invoice.dataItem.amount;
        // this.vendorTableData = this.vendorTableData.map(data => {
        //   if (data.invoicePaymentId == invoice.dataItem.invoicePaymentId) {
        //     data.isDeleted = true;
        //   }
        //   return data;
        // })
        // this.vendorTableData = this.vendorTableData.filter(data => data.invoicePaymentId != invoice.dataItem.invoicePaymentId);
        this.view = of(this.vendorTableData);
      });
    });
  }

  
  public onInvoiceSelectionChange(dataItem) {
    this.selectedInvoices = dataItem.selectedRows;
  }

  public getList() {
    throw new Error('not implemented');
  }

}
