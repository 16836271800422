import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, ObservableLike, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
  @Input() items = [];
  @Input() noEntriesFoundLabel = '';
  @Input() label = '';
  @Input() isMultiSelect: boolean = true;
  @Input() initialVal: any;
  @Input() isDisabled: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
 

  private multiSelectOption = [];
  
  private ngxMatSelectSearchControl: FormControl = new FormControl();
  private matSelectControl = new FormControl();
  private filterControlMulti: ReplaySubject<any> = new ReplaySubject();
  protected _onDestroy = new Subject<void>();
  constructor() { }

  ngOnInit() {
    this.filterControlMulti.next(this.items.slice());
    this.ngxMatSelectSearchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((d) => {
        this.filterTypesMulti();
      });
      this.setInitialValue();
      if (this.isDisabled) {
        this.matSelectControl.disable();
      }
      
  }

  public filterTypesMulti() {
    if (!this.items) {
      return;
    }
    let search = this.ngxMatSelectSearchControl.value;
    if (!search) {
      this.filterControlMulti.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    if (search.length >= 3) {
      this.filterControlMulti.next(
        this.items.filter(p => p.name.toLowerCase().indexOf(search) > -1)
      );
    }   
  }

  public setInitialValue() {
    if (this.initialVal != null) {
      this.filterControlMulti.next(this.items.slice());
      this.matSelectControl.setValue( this.initialVal);
    }
  }

  public setValue(value) {
    this.matSelectControl.setValue(value);
  }


  public onChangeEvent(isSelected, selectedItem) {
    this.onChange.emit({isSelected: isSelected, selectedItem: selectedItem});
  }


}
