import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LocalStorageHelper } from '../helpers/local-storage-helper';

@Injectable()
export class GridApiInterceptorService implements HttpInterceptor {
  constructor(public toastrService: ToastrService) {
  }
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.toastrService.error(error && error.message ? error.message : 'Something went wrong');
        return throwError(error);
    }));
  }
}
