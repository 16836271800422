import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class CompanyProvider {
   companyProviderId: number;
   companyId: number;
   providerId: number;
   merchantNumber: string;
}
