import { FormField } from "../building-blocks/decorators/reactive-form-decorators";
import { Validators } from "@angular/forms";
import { BaseForm } from "../building-blocks/base-form";

export class InvoiceUpload extends BaseForm {
    invoiceId = 0;
    @FormField('batchId', [null, Validators.required])
    batchId = null;

    @FormField('companyId', [null, Validators.required])
    companyId = null;

    @FormField('uploadTypeId', [null, Validators.required])
    uploadTypeId = null;

    @FormField('isLocked', [null])
    isLocked = false;

    @FormField('lockedByUserId', [null])
    lockedByUserId = null;
    lockedDate = null;
}