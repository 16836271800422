import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { InvoiceRecurrence, GlAccount } from 'src/app/model/invoice-recurrence';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { mockInvoiceType, mockVendors, mockCommunity, mockPaymentType, mockGlAccounts } from 'src/app/model/mock-data';
import { InvoiceService } from 'src/app/services/invoice.service';
import { forkJoin, fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { InvoiceTemplateDistributionService } from 'src/app/services/invoice-template-distribution.service';
import { ToasterMessages } from 'src/app/model/toastrMessages';
import { InvoiceTemplateService } from 'src/app/services/invoice-template.service';
import { CompanyChartAccountsService } from 'src/app/services/company-chart-accounts.service';
import { CompanyChartAccounts } from 'src/app/model/company-chart-accounts';
import { CommunityService } from 'src/app/services/community.service';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { InvoiceCompanyVendorService } from 'src/app/services/invoice-company-vendor.service';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { InvoiceCommunityService } from 'src/app/services/invoice-community.service';
import { InvoiceCompanyBankAccountService } from 'src/app/services/invoice-company-bank-account.service';
import { Vendor } from 'src/app/model/vendor';
import { MatAutocomplete } from '@angular/material';
import { InvoiceVendorService } from 'src/app/services/invoice-vendor.service';

@Component({
  selector: 'app-invoice-recurrence',
  templateUrl: './invoice-recurrence.component.html',
  styleUrls: ['./invoice-recurrence.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceRecurrenceComponent implements OnInit, AfterViewInit {
  model: InvoiceRecurrence;
  invoiceFormGroup: FormGroup;
  public loading = false;
  public invoiceTypes = [];
  public vendors = [];
  public communities = [];
  public paymentTypes = [];
  public bankAccounts = [];
  public companyChartOfAccounts: CompanyChartAccounts[] = [];
  public openDialog = false;
  public pageMode = 0;
  public invoiceTemplateId = 0;
  public vendorSearch: Vendor[] = [];

  // for auto complete control
  public searchComplete = false;
  @ViewChild('filter') input: ElementRef;
  @ViewChild('vendorSelect') vendorSelect: MatAutocomplete;

  @ViewChild('glAccountAutoComplete') glAccountAutoComplete: ElementRef;
  @ViewChild('glAccountSelect') glAccountSelect: MatAutocomplete;
  public glAccountSearch = [];

  // for selected company id used to filter Gl Account and Bank Account Dropdown
  public selectedCompanyId: number;


  uploadSaveUrl = '';
  uploadRemoveUrl = '';
  public selectedVendor = new Subject<Vendor>();
  private isLoading = false;

  constructor(private fb: FormBuilder,
    public invoiceService: InvoiceService,
    public invoiceTemplateDistributionService: InvoiceTemplateDistributionService,
    public invoiceTemplateService: InvoiceTemplateService,
    public companyChartAccountService: CompanyChartAccountsService,
    public communityService: CommunityService,
    public invoiceCommunityService: InvoiceCommunityService,
    public invoiceCompanyVendorService: InvoiceCompanyVendorService,
    public invoiceCompanyBankAccount: InvoiceCompanyBankAccountService,
    public companyChartAccounts: CompanyChartAccountsService,
    public toastrService: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
    public invoiceVendorService: InvoiceVendorService) {
    this.model = new InvoiceRecurrence(fb);
    this.invoiceFormGroup = this.model.buildFormGroup();
    this.glFormArray = this.invoiceFormGroup.get('glAccnts') as FormArray;

    this.route.queryParams.subscribe(param => {
      if (param.invoiceTemplateId !== undefined) {
        this.invoiceTemplateId = param.invoiceTemplateId;
        this.pageMode = 1;
        this.invoiceTemplateService.getInvoiceTemplateByInvoiceTemplateId(this.invoiceTemplateId).subscribe(invoiceTemplate => {
          this.model.fromObject(invoiceTemplate[0]);
          this.loadVendorDropdownDetails(this.model.companyVendorId);
          this.loadCommunityDropdownDetails(this.model.companyId);
          this.loadGLAccountDropdownDetails(this.model.companyId, 0);
          this.loadBankDropdrownDetails(this.model.companyId);
          //this.loadGlAccount(invoiceTemplate[0].companyId);
          this.loading = false;
          this.invoiceTemplateDistributionService.getByFieldId(this.invoiceTemplateId).subscribe(invTemplate => {
            const items: any = invTemplate;
            if (items.length > 0) {
              this.glAccountFormList.splice(0, 1);
              this.glFormArray.removeAt(0);
            }
            items.forEach(element => {
              element.isDeleted = false;
              this.addGlAccount(element);
            });

          }, error => {
            this.toastrService.error('There is an error on getting the data');
            this.loading = false;
            console.log(error);
          });

        }, error => {
          this.toastrService.error('Theres an error on getting the data');
          this.loading = false;
          console.log(error);
        });

      } else {
        this.pageMode = 0;
        this.loadVendorDropdownDetails(0);
        this.communityService.getById(LocalStorageHelper.getCommunitiesFromBreadcrumb()).subscribe(result => {
          this.loadCommunityDropdownDetails(result.companyId);
        })

      }
    })
  }

  ngOnInit() {
    this.getDropDownData();
    this.addGlAccount();
  }

  public getDropDownData() {
    this.loading = true;
    forkJoin(this.invoiceService.getEntity('invoicetype'),
      this.invoiceService.getEntity('paymenttype'))
      .subscribe(results => {
        this.invoiceTypes = results[0];
        this.paymentTypes = results[1];
        this.loading = false;
      }, error => {
        this.loading = false;
        this.toastrService.error('There is an error on the page');
        console.log(error);
      });
  }

  public createTemplate() {
    this.invoiceTemplateService.add(this.model.toDto()).subscribe(data => {
      this.processGlAccounts(data.id);
    }, error => {
      console.log(error);
      this.toastrService.error('There is an error while saving the data')
    })
  }

  public updateTemplate() {
    let modelDto = this.model.toDto();
    this.invoiceTemplateService.update(modelDto).subscribe(data => {
      this.processGlAccounts(this.invoiceTemplateId);
    }, error => {
      console.log(error);
      this.toastrService.error('There is an error while saving the data')
    })
  }

  public cancel() {
    this.openDialog = true;
  }
  public stayOnPage() {
    this.openDialog = false;
  }

  public leavePage() {
    this.router.navigate(['app/ledger/run-recurring-invoice']);
  }

  public onDynamicInvoiceAmountChange(isChecked) {
    if (isChecked == true) {
      this.invoiceFormGroup.controls['amount'].setValue(0);
      this.invoiceFormGroup.controls['amount'].disable();
    } else {
      this.invoiceFormGroup.controls['amount'].setValue(null);
      this.invoiceFormGroup.controls['amount'].enable();
    }
  }

  public disableDynamicAmount = false;
  public onLeaveAmountField() {
    if (this.invoiceFormGroup.controls['amount'].value !== null && this.invoiceFormGroup.controls['amount'].value !== '') {
      this.disableDynamicAmount = true;
    } else {
      this.disableDynamicAmount = false;
    }
  }

  public glFormArray: FormArray;
  public glAccountModel: GlAccount
  public glAccountFormList: GlAccount[] = [];

  get glAccountFormGroup() {
    return this.invoiceFormGroup.get('glAccnts') as FormArray;
  }

  public onCompanySelectionChange(companyId) {
    this.loadGlAccount(companyId);
  }

  public addGlAccount(entity: GlAccount = null) {
    this.glFormArray.push(this.createGlAccount(entity))
  }

  public createGlAccount(entity: GlAccount = null): FormGroup {
    this.glAccountModel = new GlAccount(this.fb);
    const formGroup = this.glAccountModel.buildFormGroup();
    if (entity) {
      this.glAccountModel.fromObject(entity);
    }
    this.glAccountFormList.push(this.glAccountModel);
    return formGroup;
  }

  public removeGlAccount(index: number): void {
    // this.glAccountFormList.splice(index, 1);
    this.glAccountFormList[index - 1].isDeleted = true;
    this.glFormArray.removeAt(index);
  }

  private processGlAccounts(invoiceTemplateId) {
    let glAccountDto = [];
    if (this.pageMode == 0) {
      this.glAccountFormList.forEach(formData => {
        let formDataDto = formData.toDto();
        formDataDto.invoiceTemplateId = invoiceTemplateId
        glAccountDto.push(formDataDto);
      })
      this.invoiceTemplateDistributionService.addBulk(glAccountDto).subscribe(result => {
        this.toastrService.success(ToasterMessages.SUCCESSMSG);
        this.router.navigate(['/app/ledger/run-recurring-invoice']);
      }, error => {
        this.loading = false;
        this.toastrService.error('There is an error when adding data on Invoice Distribution');
      })
    } else {
      this.glAccountFormList.forEach(formData => {
        let formDataDto = formData.toDto();
        formDataDto.invoiceTemplateId = invoiceTemplateId;
        if (formData.invoiceTemplateDistributionId == 0) {
          if (formDataDto.isDeleted == false) {
            // add
            this.invoiceTemplateDistributionService.add(formDataDto).subscribe(result => {
              this.router.navigate(['/app/ledger/run-recurring-invoice']);
            }, error => {
              this.toastrService.error('There is an error adding account information');
            })
          }
        } else {
          if (formDataDto.isDeleted == false) {
            this.invoiceTemplateDistributionService.update(formDataDto).subscribe(result => {
              this.router.navigate(['/app/ledger/run-recurring-invoice']);
            }, error => {
              this.toastrService.error('There is an error updating account information');
            })
          } else {
            //delete
            this.invoiceTemplateDistributionService.delete(formDataDto.invoiceTemplateDistributionId).subscribe(result => {
              this.router.navigate(['/app/ledger/transactions']);
            }, error => {
              this.toastrService.error('There is an error deleting account information');
            });
          }
        }
      });
    }

  }

  private loadGlAccount(companyId: number) {
    this.companyChartAccountService.getById('CompanyId', companyId).subscribe(company => {
      this.loading = false;
      this.companyChartOfAccounts = company;
    }, () => this.loading = false)
  }

  private loadBankDropdrownDetails(companyId: number) {
    this.loading = true;
    this.invoiceCompanyBankAccount.getById('CompanyId', companyId).subscribe(result => {
      this.loading = false;
      this.bankAccounts = result;
    }, () => this.loading = false)
  }

  public multiSelectVendors = [];
  public vendorInitialValue = 0;
  public onVendorMultiSelectControlChange(isSelected, selectedItems) {
    if (isSelected == true) {
      this.invoiceFormGroup.controls['companyVendorId'].setValue(selectedItems.id);
    }
  }

  private loadVendorDropdownDetails(vendorInitialValue) {
    this.loading = true;
    this.communityService.getById(LocalStorageHelper.getCommunitiesFromBreadcrumb()).subscribe(result => {
      this.invoiceCompanyVendorService.getById('CompanyId', result.companyId)
        .pipe(map(d => {
          this.vendors = d;
          return d.map(element => {
            return {
              id: element.companyVendorId,
              name: element.legalName
            }
          })
        })
        ).subscribe(vendors => {
          this.multiSelectVendors = vendors;
          this.vendorInitialValue = vendorInitialValue
          this.loading = false;
        }, error => this.loading = false);

    }, error => this.loading = false)
  }

  public multiSelectCommunities = [];
  public communityInitialValue = 0;
  public onCommunityMultiSelectControlChange(isSelected, selectedItems) {
    this.loading = true;
    if (isSelected == true) {
      this.selectedCompanyId = selectedItems.id;
      this.invoiceFormGroup.controls['companyId'].setValue(selectedItems.id);
      this.loadGLAccountDropdownDetails(selectedItems.id, 0);
      this.loadBankDropdrownDetails(selectedItems.id);
    }
  }

  private loadCommunityDropdownDetails(communityInitialValue) {
    this.loading = true;
    this.invoiceCommunityService.getById('PropertyManagementCompanyId', LocalStorageHelper.getManagementCompanyFromBreadcrumb())
      .pipe(map(d => {
        this.communities = d;
        return d.map(element => {
          return {
            id: element.companyId,
            name: element.name
          }
        })
      })
      ).subscribe(communities => {
        this.multiSelectCommunities = communities;
        this.communityInitialValue = communityInitialValue
      }, error => this.loading = false);
  }

  // GL account dropdown
  public multiSelectGlAccounts = [];
  public ngxMatSelectSearchControl: FormControl = new FormControl();
  public matSelectControl = new FormControl();
  public filterControlMulti: ReplaySubject<any> = new ReplaySubject();
  public _onDestroy = new Subject<void>();
  public glAccountInitialValue = 0;

  public onGlAccountMultiSelectControlChange(isSelected, selectedItems, formIndex: number) {
    if (isSelected == true) {
      this.glAccountFormList[formIndex - 1].companyChartOfAccountsId = selectedItems.vendorId;
    }
  }

  private loadGLAccountDropdownDetails(companyId: number, glAccountsInitialValue) {
    this.glAccountInitialValue = glAccountsInitialValue;
    this.ngxMatSelectSearchControl.valueChanges.pipe(
      tap(() => this.isLoading = true),
      takeUntil(this._onDestroy),
      debounceTime(200),
      switchMap((e: any) => {
        this.loading = true;
        if (e && e.length > 2) {
          return this.applyFilter(e || '');
        } else {
          return new Observable<any[]>();
        }
      }),
      takeUntil(this._onDestroy)
    ).subscribe(item => {
      this.loading = false;
      this.filterControlMulti.next(item.list);
    }, () => this.loading = false)
    this.setInitialValue();
  }

  loadGlAccountMultiSelectData() {
    this.filterControlMulti.next(this.multiSelectGlAccounts.slice());
    this.ngxMatSelectSearchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((d) => {
        this.filterTypesMulti();
      });
    this.setInitialValue();
  }

  filterTypesMulti() {
    if (!this.multiSelectGlAccounts) {
      return;
    }
    let search = this.ngxMatSelectSearchControl.value;
    if (!search) {
      this.filterControlMulti.next(this.multiSelectGlAccounts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    if (search.length >= 3) {
      this.filterControlMulti.next(
        this.multiSelectGlAccounts.filter(p => p.name.toLowerCase().indexOf(search) > -1)
      );
    }
  }

  setInitialValue() {
    if (this.glAccountInitialValue != null) {
      this.filterControlMulti.next(this.multiSelectGlAccounts.slice());
      this.matSelectControl.setValue(this.glAccountInitialValue);
    }
  }

  ngAfterViewInit() {
    this.isLoading = false;
    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(200),
          tap(() => this.isLoading = true),
          distinctUntilChanged(),
          switchMap((e: any) => {
            this.searchComplete = false;
            let val = '';
            if (e) {
              val = e.target.value;
            }
            if (val && val.length > 2) {
              return this.applyFilter(val || '');
            } else {
              this.isLoading = false;
              this.vendorSearch = [];
              return new Observable<Vendor[]>();
            }
          })
        ).subscribe(result => {
          this.vendorSearch = result.list;
          this.isLoading = false;
          this.searchComplete = true;
        });
    }
  }

  clearFilter() {
    this.vendorSearch = [];
    this.vendors = [];
  }

  applyFilter(filterValue: string): Observable<any> {
    return this.invoiceVendorService.getAllByFilter(LocalStorageHelper.getManagementCompanyFromBreadcrumb(), filterValue);
  }
  displayFn(vendor?: Vendor): string {
    return vendor ? vendor.legalName : '';
  }

  onVendorSelectionChange(vendr: Vendor) {
    this.loading = true;
    this.input.nativeElement.value = vendr.legalName;
    this.selectedVendor.next(vendr);
    this.vendors.push(vendr);
    this.loading = false;
  }

}
