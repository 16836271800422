import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentChecked, AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { ToasterMessages } from 'src/app/model/toastrMessages';
import { Vendor } from 'src/app/model/vendor';
import { CommunityService } from 'src/app/services/community.service';
import { InvoiceCommunityService } from 'src/app/services/invoice-community.service';
import { InvoiceCompanyVendorService } from 'src/app/services/invoice-company-vendor.service';
import { InvoicePaymentService } from 'src/app/services/invoice-payment.service';
import { InvoiceVendorService } from 'src/app/services/invoice-vendor.service';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  animations: [
    trigger('searchOpenClose', [
      state('open', style({
        'width': '245px',
        'border': '1px solid lightgray',
        'padding-left': '5px'
      })),
      state('closed', style({
        'width': '0px',
        'border': 'none'
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ])
    ]),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceListComponent extends KendoGridAbstractClass implements AfterContentChecked {
  public batchCodes = [];
  public pageTitle = 'Invoices';
  public searchString = '';
  public showActionButton = false;
  public showLockIcon = false
  public hoveredIndex = 0;
  public showSearch = true;
  public userRole = 2; // TODO: this should come from data source
  public selectableSettings = {};
  public selectedStatus = 0;
  public isDeletedStatus = 0;
  public invoiceStatuses = [];
  public invoiceCommunities = [];
  private isLoading = false;
  public selectedVendor = new Subject<Vendor>();
  public vendorSearch: Vendor[] = [];
  public searchComplete = false;
  public eventReady = false;
  public isManager = false;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild(GridComponent) public grid: GridComponent;
  @ViewChild('filter') input: ElementRef;
  @ViewChild('vendorSelect') vendorSelect: MatAutocomplete;
  public filterFormGroup: FormGroup;
  constructor(private invoiceService: InvoiceService,
    public communityService: CommunityService,
    public invoiceCompanyVendorService: InvoiceCompanyVendorService,
    public invoiceCommunityService: InvoiceCommunityService,
    public invoicePaymentService: InvoicePaymentService,
    public router: Router,
    private toastrService: ToastrService,
    public invoiceVendorService: InvoiceVendorService) {
    super();
    // code that will clear the back to value in location bar
    LocalStorageHelper.setBackToButtonOnLocationBar('null');
    this.exportFileName = 'payment-list';
    this.showAdvanceFilter = false;
    this.pageIndex = 1;
    this.state = {
      skip: 1, // current page number
      take: 10, // items per page
    };
    this.pageSizes = [10, 25, 50];
    this.data = {
      data: [],
      total: 0
    };
    this.filterFormGroup = new FormGroup({
      lockedByUserId: new FormControl(),
      invoiceDate: new FormControl(),
      invoiceStatusId: new FormControl(),
      batchId: new FormControl(),
      communityId: new FormControl(),
      vendorId: new FormControl(),
      minAmount: new FormControl(),
      maxAmount: new FormControl(),
    });
    this.getList();
  }

  ngOnInit() {
    this.getInvoiceRelatedEntity();
    this.loadCommunityDropdownDetails();
    this.loadVendorDropdownDetails();
    this.loadUsersByCompanyId();
    this.isManager = LocalStorageHelper.isManager();
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageIndex = (event.skip / event.take) + 1;
    this.state.skip = event.skip;
    this.state.take = event.take
    this.getList();
  }

  public onSortChange(e) {
    this.sort = `${e[0].dir}|${e[0].field}`;
    this.getList();
  }

  public setSelectableSettings(): void {
    switch (this.userRole) {
      case 1: {
        this.selectableSettings = {
          checkboxOnly: true
        };
        break;
      }
      case 2: {
        this.selectableSettings = null;
        break;
      }
      case 3: {
        this.selectableSettings = null;
        break;
      }
    }
  }

  private onFilterClick() {
    this.showAdvanceFilter = !this.showAdvanceFilter;
    if (this.input) {      
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(200),
      tap(() => this.isLoading = true),
      distinctUntilChanged(),
      switchMap((e: any)  => {
        this.searchComplete = false;
        let val = '';
        if (e) {
          val = e.target.value;
        }
        if (val && val.length > 2) {
        return this.applyFilterV(val || '');
        } else {
          this.isLoading = false;
          this.vendorSearch = [];
            return new Observable<Vendor[]>();
        }
      })
    ).subscribe( result => {
      this.vendorSearch = result.list;
      this.isLoading = false;
      this.searchComplete = true;
    });
  }
  }

  private searchItem() {
    this.showSearch = !this.showSearch;
  }

  private performSearch() {
    console.log('implement search')
  }

  public actionClicked() {
    console.log('action button is clicked');
  }

  public onStatusSelectionChange(selectedStatus) {
    this.selectedStatus = selectedStatus.value;
    this.filter = {
      invoiceStatusId: this.selectedStatus
    }
    this.getList();
  }

  private activateHover() {
    setTimeout(() => {
      this.hoverFeature();
    });
  }

  private onLockClick(row) {
    console.log('implement lock click', row);
  }

  public exportCSV() {
    this.grid.saveAsExcel();
  }

  public exportToPDF() {
    this.grid.saveAsPDF();
  }

  public checkIn(dataItem) {
    dataItem.lockedByUserId = null;
    dataItem.lockedDate = null;
    this.invoiceService.update(dataItem).subscribe(data => {
      this.toastrService.success(ToasterMessages.SUCCESSMSG.toString());
      this.getList();
    }, error => {
      this.toastrService.error(ToasterMessages.ERRMSG.toString())
    });
    this.menuTrigger.closeMenu();
  }

  public runRecurringInvoices() {
    this.router.navigate(['app/ledger/run-recurring-invoice/']);
    this.menuTrigger.closeMenu();
  }

  public checkout(dataItem) {
    const impersonatedFlag = LocalStorageHelper.getImpersonatedFlag();
    const loggedUser = LocalStorageHelper.getLoggedUserInfo(impersonatedFlag);
    dataItem.lockedByUserId = loggedUser.userId;
    dataItem.lockedDate = new Date();
    this.invoiceService.update(dataItem).subscribe(data => {
      this.toastrService.success(ToasterMessages.SUCCESSMSG.toString());
      this.getList();
    }, error => {
      this.toastrService.error(ToasterMessages.ERRMSG.toString())
    });
    this.menuTrigger.closeMenu();
  }

  public delete(dataItem) {
    this.invoiceService.deleteInvoice(dataItem.invoiceId).subscribe(result => {
      this.toastrService.success(ToasterMessages.SUCCESSMSG);
    }, error => {
      this.toastrService.error(ToasterMessages.ERRMSG);
    })
    this.menuTrigger.closeMenu();
  }

  public applyFilter() {
    let minAmount = this.filterFormGroup.controls['minAmount'].value;
    let maxAmount = this.filterFormGroup.controls['maxAmount'].value;
    if (minAmount > maxAmount) {
      this.toastrService.error('Min Amount cannot be greater that Max Amount', 'Filter Error', {
        closeButton: true,
        timeOut: 5000,
        extendedTimeOut: 3000
      });
      return;
    }
    let vendorIdd = null;
    if(this.vendor !== undefined) {
      vendorIdd = this.vendor.vendorId;
    }

    this.filter = {
      headerSearch: this.searchString,
      lockedByUserId: this.filterFormGroup.controls['lockedByUserId'].value,
      invoiceDate: this.filterFormGroup.controls['invoiceDate'].value,
      batchId: this.filterFormGroup.controls['batchId'].value,
      communityId: this.filterFormGroup.controls['communityId'].value,
      vendorId: vendorIdd,
      minAmount: this.filterFormGroup.controls['minAmount'].value,
      maxAmount: this.filterFormGroup.controls['maxAmount'].value,
      invoiceStatusId: this.selectedStatus,
      isDeleted: this.isDeletedStatus
    };
    this.getList();
  }

 


  public resetFilter() {
    this.filterFormGroup.controls['lockedByUserId'].setValue('');
    this.filterFormGroup.controls['invoiceDate'].setValue('');
    this.filterFormGroup.controls['batchId'].setValue('');
    this.filterFormGroup.controls['communityId'].setValue('');
    this.filterFormGroup.controls['vendorId'].setValue('');
    this.filterFormGroup.controls['minAmount'].setValue('');
    this.filterFormGroup.controls['maxAmount'].setValue('');
    //this.vendorMultiSelect.setValue(null);
    this.userMultiSelect.setValue(null);
    this.communityMultiSelect.setValue(null);
    this.filter = undefined;
    this.selectedStatus = 0;
    //this.vendorSearch = [];
    this.input.nativeElement.value = '';
    this.vendor = undefined;
    this.getList();
  }

  private hoverFeature() {
    const kGridContainer = Array.from(document.querySelectorAll(".k-grid .k-grid-container tr"));
    kGridContainer.forEach(row => {
      const index = row.getAttribute("data-kendo-grid-item-index");
      row.addEventListener("mouseenter", () => {
        this.showActionButton = true;
        this.showLockIcon = true;
        this.hoveredIndex = parseInt(index);
      });
    });
  }

  public cellClickHandler(column, $dataItem) {
    if (column.title !== '' && column.title !== undefined) {
      LocalStorageHelper.setBackToButtonOnLocationBar({
        label: 'Back to Invoices',
        link: '/app/ledger/transactions',
        selectedTabQueryParam: null
      });
      this.router.navigate(['/app/ledger/invoice-detail'], {
        queryParams:
          { invoiceId: $dataItem.invoiceId, isEditable: $dataItem.lockedDate == null ? true : false, currStep: $dataItem.invoiceStatusId, selectedTabId: 1 },
      });
    }
  }

  public getList() {
    this.loading = true;
    this.invoiceService.getAllWithFilters(this.pageIndex, this.state.take, this.filter, this.sort).subscribe(results => {
      this.data = {
        data: results[0],
        total: results[1][0].recordCount
      };
      this.loading = false;
      this.activateHover();
    }, error => {
      this.loading = false;
    });
  }

  public addItem() {
    this.router.navigate(['app/ledger/invoice-add']);
  }

  public getInvoiceRelatedEntity() {
    forkJoin(
      this.invoiceService.getEntity('invoicestatuscode'),
      this.invoiceService.getEntity('community'),
      this.invoiceService.getEntity('batches')
    ).subscribe(results => {
      this.invoiceStatuses = results[0];
      this.invoiceCommunities = results[1];
      this.batchCodes = results[2];
    })

  }

  public getInvoiceStatusByStatusId(invoiceStatusId: number): string {
    let status = this.invoiceStatuses.filter(d => d.invoiceStatusId == invoiceStatusId);
    if (status.length > 0) {
      return status[0].status;
    }
    return '';
  }

  public communities = [];
  public users = [];
  public vendor: Vendor;
  public multiSelectCommunities = [];
  public multiSelectVendors = [];
  public multiSelectUsers = [];
  //@ViewChild('vendorMultiSelect') vendorMultiSelect;
  @ViewChild('userMultiSelect') userMultiSelect;
  @ViewChild('communityMultiSelect') communityMultiSelect;
  public onMultiSelectControlChange(isSelected, selectedItems, controlName) {
    if (isSelected == true) {
      this.filterFormGroup.controls[controlName].setValue(selectedItems.id);
    }
  }

  private loadCommunityDropdownDetails() {
    this.loading = true;
    this.invoiceCommunityService.getById('PropertyManagementCompanyId', LocalStorageHelper.getManagementCompanyFromBreadcrumb())
      .pipe(map(d => {
        this.communities = d;
        return d.map(element => {
          return {
            id: element.companyId,
            name: element.name
          }
        })
      })
      ).subscribe(communities => {
        this.multiSelectCommunities = communities;
      }, error => this.loading = false);
  }
   

  private loadVendorDropdownDetails() {
    this.loading = true;
    this.communityService.getById(LocalStorageHelper.getCommunitiesFromBreadcrumb()).subscribe(result => {
      this.invoiceCompanyVendorService.getById('CompanyId', result.companyId)
        .pipe(map(d => {
          //this.vendors = d;
          return d.map(element => {
            return {
              id: element.companyVendorId,
              name: element.legalName
            }
          })
        })
        ).subscribe(vendors => {
          this.multiSelectVendors = vendors;
          this.loading = false;
        }, error => this.loading = false);

    }, error => this.loading = false)
  }

  private loadUsersByCompanyId() {
    this.communityService.getById(LocalStorageHelper.getCommunitiesFromBreadcrumb()).subscribe(result => {
      this.invoicePaymentService.getUsersByCompany(result.companyId)
      .pipe(map(d => {
        this.users = d.list;
        return d.list.map(element => {
          return {
            id: element.userId,
            name: element.firstName + ' ' + element.lastName
          }
        })
      })
      ).subscribe(items => {
        this.multiSelectUsers = items;
        this.loading = false;
      }, error => this.loading = false);
    })
  }

  setEvent() {
    this.isLoading = false;
    if (this.input) {
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(200),
      tap(() => this.isLoading = true),
      distinctUntilChanged(),
      switchMap((e: any)  => {
        this.searchComplete = false;
        let val = '';
        if (e) {
          val = e.target.value;
        }
        if (val && val.length > 2) {
        return this.applyFilterV(val || '');
        } else {
          this.isLoading = false;
          this.vendorSearch = [];
            return new Observable<Vendor[]>();
        }
      })
    ).subscribe( result => {
      this.vendorSearch = result.list;
      this.isLoading = false;
      this.searchComplete = true;
    });
  }
  }

  clearFilter() {
    this.vendorSearch = [];
    this.vendor = undefined;
  }

  applyFilterV(filterValue: string): Observable<any>  {
    return this.invoiceVendorService.getAllByFilter(LocalStorageHelper.getManagementCompanyFromBreadcrumb(), filterValue);
  }
  
  displayFn(vendor?: Vendor): string {
    return vendor ?  vendor.legalName : '';
  }

  onVendorSelectionChange(vendr: Vendor) {
    this.loading = true;
      this.input.nativeElement.value = vendr.legalName;          
      this.selectedVendor.next(vendr);
      this.vendor = vendr;
      this.loading = false;
  }

  ngAfterContentChecked(): void
  {
    if(this.input) {
      if(this.showSearch == true && this.input.nativeElement.offsetParent != null) {
        if(this.eventReady == false) {        
          this.eventReady = true;
          this.setEvent();
        }
      }
    } else {
      this.eventReady = false;
      this.vendor = undefined;
      this.vendorSearch = [];
      if(this.input) {
        this.input.nativeElement.value='';       
      }
    }
  }

  ngAfterViewInit() {
    this.invoiceStatuses.push('Deleted');
  }

}
