import { State } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";

export abstract class KendoGridAbstractClass {
  /**
   * show/hide loading indicator
   */
  public loading: boolean;
  /**
   * variable that indicate the columns for the table
   */
  protected columns: any[];
  /**
   * variable that will hold the current page of the table
   */
  protected pageIndex = 1;
  /**
   * variable that will indicate the sizes avaialbe for the table
   */
  protected pageSizes: any[];
  /**
   * variable that will hold the filter of the table
   */
  protected filter: any = {};
  /**
   * used by the grid to indicate the file name when exported
   */
  protected exportFileName: string;
  /**
   * used to show/hide the advance filter when filter button is clicked
   */
  protected showAdvanceFilter: boolean;
  /**
   * variable that hold the table current page and number of items per page queried from the data source
   */
  protected state: State;
  /**
   * variable that will hold the data of the table
   */
  protected data: GridDataResult;
  /**
   * variable that will hold sort parameter of the table
   */
  protected sort: string;
  /**
   * method that will format the date to readable date
   * @param dateToFormat parameter to be formatted
   */
  protected formatDate(dateToFormat) {
    let newDate = new Date(dateToFormat);
    return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getUTCFullYear()}`;
  }
  /**
   * method that will get the list that will be rendered on the table
   */
  protected abstract getList();
}