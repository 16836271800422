import { Component, Input, ViewEncapsulation } from '@angular/core';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { InvoicePaymentService } from 'src/app/services/invoice-payment.service';
import { Observable, of } from 'rxjs';
import { InvoiceVendorService } from 'src/app/services/invoice-vendor.service';

@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorDetailComponent extends KendoGridAbstractClass {
  @Input() public vendor: any;
  @Input() public invoicePayment: any;

  public view: Observable<any>;

  constructor(private invoiceVendorService: InvoiceVendorService) {
    super();
  }

  ngOnInit() { this.getList(); }

  protected getList() {
    // this.view = of(this.vendor);
     this.view = this.invoiceVendorService.getByBatchIdInvoiceId(this.invoicePayment.batchId, this.invoicePayment.invoiceId);
  }
}


interface IVendorDetails {
  vendorId: number
  vendorName: string;
  paymentDate: string;
  paymentStatus: string;
  totalAmount: number;
}


