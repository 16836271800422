import { BaseForm } from "../building-blocks/base-form";
import { FormField } from "../building-blocks/decorators/reactive-form-decorators";
import { Validators, FormArray } from "@angular/forms";

export class BatchCode {
  batchCodeId = 0;
  batchCode = null;
  description = null;
  batchTypeId = null;
  creationDate: Date = null;
  modificationDate: Date = null;
  creationUserId: number;
  modificationUserId: number;
    
}
