import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { forkJoin, Observable, Subject } from 'rxjs';
import { GridDataResult, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Router } from '@angular/router';
import { KendoGridAbstractClass } from 'src/app/building-blocks/kendo-abstract-class';
import { ToastrService } from 'ngx-toastr';
import { MatMenuTrigger } from '@angular/material';
import { FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { InvoiceTemplateService } from 'src/app/services/invoice-template.service';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { ToasterMessages } from 'src/app/model/toastrMessages';
import { CommunityService } from 'src/app/services/community.service';
import { InvoicePaymentService } from 'src/app/services/invoice-payment.service';
import { map } from 'rxjs/operators';
import { InvoiceCommunityService } from 'src/app/services/invoice-community.service';
import { InvoiceVendorService } from 'src/app/services/invoice-vendor.service';
import { Vendor } from 'src/app/model/vendor';

@Component({
  selector: 'app-invoice-recurrence-list',
  templateUrl: './invoice-recurrence-list.component.html',
  styleUrls: ['./invoice-recurrence-list.component.scss'],
  animations: [
    trigger('searchOpenClose', [
      state('open', style({
        'width': '245px',
        'border': '1px solid lightgray',
        'padding-left': '5px'
      })),
      state('closed', style({
        'width': '0px',
        'border': 'none'
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ])
    ]),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceRecurrenceListComponent extends KendoGridAbstractClass {
  @ViewChild(GridComponent) public grid: GridComponent;
  public data: any = null;
  public view: Observable<GridDataResult>;
  public state: State = {
    skip: 0,
    take: 5,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public showActionButton = false;
  public hoveredIndex = 0;
  public showSearch = true;
  public openRunRecurrenceDialog = false;
  public isManager = false;
  public isDeletedStatus = 0;
  public selectedStatus = 0;
  public batchCodes = [];
  public invoiceStatuses = [];
  public filterFormGroup: FormGroup;
  public searchString = '';
  public multiSelectUsers = [];
  public multiSelectCommunities = [];
  public users = [];
  public communities = [];
  public vendor: Vendor;
  public isLoading = false;
  public selectedVendor = new Subject<Vendor>();
  public vendorSearch: Vendor[] = [];
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('vendorFilter') vendorFilter: ElementRef;
  

  constructor(private invoiceService: InvoiceService, private invoiceTemplateService: InvoiceTemplateService,
    public communityService: CommunityService, 
    public invoicePaymentService: InvoicePaymentService,  
    public invoiceCommunityService: InvoiceCommunityService,
    public invoiceVendorService: InvoiceVendorService,
    public router: Router, public toastrService: ToastrService) {
    super();
    LocalStorageHelper.setBackToButtonOnLocationBar('null');
    this.exportFileName = 'payment-list';
    this.showAdvanceFilter = false;
    this.pageIndex = 1;
    this.state = {
      skip: 1, // current page number
      take: 10, // items per page
    };
    this.pageSizes = [10, 25, 50];
    this.data = {
      data: [],
      total: 0
    };

    this.filterFormGroup = new FormGroup({
      lockedByUserId: new FormControl(),
      invoiceDate: new FormControl(),
      invoiceStatusId: new FormControl(),
      batchId: new FormControl(),
      communityId: new FormControl(),
      vendorId: new FormControl(),
      minAmount: new FormControl(),
      maxAmount: new FormControl(),
    });
    this.getList();
  }

  ngOnInit() {
    this.isManager = LocalStorageHelper.isManager();
    this.getInvoiceRelatedEntity();
    this.loadUsersByCompanyId();
    this.loadCommunityDropdownDetails();
  }

  private loadCommunityDropdownDetails() {
    this.loading = true;
    this.invoiceCommunityService.getById('PropertyManagementCompanyId', LocalStorageHelper.getManagementCompanyFromBreadcrumb())
      .pipe(map(d => {
        this.communities = d;
        return d.map(element => {
          return {
            id: element.companyId,
            name: element.name
          }
        })
      })
      ).subscribe(communities => {
        this.multiSelectCommunities = communities;
      }, error => this.loading = false);
  }

  private loadUsersByCompanyId() {
    this.communityService.getById(LocalStorageHelper.getCommunitiesFromBreadcrumb()).subscribe(result => {
      this.invoicePaymentService.getUsersByCompany(result.companyId)
      .pipe(map(d => {
        this.users = d.list;
        return d.list.map(element => {
          return {
            id: element.userId,
            name: element.firstName + ' ' + element.lastName
          }
        })
      })
      ).subscribe(items => {
        this.multiSelectUsers = items;
        this.loading = false;
      }, error => this.loading = false);
    })
  }

  public applyFilterV(filterValue: string): Observable<any>  {
    return this.invoiceVendorService.getAllByFilter(LocalStorageHelper.getManagementCompanyFromBreadcrumb(), filterValue);
  }

  public displayFn(vendor?: Vendor): string {
    return vendor ?  vendor.legalName : '';
  }

  public onVendorSelectionChange(vendr: Vendor) {
    this.loading = true;
      this.vendorFilter.nativeElement.value = vendr.legalName;          
      this.selectedVendor.next(vendr);
      this.vendor = vendr;
      this.loading = false;
  }

  public getInvoiceRelatedEntity() {
    forkJoin(
      this.invoiceService.getEntity('invoicestatuscode'),
      this.invoiceService.getEntity('batches')
    ).subscribe(results => {
      this.invoiceStatuses = results[0];
      this.batchCodes = results[1];
    })
  }

  public onMultiSelectControlChange(isSelected, selectedItems, controlName) {
    if (isSelected == true) {
      this.filterFormGroup.controls[controlName].setValue(selectedItems.id);
    }
  }

  public applyFilter() {
    let minAmount = this.filterFormGroup.controls['minAmount'].value;
    let maxAmount = this.filterFormGroup.controls['maxAmount'].value;
    if (minAmount > maxAmount) {
      this.toastrService.error('Min Amount cannot be greater that Max Amount', 'Filter Error', {
        closeButton: true,
        timeOut: 5000,
        extendedTimeOut: 3000
      });
      return;
    }
    
    let vendorIdd = null;
    if(this.vendor !== undefined) {
      vendorIdd = this.vendor.vendorId;
    }

    this.filter = {
      headerSearch: this.searchString,
      lockedByUserId: this.filterFormGroup.controls['lockedByUserId'].value,
      invoiceDate: this.filterFormGroup.controls['invoiceDate'].value,
      batchId: this.filterFormGroup.controls['batchId'].value,
      communityId: this.filterFormGroup.controls['communityId'].value,
      vendorId: null,
      minAmount: this.filterFormGroup.controls['minAmount'].value,
      maxAmount: this.filterFormGroup.controls['maxAmount'].value,
      invoiceStatusId: this.selectedStatus,
      isDeleted: this.isDeletedStatus
    };
    this.getList();
  }

  public dataStateChange(state: any): void {
    this.state = state;
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageIndex = (event.skip / event.take) + 1;
    this.state.skip = event.skip;
    this.state.take = event.take
    this.getList();
  }

  public onSortChange(e) {
    this.sort = `${e[0].dir}|${e[0].field}`;
    this.getList();
  }

  public searchItem() {
    this.showSearch = !this.showSearch;
  }

  public performSearch() {
    console.log('implement search')
  }

  public activateHover() {
    setTimeout(() => {
      this.hoverFeature();
    });
  }

  private hoverFeature() {
    const kGridContainer = Array.from(document.querySelectorAll(".k-grid .k-grid-container tr"));
    kGridContainer.forEach(row => {
      const index = row.getAttribute("data-kendo-grid-item-index");
      row.addEventListener("mouseenter", () => {
        this.showActionButton = true;
        this.hoveredIndex = parseInt(index);
      });
    });
  }

  private onFilterClick() {
    this.showAdvanceFilter = !this.showAdvanceFilter;
  }

  public exportCVS() {
    this.grid.saveAsExcel();
  }

  public exportToPDF() {
    this.grid.saveAsPDF();
  }

  public addItem() {
    this.router.navigate(['/app/ledger/create-reccuring-invoice']);
  }

  public runRecurringInvoices() {
    this.setBackToLocationBar()
    this.router.navigate(['/app/ledger/recurring-invoice']);
  }

  public delete(dataItem) {
    this.invoiceTemplateService.softDelete(dataItem.invoiceTemplateId).subscribe(result => {
      this.toastrService.success(ToasterMessages.SUCCESSMSG);
    })
  }

  public cellClickHandler(column, $dataItem) {
    if (column.title !== '' && column.title !== undefined) { 
      this.setBackToLocationBar();
      this.router.navigate(['/app/ledger/create-reccuring-invoice'], {queryParams: {invoiceTemplateId: $dataItem.invoiceTemplateId}});
    }
  }

  public selectedCompanyVendorId = 0;
  public selectedCompanyId = 0;
  public rowInvoiceDateControl: FormControl = new FormControl(new Date(), Validators.required);

  public openRecurringInvoice(row) {
    this.selectedCompanyVendorId = row.companyVendorId;
    this.selectedCompanyId = row.companyId;
    this.openRunRecurrenceDialog = true;
    this.menuTrigger.closeMenu();
  }

  public onRunRecurrenceStateChange(isOpened) {
    this.openRunRecurrenceDialog = isOpened;
  }

  public cancelRunRecurringInvoice() {
    this.openRunRecurrenceDialog = false;
  }

  public runRunRecurringInvoice() {
    // TODO: Reymond trigger run recurring invoice
    // this.invoiceTemplateService.getAllWithFilters().subscribe(result => {
      // based on the result, perform insert on InvoiceTemplateBatch table ?????
      // after the insert to InvoiceTemplateBatch, create an Invoice using this endpoint  
      // this.invoiceService.createInvoiceFromTemplate(this.createdInvoiceTemplates, this.rowInvoiceDateControl.value).subscribe()
    // })
    this.setBackToLocationBar();
    let newDate = new Date(this.rowInvoiceDateControl.value);
    this.router.navigate(['/app/ledger/recurring-invoice'], {queryParams: {runInvoiceStep: 2, companyVendorId: this.selectedCompanyVendorId, companyId: this.selectedCompanyId, invoiceDate: `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getFullYear()}` }});
  }

  public getList() {
    this.loading = true;
    this.invoiceTemplateService.getAllPaginatedWithFilters(this.pageIndex, this.state.take, this.filter, this.sort).subscribe(results => {
      this.data = {
        data: results[0],
        total: results[1][0].recordCount
      };
      this.loading = false;
      this.activateHover();
    }, error => {
      this.toastrService.error('Theres an error on getting the data');
      this.loading = false;
      console.log(error);
    });
  }
  
  public setBackToLocationBar() {
    LocalStorageHelper.setBackToButtonOnLocationBar({
      label: 'Back to Recurring Invoices',
      link: '/app/ledger/run-recurring-invoice',
      selectedTabQueryParam: null
    });
  }


}
