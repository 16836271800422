import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { CompanyProvider } from 'src/app/model/company-provider';
import { PaymentProcessorAccount } from 'src/app/model/payment-processor-account';
import { Provider } from 'src/app/model/provider';
import { CommunityService } from 'src/app/services/community.service';
import { CompanyProviderService } from 'src/app/services/company-provider.service';
import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-payment-processor-edit',
  templateUrl: './payment-processor-edit.component.html',
  styleUrls: ['./payment-processor-edit.component.css']
})
export class PaymentProcessorEditComponent implements OnInit {

  public processor: PaymentProcessorAccount = new PaymentProcessorAccount();
  public providers: Provider[] = [];
  public companyId = 0;
  processorForm = new FormGroup({
    providerId: new FormControl(),
    merchantNumber: new FormControl()
  });
  public communityId = 0;
  public companyProviderId = 0;
  public loading3: boolean;

  constructor(private router: Router,
  private route: ActivatedRoute,
  private fb: FormBuilder,
  private providerService: ProviderService,
  private companyProvider: CompanyProviderService,
  private communityService: CommunityService) { 

  }
  
  ngOnInit() {
    
  this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
  if(this.communityId !== 0) {
      this.providerService.getAll().subscribe(ps=>{
        this.providers = ps;
        this.companyProviderId = 0;
        this.communityService.getById(this.communityId).subscribe(p=>{
          this.companyId=p.companyId;
        this.companyProvider.getCompanyProvidersByCommunity(this.communityId).subscribe(p => { 
          if(p && p.length>0 && p[0].companyProviderId !== undefined && p[0].companyProviderId !== 0) {
            this.companyProviderId = p[0].companyProviderId;
            this.processorForm.controls["providerId"].setValue(p[0].providerId);
            this.processorForm.controls["merchantNumber"].setValue(p[0].merchantNumber);
          } else {
            this.companyProviderId = 0;
            this.processorForm.controls["providerId"].setValue('');
            this.processorForm.controls["merchantNumber"].setValue('');
          }
        });
      });
    });
    }
  }

  public onSave() {

    if(this.processorForm.valid) {
      if(this.companyProviderId === 0) {
        this.loading3 = true;
        let compP = new CompanyProvider();
        compP.merchantNumber = this.processorForm.controls["merchantNumber"].value;
        compP.providerId = this.processorForm.controls["providerId"].value;
        compP.companyId = this.companyId;
        this.companyProvider.add(compP).subscribe( cp => {
          this.companyProviderId = cp.id;    
          this.loading3 = false;
        });
      } else {
        let compP = new CompanyProvider();
        compP.merchantNumber = this.processorForm.controls["merchantNumber"].value;
        compP.providerId = this.processorForm.controls["providerId"].value;
        compP.companyId = this.companyId;
        compP.companyProviderId = this.companyProviderId;
        this.companyProvider.update(compP).subscribe( up => {
            this.loading3 = false;
        });
      }

    } else {
      alert('verify please');
    }
  }

  public onCancel() {
    if(this.processorForm.dirty) {
      this.loading3 = true;
      this.companyProvider.getCompanyProvidersByCommunity(this.communityId).subscribe(p => { 
        if(p && p.length>0 && p[0].companyProviderId !== undefined && p[0].companyProviderId !== 0) {
          this.companyProviderId = p[0].companyProviderId;
          this.processorForm.controls["providerId"].setValue(p[0].providerId);
          this.processorForm.controls["merchantNumber"].setValue(p[0].merchantNumber);
        } else {
          this.companyProviderId = 0;
          this.processorForm.controls["providerId"].setValue('');
          this.processorForm.controls["merchantNumber"].setValue('');
        }
        this.loading3 = false;
      });
    }
  }
}
